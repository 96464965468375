import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const spotGoldInvoiceApi = createAsyncThunk('spotGoldInvoice', async (payload) => {
    const response = await axiosInstance.get(`/customTrade/getSpotOrderDetails/${payload}`);
    return response.data;
});

const spotGoldInvoiceSlice = createSlice({
    name: "spotGoldInvoice",
    initialState: {
        data: [],
        errors: [],
        status: "idle"
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(spotGoldInvoiceApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(spotGoldInvoiceApi.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = "idle";
            })
            .addCase(spotGoldInvoiceApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

export default spotGoldInvoiceSlice.reducer;
