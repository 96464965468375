import React, { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Autocomplete, Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import "./Billing-form.css";

const inputFields = [
  {
    name: "name",
    label: "Name",
    type: "text",
    placeholder: "Enter name here",
  },
  {
    name: "address1",
    label: "Address Line 1",
    type: "text",
    placeholder: "Enter address here",
  },
  {
    name: "address2",
    label: "Address Line 2",
    type: "text",
    placeholder: "Enter address here",
  },
  // {
  //   name: "state",
  //   label: "State",
  //   type: "text",
  //   placeholder: "Enter state here",
  // },
  // {
  //   name: "city",
  //   label: "City",
  //   type: "text",
  //   placeholder: "Enter city here",
  // },
  {
    name: "pincode",
    label: "PINCODE",
    type: "number",
    placeholder: "Enter pincode here",
  },
  {
    name: "mobileno",
    label: "Mobile no.",
    type: "number",
    placeholder: "Enter mobile number here",
  },
];

function Billing() {
  const [values, setValues] = useState({
    name: "",
    address1: "",
    address2: "",
    state: "",
    city: "",
    pincode: "",
    mobileno: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "name":
        if (!value) error = "Name is required";
        break;
      case "pincode":
        if (!value) {
          error = "PINCODE is required";
        } else if (!/^\d{6}$/.test(value)) {
          error = "PINCODE must be 6 digits";
        }
        break;
      case "mobileno":
        if (!value) {
          error = "Mobile number is required";
        } else if (!/^\d{10}$/.test(value)) {
          error = "Mobile number must be 10 digits";
        }
        break;
      // Add more validation rules as needed
      default:
        break;
    }
    return error;
  };

  const handleErrors = (name, value) => {
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const Token = sessionStorage.getItem("token");
  // console.log("login token in inbox", Token);
  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cityData, setCityData] = useState([]);
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [stateData, setStateData] = useState([]);
  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [state_code, setstate_code] = useState(0);
  const [State_name, setState_Name] = useState("");
  const [scode, setscode] = useState("");

  useEffect(() => {
    stateApi();
    // cityApi();
  }, []);

  const onBillingOff = () => {
    navigate("/dashboard/buygold");
  };

  const handleFormSubmit = async (e) => {
    console.log("submit button ✅", values);
    e.preventDefault();
    await callBillingAPI();
  };

  const handleStateChange = (event) => {
    const selectedStateId = event.target.value;
    setSelectedState(selectedStateId);

    const selectedStateObject = stateData.find(
      (state) => state._id === selectedStateId
    );
    if (selectedStateObject) {
      setSelectedStateCode(selectedStateObject.state_code);
      setSelectedStateName(selectedStateObject.name);
      const State_Name = setState_Name(selectedStateObject.name);
      const State_code = setscode(selectedStateObject.state_code);
      // console.log("Selected State Code:", selectedStateObject.state_code);
      // console.log("Selected State Name:", selectedStateObject.name);
    }
  };
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setSelectedCity(selectedCityId);

    // Find the selected city object from cityData
    const selectedCityObject = cityData.find(
      (city) => city._id === selectedCityId
    );
    if (selectedCityObject) {
      setSelectedCityName(selectedCityObject.city_name);
      // console.log("Selected City Name:", selectedCityObject.city_name);
    }
  };

  useEffect(() => {
    if (stateData.length > 0) {
      setSelectedState(stateData[0]._id);
    }
  }, [stateData]);

  useEffect(() => {
    if (selectedState !== "") {
      const filteredCities = cityData.filter(
        (city) => city.state_id === selectedState
      );
      if (filteredCities.length > 0) {
        setSelectedCity(filteredCities[0]._id);
      }
    }
  }, [cityData, selectedState]);

  // =========================APIs==============================

  const [stateApiData, setStateApiData] = useState([]);
  const [cityApiData, setCityApiData] = useState("");

  const stateApi = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stateCode/getstateCode`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(url, { headers });
      console.log(
        "DISHANT STATE API======================",
        response?.data?.data
      );
      setStateData(response.data.data);
      setStateApiData(response?.data?.data);
      setstate_code(response.data);
    } catch (error) {
      console.error("ERROR STATE API++++++++++++++++++++++++++", error);
      throw error;
    }
  };

  const cityApi = async (id) => {
    // console.log("IDDDDDDDDDDDDDDDD",id)
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stateCode/getcity`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(url, { headers });
      console.log(
        "DISHANT CITY API+++++++++++++++++++++++++++++",
        response?.data?.data
      );

      const allCities = response?.data?.data;

      // console.log("DISHANT STATE VALUE===================", values.state);

      // console.log("DISHANT CITY STATE ID===================", allCities[0].state_id);

      const filterCitiesByState = allCities.filter(
        (city) => city.state_id === id
      );

      console.log("FILTERED CITY+++++++++++++", filterCitiesByState);
      setCityData(response.data.data);
      setCityApiData(filterCitiesByState);
    } catch (error) {
      console.error("ERROR city API++++++++++++++++++++++++++", error);
      throw error;
    }
  };

  const callBillingAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/address/addaddress`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      // var UName = document.getElementById("Name").value;
      // console.log("UName", UName);
      // var Address1 = document.getElementById("Add1").value;
      // console.log("Address1", Address1);
      // var Address2 = document.getElementById("Add2").value;
      // console.log("Address2", Address2);
      // // var State = document.getElementById("State").value

      // // console.log("state name", State);
      // // var City = document.getElementById("City").value
      // var Phone = document.getElementById("Phone").value;
      // // console.log("phone no ", Phone);
      // var Pincode = document.getElementById("PinCode").value;

      const requestBody = {
        city: values.city,
        country: "India",
        line1: values.address1,
        mobileNumber: values.mobileno,
        name: values.name,
        state: values.state,
        statecode: String(scode),
        type: "B",
        zip: String(values.pincode),
        line2: values.address2,
      };
      console.log("PAYLOAD++++++++++++++ ", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      console.log("response DISHANT++++++++++++++++++++", response);
      if (response.error == true) {
        toast.error(response.message);
      } else {
        toast.success("Billing Address Saved");
        navigate("/dashboard/buygold");
      }
    } catch (error) {
      toast.error(error.response.data.message)
      console.error("ERROR IN CATCH++++++++++++++++=", error);
    }
  };

  const StateChangeHandler = (event, value) => {
    console.log("IN HANDLE CHANGE++++++++++", value);
    if (value) {
      // dispatch(getCityApi(value.id));
      cityApi(value._id);
      setValues((prevValues) => ({
        ...prevValues,
        state: value.name,
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        state: "",
      }));
      console.log("Selection cleared");
    }
  };

  const cityChangeHandler = (event, value) => {
    console.log("IN HANDLE CHANGE++++++++++", value);
    if (value) {
      setValues((prevValues) => ({
        ...prevValues,
        city: value.city_name,
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        city: "",
      }));
      console.log("Selection cleared");
    }
  };

  return (
    <section>
      <div className="row d-flex justify-content-center">
        <h3 className="text-center p-3 fw-bold" style={{ color: "#4A2A7D" }}>
          Billing Address
        </h3>
        <div
          className="col-md-10  p-3 "
          style={{ border: "2px solid #E3B041", borderRadius: "15px" }}
        >
          {/* <form style={{ color: "#4A2A7D" }}>
              <div className="row d-flex justify-content-center">
                <div className="col">
                  <div class="mb-3">
                    <label
                      for="name"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Name"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="name"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      Address Line 1
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Add1"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="formGroupExampleInput2"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      Address Line 2
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Add2"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="name"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      State
                    </label>
                    <select
                      className="custom-select outline-none form-control"
                      id="State"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                      value={selectedState}
                      onChange={handleStateChange}
                    >
                      <option value="">Select State</option>
                      {stateData.map((state) => (
                        <option value={state._id} key={state._id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="formGroupExampleInput2"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      City
                    </label>
                    <select
                      className="custom-select outline-none form-control"
                      id="City"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                      value={selectedCity}
                      onChange={handleCityChange}
                    >
                      <option value="">Open this select menu</option>
                      {cityData
                        .filter((city) => city.state_id === selectedState)
                        .map((city) => (
                          <option value={city._id} key={city._id}>
                            {city.city_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="name"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      PinCode
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="PinCode"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="formGroupExampleInput2"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      Mobile No
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Phone"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#880E4F",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{
                    minWidth: "80px",
                    borderRadius: "20px",
                    fontSize: "20px",
                    backgroundColor: "red",
                  }}
                  onClick={() => onBillingOff()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => BillingSave()}
                  style={{
                    minWidth: "80px",
                    borderRadius: "20px",
                    fontSize: "20px",
                    backgroundColor: "#4A2A7D",
                  }}
                >
                  save
                </button>
              </div>
            </form> */}

          <form onSubmit={handleFormSubmit}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                width: "100%",
              }}
            >
              {inputFields.map((item, i) => (
                <TextField
                  className="custom-inputfield"
                  key={i}
                  name={item.name}
                  value={values[item.name]}
                  label={item.label}
                  type={item.type}
                  placeholder={item.placeholder}
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  inputProps={item.name === "pincode" ? { maxLength: 6 } : {}}
                  error={!!errors[item.name]}
                  helperText={errors[item.name]}
                  onChange={(e) => {
                    handleChange(e);
                    handleErrors(item.name, e.target.value);
                  }}
                  style={{ marginBottom: "1rem" }}
                />
              ))}
            </div>

            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <Autocomplete
                disablePortal
                id="state-autocomplete"
                options={stateApiData || []}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                value={
                  (stateApiData || []).find(
                    (option) => option.name === values.state
                  ) || null
                }
                onChange={StateChangeHandler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    error={!!errors.state}
                    helperText={errors.state}
                    required
                  />
                )}
              />
              <Autocomplete
                disablePortal
                id="city-autocomplete"
                options={cityApiData || []}
                getOptionLabel={(option) => option.city_name}
                fullWidth
                size="small"
                value={
                  (cityApiData || []).find(
                    (option) => option.city_name === values.city
                  ) || null
                }
                onChange={cityChangeHandler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="City"
                    error={!!errors.city}
                    helperText={errors.city}
                    required
                  />
                )}
              />
            </div>

            {/* <div className="row d-flex justify-content-center">
                <div className="col">
                  <div class="mb-3">
                    <label
                      for="name"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Name"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="name"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      Address Line 1
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Add1"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="formGroupExampleInput2"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      Address Line 2
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Add2"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="name"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      State
                    </label>
                    <select
                      className="custom-select outline-none form-control"
                      id="State"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                      value={selectedState}
                      onChange={handleStateChange}
                    >
                      <option value="">Select State</option>
                      {stateData.map((state) => (
                        <option value={state._id} key={state._id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="formGroupExampleInput2"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      City
                    </label>
                    <select
                      className="custom-select outline-none form-control"
                      id="City"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                      value={selectedCity}
                      onChange={handleCityChange}
                    >
                      <option value="">Open this select menu</option>
                      {cityData
                        .filter((city) => city.state_id === selectedState)
                        .map((city) => (
                          <option value={city._id} key={city._id}>
                            {city.city_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="name"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      PinCode
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="PinCode"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#4A2A7D",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      for="formGroupExampleInput2"
                      class="form-label"
                      style={{ color: "#4A2A7D" }}
                    >
                      Mobile No
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Phone"
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #E3B041",
                        color: "#880E4F",
                      }}
                    />
                  </div>
                </div>
              </div> */}

            <div className="d-flex justify-content-between">
              <Button
                type="button"
                variant="contained"
                sx={{
                  backgroundColor: "#472878",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#472878",
                  },
                }}
                onClick={() => onBillingOff()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#472878",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#472878",
                  },
                }}
              >
                save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
export default Billing;
