import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import Swal from "sweetalert2";

export const contactUsApi = createAsyncThunk(
  "contactUs",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/admin_panel/contact`,
        payload
      );
      Swal.fire({
        title: "Query Sent Successfully!",
        text: "Our team will connect with you soon.",
        icon: "success",
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactUsApi.pending, (state) => {
        state.status = "loading";
        state.errors = [];
      })
      .addCase(contactUsApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(contactUsApi.rejected, (state, action) => {
        state.errors.push(action.payload);
        state.status = "idle";
      });
  },
});

export default contactUsSlice.reducer;
