// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics"; // Uncomment if using Analytics

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDSjRjb4iIKh3yLcJQIG3g2V8qDfM_Ej0I",
  authDomain: "bharat-batuk.firebaseapp.com",
  projectId: "bharat-batuk",
  storageBucket: "bharat-batuk.appspot.com",
  messagingSenderId: "742339172499",
  appId: "1:742339172499:web:06917aa94402f1ea8a1566",
  measurementId: "G-NBGND2WJ2H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const messaging = getMessaging(app);
const database = getDatabase(app);
// const analytics = getAnalytics(app); // Uncomment if using Analytics

export { app, database };

/**
 * Function to request notification permission and generate FCM token.
 */
export const generateToken = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      // Get the token
      const token = await getToken(messaging, {
        vapidKey:
          "BEiLRcQ0vv750r6URDTEi-GfkRdzOmbUwSccefc1hZ_xqsUqHEUY-T7ExGmhQYTx5bsp9JcWdGT-sx32Uy_gZ24",
      });

      if (token) {
        console.log("FCM Token:", token);
        localStorage.setItem("fcmToken", token);
        // TODO: Send the token to your server for sending notifications
      } else {
        console.warn(
          "No registration token available. Request permission to generate one."
        );
      }
    } else {
      console.error("Unable to get permission to notify:", permission);
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};
