// utils/formatTimeAgo.js

export const formatTimeAgo = (createdAt) => {
  const currentTime = new Date();
  const timeDiff = Math.floor((currentTime - new Date(createdAt)) / 1000); // in seconds

  if (timeDiff < 60) {
    return `${timeDiff} seconds ago`;
  } else if (timeDiff < 3600) {
    const minutes = Math.floor(timeDiff / 60);
    return `${minutes} minutes ago`;
  } else if (timeDiff < 86400) {
    const hours = Math.floor(timeDiff / 3600);
    return `${hours} hours ago`;
  } else if (timeDiff < 31536000) {
    const days = Math.floor(timeDiff / 86400);
    return `${days} days ago`;
  } else {
    const years = Math.floor(timeDiff / 31536000);
    return `${years} years ago`;
  }
};
