import React from "react";
import Chart from "react-apexcharts";

const BarChart = ({ totalAmount, sipUrl }) => {
  // console.log("Inside Bar========", totalAmount);

  const batukReturns = totalAmount;
  const otherPlatformReturns = totalAmount * 0.8;
  const totalSaving = totalAmount * 0.7;

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `₹${val.toFixed(2)}`,
      style: {
        fontSize: "14px",
        colors: ["white"],
      },
    },
    xaxis: {
      categories: ["Total ", "Other Platform ", "Batuk Return"],
      labels: {
        style: {
          // fontSize: "14px", // Adjust font size as needed
          fontWeight: "bold", // Bold the x-axis labels
          colors: ["#4a2a7d", "#4a2a7d", "#4a2a7d"], // Set label color (optional)
        },
      },
    },
    yaxis: {
      show: false, // Hide the Y-axis
    },
    grid: {
      show: false, // Hide the grid lines
    },
    colors: ["#4a2a7d"], // Custom color for bars
    fill: {
      opacity: 3,
      colors: ["#4a2a7d"], // Custom color for fill
    },
    states: {
      hover: {
        filter: {
          type: "none", // Disable any hover effect that might cause the bars to disappear
        },
      },
    },
  };

  const series = [
    {
      name: "Returns",
      data: [totalSaving, otherPlatformReturns, batukReturns],
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width="100%"
      // height="300px"
    />
  );
};

export default BarChart;
