import { Skeleton } from "@mui/material";
import React from "react";

export default function ProductsSkeleton() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Skeleton
          variant="rounded"
          width="100%"
          height={170}
          animation="wave"
        />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation="wave" />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={150}
          animation="wave"
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={100}
          animation="wave"
        />
        <Skeleton variant="rounded" width="100%" height={40} animation="wave" />
      </div>
    </div>
  );
}
