import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const razorpayOrderApi = createAsyncThunk('razorpayOrder', async (payload) => {
    const response = await axiosInstance.post('/orders', payload);
    return response.data;
});

const initialState = {
    data: [],
    errors: [],
    status: "idle"
};

const razorpayOrderSlice = createSlice({
    name: "razorpayOrder",
    initialState,
    reducers: {
        resetState: (state) => {
            return initialState; // Resets state to initial values
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(razorpayOrderApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(razorpayOrderApi.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = "idle";
            })
            .addCase(razorpayOrderApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

export const { resetState } = razorpayOrderSlice.actions;

export default razorpayOrderSlice.reducer;
