import React, { useState, useEffect } from "react";
import { getGoldApi } from "../redux/features/goldSlice";
import { useDispatch } from "react-redux";

const Timer = () => {
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(120); // 2 minutes = 120 seconds

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 1) {
          // When timer reaches 1 second, reset it to 120 and call the API
          dispatch(getGoldApi());
          return 120;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  return (
    <div>
      <strong>{formatTime()}</strong>
    </div>
  );
};

export default Timer;
