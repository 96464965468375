import { createSlice } from '@reduxjs/toolkit'

const historyDataSlice = createSlice({
    name: 'historyDataSlice',
    initialState: {
        data: []
    },
    reducers: {
        AddHistory: (state, action) => {

            console.log("action.payload", action);
            state.data = action.payload;
        },

    }
});



export const { AddHistory } = historyDataSlice.actions
export default historyDataSlice.reducer
