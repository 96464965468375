import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const removeProductCartApi = createAsyncThunk(
  "removeProductInCart",
  async (id) => {
    const response = await axiosInstance.delete(`/merchant/deleteCartItem/${id}`);
    return response.data;
  }
);

const removeProductCartSlice = createSlice({
  name: "removeProductInCart",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeProductCartApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeProductCartApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(removeProductCartApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default removeProductCartSlice.reducer;
