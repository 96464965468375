import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { panSchema } from "../../utils/validationSchemas";
import axios from "axios";
import transactionId from "../../utils/transactionId";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getkycDetailsApi } from "../../redux/features/kycDetailsSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import { FaSave } from "react-icons/fa";

const fields = [
  { id: "panNum", label: "PAN Number" },
  { id: "dob", label: "Date of Birth" },
  { id: "email", label: "Email" },
];

export default function Pan({ kycDetailApiResponse, handleNext }) {
  const dispatch = useDispatch();
  const Token = sessionStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  //   ======================FORMIK=======================

  const { values, handleSubmit, handleChange, errors, setValues } = useFormik({
    initialValues: {
      panNum: "",
      dob: "",
      email: "",
    },
    validationSchema: panSchema,
    onSubmit: async () => {
      await encryptPanApi();
      await dispatch(getkycDetailsApi());
    },
    validateOnMount: false, // No validation on mount
    validateOnChange: false, // No validation on change
    validateOnBlur: true, // Validate only on blur
  });

  const [loading, setLoading] = useState(false);
  const encryptPanApi = async () => {
    setLoading(true);
    const url = `${baseUrl}/encrypt/encrypted`;
    const encryptdata = JSON.stringify({
      transID: transactionId.transactionId,
      docType: "2",
      docNumber: values.panNum,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const requestBody = {
        plainText: encryptdata,
      };
      const response = await axios.post(url, requestBody, { headers });
      // console.log("ENCRYPT PAN API++++++++++++++++++", response);

      const encr = response.data.data[0];
      await panverify(encr);
    } catch (error) {
      setLoading(false);
      console.log("ERROR INSIDE CATCH++++++++++++++++++=", error);
    } finally {
      setLoading(false);
    }
  };

  //=================================================================

  const panverify = async (encryptdata) => {
    let data = {
      requestData: encryptdata,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://www.truthscreen.com/v1/apicall/nid/idsearch",
      headers: {
        username: "production@batuk.in",
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      const respo = response.data.responseData;
      await decryptPanApi(respo);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  //   =====================================================================

  const decryptPanApi = async (enc) => {
    const url = `${baseUrl}/decrypt/decrypted`;
    const encryptdata = enc;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const requestBody = {
        encText: encryptdata,
      };

      const response = await axios.post(url, requestBody, { headers });

      // const {} = response.data.data[0]

      const parsedData = JSON.parse(response.data.data[0]);
      // console.log("PARSHEDDATA===============", parsedData);

      const {
        Name,
        LastUpdate,
        NameOnTheCard,
        panHolderStatusType,
        STATUS,
        StatusDescription,
        source_id,
        status,
      } = parsedData.msg || "";

      const parsedResponse = JSON.parse(response?.data?.data[0]);

      if (parsedResponse.status === 1) {
        // toast.success("Success");
        await PanKYCAPI(parsedData);
      } else if (parsedResponse.status === 1014) {
        toast.error("Incorrect PAN");
        return;
      } else if (parsedResponse.status === 9) {
        toast.error("User not found");
        return;
      } else {
        toast.error("unexpected error");
        return;
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  //   =====================================================================

  const PanKYCAPI = async (parsedData) => {
    const url = `${baseUrl}/kyc/pan`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    console.log("parsedData==========", parsedData);

    try {
      const requestBody = {
        msg: {
          ...parsedData.msg,
          dob: values.dob,
          email: values.email,
        },
        status: parsedData.status,
      };

      const response = await axios.post(url, requestBody, { headers });

      if (response.status === 200) {
        console.log(
          "kyc pan API Response++++++++++:",
          response.data.data[0].msg.Name
        );

        let userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};
        userDetails.name = response.data.data[0].msg.Name;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
        handleNext("panel2");
        toast.success("PAN details saved");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // const kycDetailApiResponse = useSelector(
  //   (state) => state.kycDetailsSlice.data.data
  // );

  // console.log("KYC API RESPONSE=================", kycDetailApiResponse);

  const { PanNumber, dob, email, Name, NameOnTheCard, STATUS } =
    kycDetailApiResponse?.[0]?.pan?.msg || {};

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    if (userDetails) {
      setValues({
        panNum: PanNumber || "",
        dob: userDetails.dob || dob || "",
        email: userDetails.email || email || "",
      });
    }
  }, [setValues, kycDetailApiResponse]);

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
      >
        {fields.map((field) => (
          <TextField
            key={field.id}
            id={field.id}
            label={field.label}
            variant="outlined"
            size="small"
            onChange={handleChange}
            value={values[field.id] || ""}
            error={Boolean(errors[field.id])}
            helperText={errors[field.id] || ""}
            type={field.id === "dob" ? (PanNumber ? "text" : "date") : "text"}
            InputProps={{
              readOnly: !!PanNumber, // Conditionally set the read-only property
            }}
            InputLabelProps={{
              shrink: field.id === "dob" ? true : undefined, // Ensure label displays for date field
            }}
            sx={{ marginBottom: 2 }}
          />
        ))}

        {!PanNumber && (
          <div style={{ display: "flex", justifyContent: "end", gap: "1rem" }}>
            <LoadingButton
              type="submit"
              size="small"
              // onClick={handleClick}
              loading={loading}
              loadingPosition="start"
              startIcon={<FaSave color="white" />}
              variant="contained"
              sx={{
                backgroundColor: "#4a2a7d",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4a2a7d",
                },
              }}
            >
              Save
            </LoadingButton>
            {/* <Button
              variant="contained"
              onClick={() => handleNext("panel2")}
              sx={{
                backgroundColor: "#4a2a7d",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4a2a7d",
                },
              }}
            >
              Next
            </Button> */}
          </div>
        )}
      </form>
    </div>
  );
}
