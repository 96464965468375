import * as yup from "yup";

export const BillingAddressSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
    .required("Phone number is required"),
  pan: yup
    .string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "PAN must be valid")
    .required("PAN is required"),
  dob: yup.date().required("Date of birth is required"),
  address: yup.string().required("Address is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  landmark: yup.string().required("Landmark is required"),
  zip: yup
    .string()
    .matches(/^[0-9]{6}$/, "ZIP code must be exactly 6 digits")
    .required("ZIP code is required"),
});

export const ShippingAddressSchema = yup.object().shape({
  address: yup.string().required("Address is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  landmark: yup.string().required("Landmark is required"),
  zip: yup
    .string()
    .matches(/^[0-9]{6}$/, "ZIP code must be exactly 6 digits")
    .required("ZIP code is required"),
});

export const contactUsSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name cannot exceed 50 characters"),

  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),

  mobileNo: yup
    .string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),

  subject: yup
    .string()
    .required("Subject is required")
    .min(5, "Subject must be at least 5 characters")
    .max(100, "Subject cannot exceed 100 characters"),

  message: yup
    .string()
    .required("Query is required")
    .min(10, "Query must be at least 10 characters")
    .max(500, "Query cannot exceed 500 characters"),
});

export const panSchema = yup.object({
  panNum: yup
    .string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN Number format")
    .required("PAN Number is required"),
  dob: yup
    .date()
    .max(new Date(), "Date of Birth cannot be in the future")
    .required("Date of Birth is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
});

export const billingAddressSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters long"),

  addressLine1: yup.string().required("Address Line 1 is required"),

  addressLine2: yup.string().required("Address Line 2 is required"),

  state: yup.string().required("State is required"),

  city: yup.string().required("City is required"),

  pincode: yup
    .string()
    .required("Pincode is required")
    .matches(/^\d{6}$/, "Pincode must be exactly 6 digits"),

  mobileNum: yup
    .string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),
});

export const bankSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must not exceed 50 characters"),

  accountNum: yup
    .string()
    .required("Account number is required")
    .matches(/^\d+$/, "Account number must be digits only")
    .min(8, "Account number must be at least 8 digits")
    .max(18, "Account number must not exceed 18 digits"),

  ifsc: yup
    .string()
    .required("IFSC code is required")
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code"),
});

export const sipSchema = yup.object({
  date: yup.string().required("Date is required"),
  amount: yup
    .number()
    .min(100, "Amount must be at least 100")
    .required("Amount is required"),
});
