import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const razorpayPostOrderApi = createAsyncThunk('razorpayPostOrder', async () => {
    const response = await axiosInstance.post('/redeem/postOrder');
    return response.data;
});

const razorpayPostOrderSlice = createSlice({
    name: "razorpayPostOrder",
    initialState: {
        data: [],
        errors: [],
        status: "idle"
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(razorpayPostOrderApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(razorpayPostOrderApi.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = "idle";
            })
            .addCase(razorpayPostOrderApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

export default razorpayPostOrderSlice.reducer;
