import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const historyApi = createAsyncThunk('history', async (payload) => {
    const response = await axiosInstance.post('/customTrade/getOrderHistory',payload);
    return response.data;
});

const historySlice = createSlice({
    name: "history",
    initialState: {
        data: [],
        errors: [],
        status: "idle"
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(historyApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(historyApi.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = "idle";
            })
            .addCase(historyApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

export default historySlice.reducer;
