import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { FaPlus } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import axios from "axios";
import ShippingAddressForm from "../../components/ShippingAddressForm";
import MainModal from "../../components/MainModal";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IoIosAddCircleOutline } from "react-icons/io";
import "./ShippingAddress.css";

const ShippingAddress = () => {
  const [checkaddress, setcheckaddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  // --------------------MODAL OPEN STATE------------------------
  const [prevaddclick, setprevclick] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const [checkBoxState, setCheckBoxState] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  console.log("LOCATION+++++++++++++", location.state);
  const Token = sessionStorage.getItem("token");

  const { t } = useTranslation();
  const { shippingAddress, addAddressBtn, noAddressFound } =
    t("shippingAddress");
  const { name, phone, address1, address2, city, state, zipCode } = t(
    "shippingAddress.addressCard"
  );

  const GetAddressAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/address/get_address`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();
      console.log("DISHANT++++++++++++++++++++", data.data);

      setcheckaddress(
        data.data.map((item, i) => {
          return item;
        })
      );
    } catch (error) {
      console.error("ERROR IN CATCH+++++++++++++++++", error);
    }
  };

  const handledelete = (item) => {
    deleteAPI(item._id)
      .then(() => {
        // Filter out the deleted address from checkaddress state
        const updatedAddresses = checkaddress.filter(
          (address) => address._id !== item._id
        );
        // Update the checkaddress state with the updated array
        setcheckaddress(updatedAddresses);
      })
      .catch((error) => {
        console.log("Error deleting address:", error);
      });
    // console.log("delete address on click", item._id);
  };

  const deleteAPI = async (id) => {
    let data = JSON.stringify({
      _id: id,
    });

    let confige = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/address/delete`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
      data: data,
    };

    axios
      .request(confige)
      .then((response) => {
        // console.log((response.data.data));
        return response.data.data;
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const addresfunction1 = (item) => {
    setprevclick(true);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    GetAddressAPI();
  }, [modalShow]);

  const handleCheckboxChange = (event) => {
    setCheckBoxState(!checkBoxState);
  };

  return (
    <>
      {/* <div className="row">
        <div
          className="col p-4"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h4 className="Newcolored">{shippingAddress}</h4>
          <button
            style={{
              backgroundColor: "#472878",
              color: "white",
              padding: "5px",
              borderRadius: "6px",
            }}
            onClick={() => {
              addresfunction1();
            }}
          >
            <FaPlus style={{ color: "goldenrod", marginRight: "5px" }} />
            {addAddressBtn}
          </button>
        </div>
      </div> */}

      <div
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: "#4a2a7d",
          marginBottom: "2rem",
        }}
      >
        Billing & Shipping Address
      </div>

      <div
        style={{
          // display: "flex",
          // flexDirection: "column",
          // gap: 2,
          // margin: "10px",

          // display: "grid",
          // gridTemplateColumns: "1fr 1fr",
          // gap: "30px",
          color: "#4A2A7D",
        }}
      >
        {checkaddress.length > 0 ? (
          <div
            style={
              {
                // display: "grid",
                // gridTemplateColumns: "1fr 1fr",
                // gap: "3rem",
              }
            }
            className="address_cards_container"
          >
            {/* BILLING ADDRESS  */}
            <div
              style={{
                padding: "2rem",
                borderRadius: "10px",
                boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  Billing Address
                </div>
                {/* <Tooltip title="Delete">
                  <IconButton>
                    <MdDeleteForever
                      color="red"
                      // onClick={() => handleRemove(item.product_id)}
                    />
                  </IconButton>
                </Tooltip> */}
              </div>
              <hr />
              {checkaddress
                .filter((item) => item.type === "B")
                .map((item, index) => (
                  <div>
                    {/* <Link
                      key={index}
                      to={
                        location.state.metalType === "gold"
                          ? "/dashboard/confirmation"
                          : location.state.metalType === "silver"
                          ? "/dashboard/confirmation"
                          : `/dashboard/spotGold/${location.state}/shippingAddress/confirmation`
                      }
                      state={{ coinDetail: location.state, address: item }}
                    > */}
                    <div
                      // className="address-responsive"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                        fontSize: "25px",
                        color: "#4a2a7d",
                        gap: "0.7rem",
                      }}
                      // onClick={() => handleAddressSelection(item._id, item)}
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 3fr",
                          width: "100%",
                          gap: "2rem",
                        }}
                      >
                        <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                          {name}:
                        </div>
                        <div style={{ fontSize: "1.2rem" }}>{item.name}</div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 3fr",
                          width: "100%",
                          gap: "2rem",
                        }}
                      >
                        <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                          {phone}:
                        </div>
                        <div style={{ fontSize: "1.2rem" }}>
                          {item.mobileNumber}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 3fr",
                          width: "100%",
                          gap: "2rem",
                        }}
                      >
                        <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                          {address1}:
                        </div>
                        <div style={{ fontSize: "1.2rem" }}>{item.line1}</div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 3fr",
                          width: "100%",
                          gap: "2rem",
                        }}
                      >
                        <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                          {address2}:
                        </div>
                        <div style={{ fontSize: "1.2rem" }}>{item.line2}</div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 3fr",
                          width: "100%",
                          gap: "2rem",
                        }}
                      >
                        <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                          {city}:
                        </div>
                        <div style={{ fontSize: "1.2rem" }}>{item.city}</div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 3fr",
                          width: "100%",
                          gap: "2rem",
                        }}
                      >
                        <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                          {state}:
                        </div>
                        <div style={{ fontSize: "1.2rem" }}>{item.state}</div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 3fr",
                          width: "100%",
                          gap: "2rem",
                        }}
                      >
                        <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                          {zipCode}:
                        </div>
                        <div style={{ fontSize: "1.2rem" }}>{item.zip}</div>
                      </div>
                    </div>
                    {/* </Link> */}
                    {/* <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handledelete(item);
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </div> */}
                  </div>
                ))}
            </div>
            {/* SHIPPING ADDRESS  */}
            <div
              style={{
                padding: "2rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  Shipping Address
                </div>
                {/* <Tooltip title="Delete">
                  <IconButton>
                    <MdDeleteForever
                      color="red"
                      // onClick={() => handleRemove(item.product_id)}
                    />
                  </IconButton>
                </Tooltip> */}
              </div>
              <hr />
              <FormControlLabel
                control={<Checkbox checked={checkBoxState} />}
                label="Same As Billing Address"
                onClick={handleCheckboxChange}
              />
              {checkaddress.filter((item) =>
                checkBoxState ? item.type === "B" : item.type === "D"
              ).length > 0 ? (
                checkaddress
                  .filter((item) =>
                    checkBoxState ? item.type === "B" : item.type === "D"
                  )
                  .map((item, index) => (
                    <div key={index}>
                      {/* <Link
                        to={
                          location.state.metalType === "gold"
                            ? "/dashboard/confirmation"
                            : location.state.metalType === "silver"
                            ? "/dashboard/confirmation"
                            : `/dashboard/spotGold/${location.state}/shippingAddress/confirmation`
                        }
                        state={{ coinDetail: location.state, address: item }}
                      > */}
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          width: "100%",
                          fontSize: "25px",
                          // backgroundColor: "rosybrown",
                          color: "#4a2a7d",
                          gap: "0.7rem",
                        }}
                      >
                        {/* Address display code */}
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 3fr",
                            width: "100%",
                            gap: "2rem",
                          }}
                        >
                          <div
                            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                          >
                            {name}:
                          </div>
                          <div style={{ fontSize: "1.2rem" }}>{item.name}</div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 3fr",
                            width: "100%",
                            gap: "2rem",
                          }}
                        >
                          <div
                            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                          >
                            {phone}:
                          </div>
                          <div style={{ fontSize: "1.2rem" }}>
                            {item.mobileNumber}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 3fr",
                            width: "100%",
                            gap: "2rem",
                          }}
                        >
                          <div
                            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                          >
                            {address1}:
                          </div>
                          <div style={{ fontSize: "1.2rem" }}>{item.line1}</div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 3fr",
                            width: "100%",
                            gap: "2rem",
                          }}
                        >
                          <div
                            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                          >
                            {address2}:
                          </div>
                          <div style={{ fontSize: "1.2rem" }}>{item.line2}</div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 3fr",
                            width: "100%",
                            gap: "2rem",
                          }}
                        >
                          <div
                            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                          >
                            {city}:
                          </div>
                          <div style={{ fontSize: "1.2rem" }}>{item.city}</div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 3fr",
                            width: "100%",
                            gap: "2rem",
                          }}
                        >
                          <div
                            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                          >
                            {state}:
                          </div>
                          <div style={{ fontSize: "1.2rem" }}>{item.state}</div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 3fr",
                            width: "100%",
                            gap: "2rem",
                          }}
                        >
                          <div
                            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                          >
                            {zipCode}:
                          </div>
                          <div style={{ fontSize: "1.2rem" }}>{item.zip}</div>
                        </div>
                      </div>
                      {/* </Link> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        {/* <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            handledelete(item);
                          }}
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button> */}

                        <Link
                          to={
                            location.state.metalType === "gold"
                              ? "/dashboard/confirmation"
                              : location.state.metalType === "silver"
                              ? "/dashboard/confirmation"
                              : `/dashboard/spotGold/${location.state}/shippingAddress/confirmation`
                          }
                          state={{ coinDetail: location.state, address: item }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#4a2a7d",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#4a2a7d",
                              },
                            }}
                          >
                            Proceed
                          </Button>
                        </Link>
                        <Tooltip title="Delete">
                          <IconButton>
                            <MdDeleteForever
                              color="red"
                              size={40}
                              onClick={() => {
                                handledelete(item);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      color: "#4a2a7d",
                    }}
                  >
                    <IoIosAddCircleOutline
                      size={100}
                      onClick={() => {
                        addresfunction1();
                      }}
                    />
                    <div>Add Address</div>
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>{noAddressFound}</>
        )}
      </div>

      {prevaddclick && (
        <MainModal
          heading="Shipping Address"
          show={modalShow}
          Content={<ShippingAddressForm handleModalClose={handleModalClose} />}
          onHide={() => setModalShow(false)}
        />
      )}
    </>
  );
};

export default ShippingAddress;
