import React from "react";
import Footer from "../../components/footer/Footer";
import Breadcrumb from "../../components/Breadcrumb";

export default function Terms() {
  return (
    <div>
      <Breadcrumb currentPageName="Terms & Conditions" />
      <div
        style={{
          maxWidth: "1240px",
          margin: "auto",
          padding: "0.5rem",
          color: "#4a2a7d",
        }}
      >
        <h5>Terms of Use | Batuk</h5>
        <p>
          The document serves as an electronic record under the purview of the
          Information Technology Act, 2000, and its subsequent amendments, along
          with relevant rules and amended provisions concerning electronic
          records across various statutes, as influenced by the Information
          Technology Act, 2000. This electronic record originates from a
          computer system and does not necessitate any physical or digital
          signatures. <br /> <br />
          Prior to registering or utilizing the app (“Batuk”) or the website{" "}
          <a
            href="https://batuk.gold/"
            target="_blank"
            style={{ color: "blue" }}
          >
            www.batuk.gold
          </a>
          , which are respectively registered under the company names Bharat
          Batuk Private Limited, duly incorporated under the Companies Act,
          1956, as amended in 2013, users are advised to carefully review the
          terms of use. The terms and conditions delineated herein constitute a
          legally binding contract (“agreement”) between the user or customer
          (“you”) and Bharat Batuk Private Limited, with its registered office
          located at [registered office address] and its business or corporate
          office situated at [business/corporate office address].
          <br /> <br />
          By registering or logging into the relevant web portal or application,
          users automatically acknowledge and agree to the terms and conditions
          outlined in this document. It is emphasized that these terms and
          conditions are subject to periodic amendment or revision, as
          necessitated by changes in laws, statutory requirements, or company
          policies. By continuing to use the app or web portal, users are deemed
          to have acknowledged, accepted, and agreed to any such modifications
          or amendments made to the policy over time.
          <br /> <br />
          The company reserves the right to amend the terms and conditions at
          any time by posting an updated version on the website, through
          pop-ups, and/or on the mobile app. The updated version of the terms of
          service becomes effective immediately upon posting. Users’ continued
          use of the app following the posting of changes indicates acceptance
          and agreement to the amended provisions, which may include additional
          terms, removal of portions of these terms, or other modifications.
        </p>
        <h5>Definition</h5>
        <p>
          “Batuk” – means Bharat Batuk Private Limited, Batuk App, Batuk Web
          Portal, any duly authorised representative/officer
          <br /> <br />
          “We,” “us,” “our” – refers to Bharat Batuk Private Limited/Batuk
          App/Batuk Web Portal
          <br /> <br />
          “You,” “yours,” “Yourself,” “User”,”Customer” – refers to any
          individual/person, non-registered individual or corporate body,
          registered user of the app, including but not limited to users of
          services provided by Batuk.
        </p>
        <h5>Purpose of this Document</h5>
        <p>
          This document outlines the terms and conditions governing the
          provision of Batuk services to users. It specifies the nature of our
          service, usage guidelines, and other regulations governing our
          relationship with users.
        </p>
        <h5>Applicability</h5>
        <p>
          These terms apply to anyone who visits the Batuk Platform or creates
          an account on it (“users” or “you” or “yours” or “yourself”).
        </p>
        <h5>Importance</h5>
        <p>
          By accessing or using the Batuk Platform, users are bound by these
          Terms & Conditions. Users acknowledge our rights and agree to adhere
          to the obligations outlined herein. <br /> <br />
          Absolutely. These Terms & Conditions constitute a legally binding
          agreement. Therefore, it is essential to read them carefully before
          using the Batuk Platform.
        </p>
        <h5>Should you read the entire document?</h5>
        <p>
          Absolutely. These Terms & Conditions constitute a legally binding
          agreement. Therefore, it is essential to read them carefully before
          using the Batuk Platform.
        </p>
        <h5>Can these Terms change?</h5>
        <p>
          Yes. We reserve the right to revise these Terms as needed and update
          the Batuk Platform periodically. You will be intimated accordingly and
          kept informed. Your continuous usage shall be treated as a consent to
          these updated or amended terms of use. If you disagree with any part
          of these Terms, you may cease using the Batuk Platform immediately.
          <br /> <br />
          Certainly. If you have any queries or concerns regarding these Terms
          or the Batuk Platform, please contact our Grievance Officer
          (grievance@batuk.in).
        </p>
        <h5>OUR SERVICES</h5>
        <p>
          We aim to make the batuk platform a user friendly, digital gateway, a
          fintech platform to enable users/customers to gain access to multiple
          financial products and services such as buying and selling of digital
          gold and silver, investment products, loan products and proprietary
          products such as will creation and tax filing etc, and also endeavour
          to add more innovative products for the users/customers. To create an
          account, you must be at least 18 years old legally competent and KYC
          Compliant as per the prevailing rules and regulations.
        </p>
        <h5>Nature of our service</h5>
        <p>
          We facilitate savings and investments in third-party financial
          products listed on the Batuk platform, providing features to track
          your savings and investments and other proprietary fintech enabled
          services.
        </p>
        <h5>Eligibility</h5>
        <p>
          You may use our services only if you are 18 years of age or older and
          legally capable of entering into a contract. You must not be
          incapacitated under the Indian Contract Act, 1872.
        </p>
        <h5>Creating a Batuk account</h5>
        <p>
          While the batuk platform is user friendly, to access the Batuk
          Platform, you must create a Batuk Account. We require your mobile
          number for authentication via a one-time password. Additionally, we
          may request your name, email address, age, residential address, and
          other necessary information as may be required for KYC norms and
          regulations.
        </p>
        <h5>Disclaimer</h5>
        <p>
          We are a facilitator only, we bridge the gap between the user and the
          product/service provider. Batuk does not handle any payment into their
          bank account, the entire transaction is routed through the respective
          bank account of the service provider. Therefore, only being a
          facilitator or an intermediary, Batuk ceases to take responsibility or
          provide any kind of warranties or securities which is therefore at the
          sole cause and risk of the customer.
          <br /> <br />
          For any related risk or loss or otherwise the user may direct or
          initiate their grievance or action as appropriate at their sole cost
          and risk against the product or service provider and shall hold them
          liable or responsible directly and that the user undertakes, accepts
          and agrees that Batuk platform is only a facilitator and therefore can
          not be held responsible.
        </p>
        <h5>
          We share your information and documents to our partners through secure
          channels. However, in case of unauthorized access or any security
          breach, we assume no liability or responsibility for the disclosure of
          your information or documents to third-parties.
        </h5>
        <p>
          Please read the terms of our partners on their respective websites
          carefully before investing. We are not responsible for their actions
          or products.
        </p>
        <h5>KYC verification</h5>
        <p>
          Before investing, we conduct Know Your Customer (KYC) verification as
          per applicable law and our various partners listed terms and
          conditions. We may request documents such as PAN card, Aadhar card,
          occupation, salary details, or other necessary information.
        </p>
        <h5>Deposit of funds</h5>
        <p>
          Funds for savings or investments can be transferred using various
          payment modes available on the Batuk Platform. User funds are directly
          transferred/transmitted/deposited into the bank account of our
          partners.
        </p>
        <h5>Withdrawal of funds</h5>
        <p>
          Subject to KYC verification and partner terms, users may withdraw
          investments. Withdrawal requests are forwarded to the partner, who
          deposits funds into the user’s registered bank account within the time
          frame defined by the partner. The funds you transfer or request to
          withdraw using the Batuk Platform are processed by your bank, our
          partners, and their respective banks and payment intermediaries. We
          are not responsible in case there is a delay in the transfer of funds
          or in delivering the financial product you subscribed for.
        </p>
        <h5>Acceptance to partner terms</h5>
        <p>
          Each financial product is provided by a partner, with its own terms.
          Users are responsible for understanding and adhering to these terms.
        </p>
        <h5>Disclaimers</h5>
        <p>
          Our services do not constitute any investment advice, we are only
          facilitators. We disclaim liability for investment losses.
        </p>
        <h5>Privacy and Data Protection</h5>
        <p>
          Our privacy policy (available at Privacy Policy ) explains how we
          treat your personal data and protect your privacy when you use our
          services. By accessing or using the Batuk Platform, you agree that we
          can use such data in accordance with our privacy policy.
        </p>
        <h5>INTELLECTUAL PROPERTY</h5>
        <p>
          All content and services on the Batuk Platform are our intellectual
          property and protected by law. You cannot duplicate or commercially
          exploit it in any manner.
        </p>
        <h5>Ownership</h5>
        <p>
          The Batuk Platform and our services are owned and operated by the
          Company. The visual interfaces, graphics, design, compilation,
          information, computer code (including source code and object code),
          products, software, services, and all other elements of our services
          and the Batuk Platform is our intellectual property and developed
          in-house and is protected under applicable laws.
        </p>
        <h5>Limited license</h5>
        <p>
          We give you a limited, non-transferrable, non-sublicensable and
          revocable license to access the Batuk Platform, and avail our services
          for your own personal and lawful use only.
        </p>
        <h5>No misappropriation</h5>
        <p>
          Users must not tamper with the Batuk Platform or violate intellectual
          property rights. Users are banned from copying any
          features/specifications/elements, if done so shall be liable to strict
          legal action at their sole cost and risk. You agree not to remove,
          obscure, or alter the Batuk Platform or any third party’s
          intellectual-property affixed or contained on the Batuk Platform. You
          are not entitled to duplicate, distribute, create derivative works of,
          display, or commercially exploit the Batuk Platform, our services,
          features or facilities.
        </p>
        <h5>THIRD-PARTY CONTENT AND ADVERTISEMENT</h5>
        <p>
          We may display offers or links from third parties, but do not endorse
          or warrant their products.
        </p>
        <h5>Referrals</h5>
        <p>
          Users are encouraged to refer others to the Batuk Platform and may
          receive rewards for successful referrals from time to time as per the
          program offered.
        </p>
        <h5>TERMINATION</h5>
        <p>
          We reserve the right to suspend or terminate services and access to
          the Batuk Platform under certain circumstances. However, it is
          clarified that your account holdings/savings/investments with the
          partner or service provider shall remain intact and in such an event,
          you may be required to connect with these product/service providers.{" "}
          <br />
          <br />
          You acknowledge and agrees that the Company may, at its sole
          discretion, without notice, suspend or terminate its services and your
          access to the Batuk Platform, if:
          <ul>
            <li>
              You engage in any conduct or activities that the Company, in its
              sole discretion, believes to be violative of these Terms, or is
              otherwise inappropriate for your continued access to the Batuk
              Platform.
            </li>
            <li>
              We learn that the user is dead, bankrupt, or lacks legal capacity
              to avail our services.
            </li>
            <li>We are required to do so by applicable law.</li>
            <li>
              The provision of our services to You is no longer commercially
              viable or feasible for us.
            </li>
            <li>If your KYC has been found to be faulty or suspicious</li>
            <li>
              If you are found to be using or accessing third party bank
              accounts or payment instruments.
            </li>
            <li>
              If it is found that you have concealed important/regulatory/lawful
              information/details.
            </li>
          </ul>
        </p>
        <h5>INDEMNITY</h5>
        <p>
          You agree to defend, indemnify and hold the Company, its group
          entities, vendors, business partners, associates, directors, officers,
          and employees harmless from any and all claims, loss, liabilities,
          damages, costs, action, expenses or any liability that they may suffer
          from your violation of these Terms or your unauthorized use of the
          Batuk Platform or any loss arising out of IPR/Trademark etc violation.
        </p>
        <h5>GRIEVANCE REDRESSAL</h5>
        <p>
          Any complaints or concerns with regards to the content on Batuk
          Platform, our services, or your disagreement to these Terms, shall be
          immediately informed, with all the relevant details, to the designated
          Grievance Officer through email to: grievance@batuk.in <br />
          <br />
          Our Grievance Officer will take note of your query or complaint as
          soon possible. You acknowledge that this process might take some time
          and agree to not hold us liable for any reasonable delays. We will get
          back to you as soon as possible.
        </p>
        <h5>GOVERNING LAW & JURISDICTION</h5>
        <p>
          Applicable law: The Batuk Platform, our services, these Terms, and all
          transactions entered on through the Batuk Platform between the user
          and us will be governed and construed by the applicable laws of India.
        </p>
        <h5>Jurisdiction</h5>
        <p>
          You agree that all claims, differences and disputes arising under
          these Terms or from your use of the Batuk Platform are subject to the
          exclusive and competent jurisdiction of the courts in Delhi. However,
          if the Company thinks fit, <br /> <br />
          it may institute proceedings against you in any other court or
          tribunal having appropriate jurisdiction based on the profile of the
          user/customer.
        </p>
        <h5>FORCE MAJEURE</h5>
        <p>
          The Company is not liable for disruptions beyond its control. Without
          limiting anything in these Terms, under no circumstances shall the
          Company be held liable for any damage, loss, or suspension of services
          on the Batuk Platform, resulting directly or indirectly from acts of
          nature, forces, or causes beyond its reasonable control, including,
          without limitation, internet failures, computer equipment failures,
          telecommunication equipment failures, or any other government
          regulations, change in governing laws, or interference by government
          agencies/bodies, floods, pandemic, lockdowns, storms, electrical
          failure, civil disturbances, riots.
        </p>
        <h5>LIMITATION OF LIABILITY</h5>
        <p>
          The Company accepts no liability whatsoever, direct or indirect for
          non-compliance with the applicable laws of any country other than that
          of India. In case the Batuk Platform is accessed or used, or any of
          our services can be availed from a country outside India, it will not
          obligate us to comply with the laws of such country or jurisdiction.
        </p>
        <h5>MISCELLANEOUS</h5>
        <p>
          <b>Notices:</b> We will send notices and communications to you via.
          e-mail, letter, telephone, SMS, or any other means that we deem fit,
          to the address available in our records. The notice or communications
          will be deemed communicated once it is delivered from our personnel or
          automated systems. We may also, at our sole discretion, publish
          notices of general nature, which are applicable to all users and have
          the same effect as a notice served individually. <br />
          <br />
          <b>Dispute resolution:</b> If any dispute arises between the user and
          the Company, in connection with validity, interpretation,
          implementation or alleged material breach of these Terms or the use of
          the Batuk Platform, both parties will shall endeavour to settle such
          dispute amicably. In case of failure to resolve the dispute, the
          courts at New Delhi shall have sole jurisdiction upon the same. <br />
          <br />
          <b>No Waiver:</b> Unless waived or specifically agreed in writing by
          the Company, no action or inaction by us under these Terms will
          constitute a waiver of our rights or remedies available to us under
          applicable law. <br />
          <br /> <b>Severability:</b> If any provision of these Terms is
          determined to be legally unenforceable or invalid, the remaining
          provisions will continue in effect. The Company will substitute a
          provision that most closely approximates the effect and intent of the
          invalid provision. <br />
          <br />
          <b>No Waiver:</b> Unless waived or specifically agreed in writing by
          the Company, no action or inaction by us under these Terms will
          constitute a waiver of our rights or remedies available to us under
          applicable law. <br />
          <br />
          <b>Severability:</b> If any provision of these Terms is determined to
          be legally unenforceable or invalid, the remaining provisions will
          continue in effect. The Company will substitute a provision that most
          closely approximates the effect and intent of the invalid provision.{" "}
          <br />
          <br />
          <b>Assignment:</b> You cannot assign or otherwise transfer these Terms,
          or any rights granted here to any third-party. Our rights, however,
          under these Terms are freely transferable to any third party without
          the requirement of seeking your consent. <br />
          <br /> <b>Subscribe:</b> I accept the Terms & Conditions, Terms of
          Use, Privacy Policy and unconditionally consent and authorise Bharat
          Batuk and its representatives to contact me via channels like
          telephone,SMS,Email or Whatsapp, regarding your account and other
          applicable offers. This consent will override any registration under
          DNC/NDNC.
        </p>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
