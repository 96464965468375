import React, { useEffect, useState } from "react";
import axios from "axios"; // Import axios for making HTTP requests
import "./coinbuy.css"; // Import CSS file for styling
import FormComp from "./BuyNow";
import MainModal from "../../components/MainModal";
import { FaPlus } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { config } from "../../config";
import { FaRegEdit } from "react-icons/fa";
// import { FaCheckCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Swal from "sweetalert2";
import Confirmationpage from "./Confirmationpage";
import { useNavigate } from "react-router-dom";
import ShippingAddressForm from "../../components/ShippingAddressForm";
// import Swal from 'sweetalert2/dist/sweetalert2.js'

const CoinDetail = ({ data, onHide }) => {
  const navigate = useNavigate();
  const Token = sessionStorage.getItem("token");
  // console.log("login token in inbox", Token);
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const [cardView, setCardView] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  // State variables to store fetched coin data
  const [coinData, setCoinData] = useState(null); // Initialize with null as initial state
  const { productImages, purity, productWeight, sku, description, basePrice } =
    data; // Destructure props
  const [mainImage, setMainImage] = useState(null); // Initialize with null as initial state
  const [viewBuyNow, setViewBuyNow] = useState(false);
  const [productView, setproductView] = useState(true);
  // const [selectAddress, setSelectAddress] = useState(false);
  const [confirmationView, setConfirmationView] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [addressview, setaddressview] = useState(false);
  const [checkaddress, setcheckaddress] = useState([]);
  const [editdata, seteditdata] = useState({});
  const [checkitem, setcheckItem] = useState({});

  // useEffect to call API once when component mounts
  useEffect(() => {
    callAPI(); // Call the API function
    GetAddressAPI();
  }, []);

  const callAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/products/show-product/${sku}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setCoinData(data?.data[0]?.result?.data);
      setMainImage(data?.data[0]?.result?.data?.productImages[0]?.url);

      // setSumdata(data?.data?.[0]?.result?.data?.filter(item => item.metalType === 'gold') || []);
    } catch (error) {
      // console.error("Error connecting to API", error);
    }
  };

  // Function to handle image click
  const handleImageClick = (index) => {
    // Change the main image URL to the clicked thumbnail's URL
    if (coinData) {
      const clickedImage = coinData.productImages[index].url;
      setMainImage(clickedImage);
    }
  };

  // Log coinData value
  // console.log("coinData", coinData);

  // JSX to render the component

  function handleBuyNow() {
    GetAddressAPI();
    console.log("User Address", checkaddress);
    setViewBuyNow(true);
    setaddressview(true);
    setproductView(false);
  }

  const GetAddressAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/address/get_address`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();
      console.log("DISHANT++++++++++++++++++++", data.data);
      // setcheckaddress(
      //   data?.data?.filter((item) => {
      //     return item.type === "D";
      //   })
      // );

      setcheckaddress(
        data.data.map((item, i) => {
          return item;
        })
      );
    } catch (error) {
      console.error("ERROR IN CATCH+++++++++++++++++", error);
    }
  };

  // console.log("API RESPONSE++++++++++++++++++++++++",checkaddress)

  const deleteAPI = async (id) => {
    let data = JSON.stringify({
      _id: id,
    });

    let confige = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/address/delete`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
      data: data,
    };

    axios
      .request(confige)
      .then((response) => {
        // console.log((response.data.data));
        return response.data.data;
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const [addclick, setaddclick] = useState(false);
  const [prevaddclick, setprevclick] = useState(false);

  const editAddressAPI = async (id, updatedData) => {
    let data = JSON.stringify({
      // "city": cityName,
      // "country": "india",
      // "_id": widget.Edit['_id'],
      // "line1": address1Controller.text,
      // "mobileNumber": _numberController.text,
      // "name": nameController.text,
      // "state": countryName,
      // "statecode": widget.Edit['statecode'],
      // "type": "${widget.Edit['type']}",
      // "zip": zipController.text,
      // "line2": address2Controller.text,
    });

    let confige = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/address/update`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
      data: data,
    };

    axios
      .request(confige)
      .then((response) => {
        // console.log((response.data.data));
        return response.data.data;
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleEditAddress = async () => {
    try {
      const updatedData = {
        /* Construct the updated address data */
      };
      const response = await editAddressAPI(editdata._id, updatedData);
      // console.log('Address updated successfully:', response.address);
      // Optionally, you can update the UI or perform any other actions after successful update
    } catch (error) {
      // console.error('Error updating address:', error.message);
      // Handle error: display error message to the user, etc.
    }
  };

  const addresfunction = (item) => {
    setaddclick(true);
    setModalShow(true);
    // console.log("edit address on click", item);
    seteditdata(item);
  };

  const addresfunction1 = (item) => {
    setprevclick(true);
    // console.log("deliver address on click", item);
    setModalShow(true);
  };

  // Function to handle selection of an address
  const handleAddressSelection = (selectedAddressId, item) => {
    setSelectedAddress(selectedAddressId);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#880E4F",
      cancelButtonColor: "#d33",
      confirmButtonText: "Proceed to buy!",
    }).then((result) => {
      if (result.isConfirmed) {
        setConfirmationView(true);
        setaddressview(false);
        setproductView(false);
        setcheckItem(item);
      }
    });
  };

  const handledelete = (item) => {
    deleteAPI(item._id)
      .then(() => {
        // Filter out the deleted address from checkaddress state
        const updatedAddresses = checkaddress.filter(
          (address) => address._id !== item._id
        );
        // Update the checkaddress state with the updated array
        setcheckaddress(updatedAddresses);
      })
      .catch((error) => {
        // console.log("Error deleting address:", error);
      });
    // console.log("delete address on click", item._id);
  };

  return (
    <div>
      {productView && (
        <div>
          {coinData ? (
            <div>
              <div className="card p-4 ">
                <div className="row">
                  <div
                    className="col-md-4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="row text-center">
                      <div
                        className="col-md-12"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="row">
                          <div className="c-l-md-12">
                            <img
                              className="img-fluid"
                              style={{ width: 200 }}
                              src={mainImage}
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12">
                            <div className="d-flex justify-content-evenly align-items-center flex-wrap">
                              {coinData.productImages.map((image, index) => (
                                <img
                                  key={index}
                                  className="img-thumbnail mt-1 contentshadow"
                                  style={{ height: 60 }}
                                  src={image.url}
                                  alt={coinData.title}
                                  onClick={() => handleImageClick(index)}
                                />
                              ))}
                            </div>
                          </div>
                          <div className="col-12">
                            <div>
                              <h6 className="mt-4" style={{ color: "#4A2A7D" }}>
                                Delivery & making charges
                              </h6>
                              <h6 style={{ color: "#4A2A7D" }}>
                                &#8377;{basePrice}(All Incl.)
                              </h6>
                            </div>
                            <button
                              //   className="btn w-75 mt-2 text-light"
                              onClick={() => {
                                handleBuyNow();
                              }}
                              style={{
                                backgroundColor: "#4A2A7D",
                                color: "white",
                                padding: "5px 5px",
                                borderRadius: "5px",
                              }}
                            >
                              Buy now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="">
                      <div className="">
                        <div className="contentshadow">
                          <h5 className="colore" style={{ color: "#4A2A7D" }}>
                            Product Details
                          </h5>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                            }}
                          >
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th
                                    className="colore"
                                    style={{ color: "#4A2A7D" }}
                                  >
                                    Weight
                                  </th>
                                  <td className="coloreddescription">
                                    {productWeight} gm
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    className="colore"
                                    style={{ color: "#4A2A7D" }}
                                  >
                                    Metal Purity
                                  </th>
                                  <td className="coloreddescription">
                                    {purity}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    className="colore"
                                    style={{ color: "#4A2A7D" }}
                                  >
                                    Sku Id
                                  </th>
                                  <td className="coloreddescription">{sku}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="contentshadow">
                          <h5 className="colore" style={{ color: "#4A2A7D" }}>
                            Description
                          </h5>
                          <p
                            className="coloreddescriptio"
                            style={{ color: "#4A2A7D" }}
                          >
                            {description}
                          </p>
                        </div>
                        {/* <div className='contentshadow'>
                            <h5 className='colored'>Product Retails</h5>
                            <p className='coloreddescription'>{data.description}</p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}

      {addressview && (
        <>
          <div className="row">
            <div
              className="col p-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h5 className="Newcolored">Shipping Addresses</h5>
              <button
                style={{
                  backgroundColor: "#472878",
                  color: "white",
                  padding: "5px",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  addresfunction1();
                }}
              >
                <FaPlus style={{ color: "goldenrod", marginRight: "5px" }} />
                Add Address
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              margin: "5px",
              color: "#4A2A7D",
            }}
          >
            {checkaddress.length > 0 ? (
              checkaddress.map((item, index) => (
                <div
                  key={index}
                  className={`contentshadow rounded ${
                    selectedAddress === item._id ? "selected" : ""
                  }`}
                >
                  <div className="row">
                    <div
                      className="col-6"
                      style={{
                        display: "flex",
                        alignSelf: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      {selectedAddress === item._id && (
                        <IoMdCheckmarkCircleOutline />
                      )}
                    </div>
                    <div
                      className="col-6"
                      style={{
                        display: "flex",
                        alignSelf: "flex-end",
                        justifyContent: "flex-end",
                        fontSize: "25px",
                      }}
                    >
                      <FaRegEdit
                          style={{ cursor: "pointer" }}
                        onClick={() => {
                          addresfunction(item);
                        }}
                      />
                      <MdDeleteForever
                        onClick={() => {
                          handledelete(item);
                        }}
                        style={{ marginRight: 5, marginLeft: 5,cursor:"pointer" }}
                      />
                    </div>
                  </div>

                  {/* <table key={index} className="table">
                    <tbody>
                      <tr
                        onClick={() => handleAddressSelection(item._id, item)}
                      >
                        <td>{item.name}</td>
                        <td>{item.mobileNumber}</td>
                        <td>
                          {item.line1}, {item.line2}
                        </td>
                        <td>{item.city}</td>
                        <td>{item.state}</td>
                        <td>{item.zip}</td>
                      </tr>
                    </tbody>
                  </table> */}

                  <div
                    className="address-responsive"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleAddressSelection(item._id, item)}
                  >
                    <div className="address-inner-box">
                      <div style={{ fontsize: "25px", fontWeight: "bold" }}>
                        Name
                      </div>
                      <div>{item.name}</div>
                    </div>
                    <div className="address-inner-box">
                      <div style={{ fontsize: "25px", fontWeight: "bold" }}>
                        Phone No.
                      </div>
                      <div>{item.mobileNumber}</div>
                    </div>
                    <div className="address-inner-box">
                      <div style={{ fontsize: "25px", fontWeight: "bold" }}>
                        Address
                      </div>
                      <div>{item.line1}</div>
                    </div>
                    <div className="address-inner-box">
                      <div style={{ fontsize: "25px", fontWeight: "bold" }}>
                        Address
                      </div>
                      <div>{item.line2}</div>
                    </div>
                    <div className="address-inner-box">
                      <div style={{ fontsize: "25px", fontWeight: "bold" }}>
                        city
                      </div>
                      <div>{item.city}</div>
                    </div>
                    <div className="address-inner-box">
                      <div style={{ fontsize: "25px", fontWeight: "bold" }}>
                        State
                      </div>
                      <div>{item.state}</div>
                    </div>
                    <div className="address-inner-box">
                      <div style={{ fontsize: "25px", fontWeight: "bold" }}>
                        Zip code
                      </div>
                      <div>{item.zip}</div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>no address found</>
            )}
          </div>
        </>
      )}

      {addclick && (
        <MainModal
          heading="Billing Address"
          show={modalShow}
          // Content={<FormComp close={onHide} data={editdata} />}
          Content={<ShippingAddressForm close={onHide} data={editdata} />}
          onHide={() => setModalShow(false)}
        />
      )}

      {prevaddclick && (
        <MainModal
          heading="Billing Address"
          show={modalShow}
          // Content={<FormComp />}
          Content={<ShippingAddressForm/>}
          onHide={() => setModalShow(false)}
        />
      )}
      {confirmationView && (
        <Confirmationpage data={data} useraddress={checkitem} />
      )}
    </div>
  );
};

export default CoinDetail;
