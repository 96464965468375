import React, { useEffect, useState } from "react";
// import Goldlogo from "../../images/NewGoldLogo.png";
import "./goldify.css";
// import Locker from "../../images/new_locker.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./goldify.scss";
import Swal from "sweetalert2";
// import { GoArrowLeft } from "react-icons/go";
// import { toast } from "react-toastify";
import BuySell from "../../components/BuySell";

const SellGold = () => {
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);
  const Token = sessionStorage.getItem("token");
  // console.log("login token in inbox", Token);

  const [AccountNo, setAccount] = useState("");
  const navigate = useNavigate();
  const [amountValue, setAmountValue] = useState("");
  const [amountInputEmpty, setAmountInputEmpty] = useState(true);
  const [weightInputEmpty, setWeightInputEmpty] = useState(true);
  const [firstvisit, setfirstvisit] = useState("");
  const [userList, setUserList] = useState([]);
  const [lokervalue, setlockervalue] = useState("");
  const [goldApiData, setGoldApiData] = useState("");
  const [portfolioApiData, setPortfolioApiData] = useState("");

  // ---------------------live value API---------------------
  let values = "";
  var clickFirst = "";
  var AccNumber;

  useEffect(() => {
    goldApi();
    portfolioApi();
    GetProfileAPI();
  }, []);

  // const goldApi = async () => {
  //   try {
  //     const url = `${process.env.REACT_APP_BASE_URL}/augmont/gold`;
  //     const headers = {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${Token}`,
  //     };
  //     const response = await axios.get(url, { headers });
  //     const data = response.data;
  //     // console.log("live Api", data);
  //     const lastval = data.data[0][data.data[0].length - 1];
  //     var goldValue = document.getElementById("num1");
  //     var val = (goldValue.value = lastval.gSell);
  //   } catch (error) {
  //     // console.error("Error connecting to API", error);
  //   }
  // };

  // --------------------Portfolio API ----------------------
  // const portfolioApi = async () => {
  //   try {
  //     const url = `${process.env.REACT_APP_BASE_URL}/act/getport`;
  //     const headers = {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${Token}`,
  //     };

  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: headers,
  //     });

  //     const data = await response.json();
  //     // console.log("gold in loker", data);
  //     const api = data.data[0].augmont.gold;
  //     // console.log("portfolio api", api);
  //     const GoldLockerValue = api.balQuantity;
  //     setlockervalue(GoldLockerValue);
  //     // console.log("locker value in sell gold", lokervalue)
  //     // SetGoldLocker(GoldLockerValue);
  //     // setLoading(false); // Set loading to false after all API calls are completed
  //   } catch (error) {
  //     // console.error("Error connecting to API", error);
  //   }
  // };

  const goldApi = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/augmont/gold`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      setGoldApiData(response);
      const data = response.data;
      const lastval = data.data[0][data.data[0].length - 1];
      var goldValue = document.getElementById("num1");
      var val = (goldValue.value = lastval.gBuy);
    } catch (error) {
      console.error("Error connecting to API", error);
      // toast.error(error.message);
    }
  };

  // --------------------Portfolio API ----------------------
  const portfolioApi = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/act/getport`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
      });

      const data = await response.json();
      setPortfolioApiData(data);
      const api = data.data[0].augmont.gold;
      const GoldLockerValue = api.balQuantity;
      // SetGoldLocker(GoldLockerValue);
    } catch (error) {
      console.error("Error connecting to API", error);
      // toast.error(error.message);
    }
  };

  // -------------------get quate buy ---------------------------
  const callQuantityAPI = async (rupeeValue, gramValue, isRupeeSelected) => {
    console.log("callQuantityAPI called++++++++++++++++++++++");
    console.log(
      "AMOUNT & QUANTITY++++++++++++++++++++++",
      rupeeValue,
      gramValue
    );
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/augmont/get-quote-sell`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      // var amountInput1 = document.getElementById("num2").value;
      // var weightInput1 = document.getElementById("num3").value;
      // const requestBody = { metalType: "gold" };

      // if (weightInput1 == "" && amountInput1 !== "") {
      //   requestBody["amount"] = amountInput1;
      //   clickFirst = "A";
      // } else if (amountInput1 == "" && weightInput1 !== "") {
      //   requestBody["quantity"] = weightInput1;
      //   clickFirst = "Q";
      // } else {
      //   requestBody["amount"] = amountInput1;
      //   clickFirst = "A";
      // }

      const requestBody = {
        metalType: "gold",
        ...(isRupeeSelected ? { amount: rupeeValue } : { quantity: gramValue }),
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      navigate("/dashboard/goldsellsummary", {
        state: {
          data: data,
        },
      });
      return data;
    } catch (error) {
      console.error("Error connecting to API", error);
      throw error; // Re-throw the error to handle it in GotoSummary
    }
  };

  // ------------------ End --------------------------------------

  // ------------------------calculation logic ----------------
  const handleInputChange = (inputId) => {
    const num1 = parseFloat(document.getElementById("num1").value);
    const num2 = parseFloat(document.getElementById("num2").value);
    const num3 = parseFloat(document.getElementById("num3").value);

    if (inputId === "num1") {
      const result = isNaN(num1) || isNaN(num2) ? "" : num2 / num1;
      document.getElementById("num3").value = result;
    }
    if (inputId === "num3") {
      const amount = isNaN(num3) || isNaN(num1) ? "" : num3 * num1;
      var amountFormatted = amount === "" ? "" : amount.toFixed(2);
      document.getElementById("num2").value = amountFormatted;
    } else if (inputId === "num2") {
      const x = num2.toString();

      if (x !== "NaN") {
        setAmountValue(num2);
      } else {
        setAmountValue(0);
      }
      var weight =
        isNaN(num2) || isNaN(num1) || num1 === 0 || num2 === ""
          ? ""
          : num2 / num1;
      var weightFormatted = weight === "" ? "" : weight.toFixed(2);
      document.getElementById("num3").value = weightFormatted;
    }
    if (inputId === "num2" || inputId === "num3") {
      // setAmountValue(document.getElementById("num2").value);
      const inputValue = document.getElementById(inputId).value;

      const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");

      const parts = sanitizedValue.split(".");

      document.getElementById(inputId).value = parts.join(".");
    }

    if (inputId === "num2") {
      setAmountInputEmpty(num2 === "");
    } else if (inputId === "num3") {
      setWeightInputEmpty(num3 === "");
    }
  };

  // ------------------------ redirect funtion ------------------

  const GotoSummary = async () => {
    // console.log("locker value", lokervalue)

    if (lokervalue === " " || lokervalue <= 0) {
      Swal.fire("You don't have safficiend gold..");
      // navigate("/dashboard/bankdetails");
    } else if (AccountNo === null || lokervalue <= 0) {
      // Swal.fire("You don't have safficiend gold..");
      navigate("/dashboard/bankdetails");
      // console.log("check from gold sell summary");
    } else {
      const data = await callQuantityAPI();
      // console.log("data okkkk kkkkkkkkkkk", data); // Call the API to fetch data
      navigate("/dashboard/goldsellsummary", {
        state: {
          data: data,
          clickFirst: clickFirst,
        },
      });
    }
  };

  // ----------------------  sell profile-----------------
  const GetProfileAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/getProfile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const Profiledata = response.data;
      // console.log("Get Profile API Response", Profiledata);
      AccNumber = Profiledata.data[0].kyc.account_no;
      setAccount(AccNumber);
      return AccNumber;
      // setpanum(AccNumber);
      // console.log("pan number sandy sir", AccNumber);
      // Store profile data in session storage
      // sessionStorage.setItem("ProfileData", JSON.stringify(Pannumber));
      // console.log("Profile data stored in session storage", Pannumber);
    } catch (error) {
      // console.error("Error connecting to API", error);
    }
  };

  return (
    <div className="container-fluid" style={{ color: "#4A2A7D" }}>
      <div className="row d-flex justify-content-evenly">
        {/* <div
          className="col-md-7 p-4 "
          style={{
            padding: "10px 10px",
            borderRadius: "10px",
            boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)",
          }}
        >
          <h3 className="fw-bold text-center" style={{ color: "#4A2A7D" }}>
            Digital Gold
          </h3>
          <div className="row" style={{ borderRadius: "10px", border: "2px solid #E3B041", color: "#4A2A7D" }}>
            <div className='row d-flex justify-content-around'>
              <div className='col-md-1 d-flex justify-content-start my-auto p-0' style={{ height: "auto" }}>
                <img src={Goldlogo} className='img-fluid p-0 mx-2' style={{ height: "auto", width: "150px" }} />
              </div>
              <div className='col-md-3 p-2 text-center'>
                <h4 className="fw-bold">AUGMONT</h4>
                <h6>24k 999.9 Purity</h6>
              </div>
              <div className="col-md-3  my-auto" >
                &#8377;&nbsp;
                <input type="text" id="num1" onInput={() => handleInputChange("num1")} className="live fw-bold" style={{ width: "80px", border: "none", background: "transparent", outline: "none", color: "#4A2A7D" }} readOnly />
              </div>
              <div className='col-md-2 mt-4 '>
                <h6 className=" " style={{ color: "#4A2A7D", fontSize: "20px" }}> <span className="blinking-circle"></span> &nbsp;Live</h6>
              </div>
            </div>
          
            <div className="col-md-12 w-100" style={{ color: "#4A2A7D", backgroundColor: "#C9BDF2", borderRadius: "10px" }}>
              <h4 className='fw-bold p-2'>Gold in Locker: {lokervalue} gm</h4></div>
          </div>

        



          <div className="row d-flex justify-content-center mt-4">
            <div className="col-md-5 mt-2 ">
              <div className="col-md-4 mt-3">
                <h5 style={{ color: "#4A2A7D" }}>Amount</h5>
              </div>
              <div className="col">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">&#8377;</span>
                  <input type="text" className="form-control text-dark" aria-describedby="basic-addon1" id="num2"
                    onInput={() => handleInputChange("num2")}
                    style={{ width: "70%" }}  // Reduced width
                  />
                </div>
                <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}></p>
              </div>
            </div>
            <div className="col-md-1 py-3 mt-5 mx-2 " style={{ color: "#4A2A7D" }}>
              <i className="fa-solid fa-arrows-left-right fs-5"></i>
            </div>
            <div className="col-md-5 mt-2 ">
              <div className="col-md-4 mt-3">
                <h5 style={{ color: "#4A2A7D" }}>Weight</h5>
              </div>
              <div className="col">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">gm</span>
                  <input type="text" className="form-control text-dark" aria-describedby="basic-addon1" id="num3"
                    onInput={() => handleInputChange("num3")}
                    style={{ width: "70%" }}  // Reduced width
                  />
                </div>
                <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}></p>
              </div>
            </div>
          </div>



          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn p-2 mt-3 text-light"
              style={{
                minWidth: "300px",
                borderRadius: "20px",
                fontSize: "20px",
                backgroundColor: "#4A2A7D",
              }}
              onClick={() => GotoSummary()}
              disabled={lokervalue < amountValue}
            >
              Sell &#8377;{amountValue}
            </button>
          </div>
          <div className="col-md-12 mt-3" style={{ color: "#4A2A7D" }}>
            <p className="text-center">Exclusive of 3% GST</p>
            <h6 className="text-center mt-1" style={{ color: "#4A2A7D" }}>
              Bharat Batuk Pvt Ltd (BBPL)
            </h6>
            <p className="text-center mt-2">
              Gold bought in last 7 days can not be sold.
              <br />
              BBPL offers gold products through our partners.
              <br />
              Refer to<Link target="blank" to="https://batuk.in/terms-conditions/"> Terms & Conditions</Link> of Gold for details.
            </p>
          </div>
        </div> */}

        {goldApiData && portfolioApiData && (
          <BuySell
            metalType="GOLD"
            goldApi={goldApi}
            transactionType="goldSell"
            goldApiData={goldApiData}
            portfolioApiData={portfolioApiData}
            callQuantityAPI={callQuantityAPI}
          />
        )}

        {/* <div
          className="col-sm-4 py-3"
          style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)" }}
        >
          <h4 className="text-center mb-4 top_Header">FAQ</h4>
          <Accordion className='description'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div class="summarry">
                <h2>
                  <span>01.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className='description'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div class="summarry">
                <h2>
                  <span>02.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className='description'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div class="summarry">
                <h2>
                  <span>03.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className='description'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div class="summarry">
                <h2>
                  <span>04.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className='description'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div class="summarry">
                <h2>
                  <span>05.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className='description'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div class="summarry">
                <h2>
                  <span>06.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className='description'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div class="summarry">
                <h2>
                  <span>07.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className='description'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div class="summarry">
                <h2>
                  <span>08.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>


        </div> */}
      </div>
    </div>
  );
};

export default SellGold;
