import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import "./Dashboard.css";
import "../../pages/comman.scss";
import NavbarMobile from "../../components/navbarMobile/NavbarMobile";
import { useMediaQuery } from "@mui/material";
import { resetProfile } from "../../redux/features/profileSlice";
import { resetKycDetails } from "../../redux/features/kycDetailsSlice";
import { useDispatch } from "react-redux";

const sidebarLinks = [
  {
    name: "Home",
    link: "/",
    img: "/home.png",
    img2: "/home2.png",
  },
  {
    name: "Digital Gold",
    link: "/dashboard/buygold",
    img: "/goldHome.png",
  },
  {
    name: "Digital Silver",
    link: "/dashboard/buysilver",
    img: "/silverHome.png",
  },
  {
    name: "Jewellery",
    // link: "/dashboard/spotGold",
    link: "/shop",
    img: "/jewellery_main.png",
  },
  {
    name: "Gold SIP",
    link: "/dashboard/gold-sip",
    img: "/gold_sip.png",
  },
  {
    name: "Silver SIP",
    link: "/dashboard/silver-sip",
    img: "/silver_sip.png",
  },
  // {
  //   name: "Gold FD",
  //   link: "/dashboard/goldFD",
  //   img: "../goldFDLogo.png",
  // },
  {
    name: "History",
    link: "/dashboard/history",
    img: "/historyHome.png",
    img2: "/history2.png",
  },
  {
    name: "Portfolio",
    link: "/dashboard/portfolio",
    img: "/portfolioHome.png",
    img2: "/portfolio2.png",
  },
  {
    name: "Profile",
    link: "/dashboard/profile",
    img: "/profileHome.png",
    img2: "/profile2.png",
  },
  // {
  //   name: "Help & Support",
  //   link: "/dashboard/helpSupport",
  //   img: "/profileHome.png",
  //   img2: "/profile2.png",
  // },
];

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Dashboard() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const Token = sessionStorage.getItem("token");
  React.useEffect(() => {
    // const login = localStorage.getItem("isLOGIN");
    if (!Token) {
      navigate("/login");
    }
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();

  const callLogoutAPI = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/logout`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
      });

      const data = await response.json();

      if (response.status == 200) {
        dispatch(resetProfile());
        dispatch(resetKycDetails());

        // sessionStorage.removeItem("token");
        // sessionStorage.removeItem("isLOGIN");
        // localStorage.removeItem("userDetails");
        sessionStorage.clear(); // Clears all session storage
        localStorage.clear(); // Clears all local storage
        navigate("/login");
      }
      return data;
    } catch (error) {
      throw error;
    }
  };

  const [hoveredIndex, setHoveredIndex] = React.useState(null);
  const isMobile = useMediaQuery("(max-width:700px)");

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* -----------------------------------NAVBAR--------------------------------------------  */}
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ bgcolor: "#472878", color: "white" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {!isMobile && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  edge="start"
                  sx={{
                    marginRight: 1,
                  }}
                >
                  <img
                    src="/menuicon.png"
                    alt="batuk"
                    height="30px"
                    width="45px"
                    style={{ display: "flex", marginRight: 1 }}
                  />
                </IconButton>
              )}
              {isMobile && <NavbarMobile />}
              {!open && (
                <img
                  src="/Batuk-logo.png"
                  alt="batuk"
                  width="62px"
                  style={{ display: "flex", marginRight: "1rem" }}
                />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link to="/dashboard/profile">
                <IconButton
                  //  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                >
                  <img src="/account.png" alt="Profile Logo" width="37px" />
                </IconButton>
              </Link>
              <Button variant="text" onClick={callLogoutAPI}>
                <img
                  src="/SignOut.png"
                  alt="SignOut Logo"
                  width="35px"
                  style={{ marginLeft: "0.5rem", marginRight: "1rem" }}
                />
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* ------------------------------------SIDEBAR-------------------------------------- */}
      {!isMobile && (
  <Drawer variant="permanent" open={open}>
    <DrawerHeader
      sx={{
        backgroundColor: "#472878",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src="/BatukLogo.png" alt="batuk" width="160px" />
    </DrawerHeader>
    <Divider />
    {sidebarLinks.map((item, index) => (
      <NavLink
        to={item.link}
        key={index}
        className={({ isActive }) =>
          isActive ? "active-link" : "sidebar-link"
        }
      >
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <Tooltip title={open ? "" : item.name} placement="right">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    window.location.pathname === item.link ||
                    hoveredIndex === index
                      ? item.img2 || item.img
                      : item.img
                  }
                  alt={item.name}
                  width={30}
                />
              </ListItemIcon>
              <h5
                style={{
                  alignItems: "center",
                  display: open ? "" : "none",
                  margin: "0px",
                }}
              >
                {item.name}
              </h5>
            </ListItemButton>
          </Tooltip>
        </ListItem>
      </NavLink>
    ))}
  </Drawer>
)}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
