import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const getGoldApi = createAsyncThunk("gold", async () => {
  // const response = await axiosInstance.get('/augmont/gold');
  const response = await axiosInstance.get("/merchant/OnlineLiveRates");
  return response.data;
});

const goldSlice = createSlice({
  name: "gold",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGoldApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGoldApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(getGoldApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default goldSlice.reducer;
