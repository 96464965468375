import React from "react";
import Footer from "../../components/footer/Footer";
import Breadcrumb from "../../components/Breadcrumb";

export default function ShippingPolicy() {
  return (
    <div>
      <Breadcrumb currentPageName="Shipping Policy" />
      <div
        style={{
          maxWidth: "1240px",
          margin: "auto",
          padding: "2rem 0.5rem",
          color: "4a2a7d",
        }}
      >
        Currently, we do not have any shipping policy. we will update here once
        we start.
      </div>
      {/* <Footer /> */}
    </div>
  );
}
