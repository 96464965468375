const crypto = require('crypto');


function encrypt(plainText, password) {
    const iv = crypto.randomBytes(16);
    const hash = crypto.createHash('sha512').update(password, 'utf8').digest('hex');
    const key = hash.substring(0, 16);
    const cipher = crypto.createCipheriv('aes-128-cbc', key, iv);
    let encrypted = cipher.update(plainText, 'utf8', 'base64');
    encrypted += cipher.final('base64');
    return `${encrypted}:${iv.toString('base64')}`;
}

function decrypt(encText, password) {
    const parts = encText.split(':');
    const encryptedData = Buffer.from(parts[0], 'base64');
    const iv = Buffer.from(parts[1], 'base64');
    const hash = crypto.createHash('sha512').update(password, 'utf8').digest('hex');
    const key = hash.substring(0, 16);
    const decipher = crypto.createDecipheriv('aes-128-cbc', key, iv);
    let decrypted = decipher.update(encryptedData, 'base64', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
}


module.exports = { encrypt, decrypt };