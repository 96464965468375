import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const cancelSipApi = createAsyncThunk("cancelSip", async (sipId) => {
  const response = await axiosInstance.delete(`/sip/delete/${sipId}`);
  return response.data;
});

const cancelSipSlice = createSlice({
  name: "cancelSip",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {
    sipReset: (state) => {
      state.data = [];
      state.errors = [];
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelSipApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(cancelSipApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(cancelSipApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export const { sipReset } = cancelSipSlice.actions;
export default cancelSipSlice.reducer;
