import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import Swal from "sweetalert2";

export const goldFDCloseApi = createAsyncThunk(
  "goldFDClose",
  async (transactionId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/fd/preorderClose/${transactionId}`
      );

      Swal.fire({
        title: "FD Closed SUCCESSFULLY",
        icon: "success",
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const goldFDCloseSlice = createSlice({
  name: "goldFDClose",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(goldFDCloseApi.pending, (state) => {
        state.status = "loading";
        state.errors = [];
      })
      .addCase(goldFDCloseApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(goldFDCloseApi.rejected, (state, action) => {
        state.errors.push(action.payload);
        state.status = "idle";
      });
  },
});

export default goldFDCloseSlice.reducer;
