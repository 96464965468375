import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const sessionCartApi = createAsyncThunk(
  "addProductInCart",
  async (payload) => {
    const response = await axiosInstance.post(
      "/merchant/addSessionCart",
      payload
    );
    return response.data;
  }
);

const sessionCartSlice = createSlice({
  name: "addProductInCart",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sessionCartApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sessionCartApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(sessionCartApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default sessionCartSlice.reducer;
