import React, { useEffect } from "react";
import "./GoldFD.css";
import { useDispatch, useSelector } from "react-redux";
import { goldFDSchemaApi } from "../../redux/features/goldFD/goldFDSchemaSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function GoldFD() {
  const sliceResponse = useSelector((state) => state.goldFDSchemaSlice.data);
  //   console.log("DISHANT++++++++++++++++++++", sliceResponse);
//   console.log("DISHANT++++++++++++++++++++", sliceResponse?.data?.result?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(goldFDSchemaApi());
  }, []);

  useEffect(() => {
    console.log("inside useEffect");
    if (sliceResponse.error) {
      console.log("inside if conditions");
      toast.error(sliceResponse?.message);
    }
  }, [sliceResponse.error]);
  return (
    <div>
      <h2 style={{textAlign: 'center',color:" #472878"}}>Gold FD</h2>
      <div className="goldFD-container">
        {sliceResponse?.data?.result?.data?.map((item, i) => (
          <Link to="/dashboard/goldFD/goldFD-details" state={item} key={i} className="goldFD-box1">
            <div
              style={{ display: "flex", gap: "10px", justifyContent: "center" }}
            >
              <div style={{ width: "2rem" }}>
                <img src={item.profile.logo} alt="logo" width="100%" />
              </div>
              <h3>AUSPICIOUS GOLD</h3>
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Scheme Name: </div>
                <div>{item.name}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Tenure: </div>
                <div>{item.tenure} days</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Interest Rate: </div>
                <div>{item.interestRate}%</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Minqty Lease: </div>
                <div>{item.minQtyLease}gm</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "5px" }}>
                  <div style={{ fontWeight: "bold" }}>Start Date: </div>
                  <div>{item.startDate}</div>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <div style={{ fontWeight: "bold" }}>End Date: </div>
                  <div>{item.endDate}</div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
