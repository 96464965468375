import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import Swal from "sweetalert2";

export const goldFDCreateApi = createAsyncThunk(
  "goldFDCreate",
  async (payload) => {
    const response = await axiosInstance.post("/fd/create", payload);

    Swal.fire({
      title: "FD CREATED SUCCESSFULLY",
      icon: "success",
    });
    // navigate("/dashboard/history");
    return response.data;
  }
);

const goldFDCreateSlice = createSlice({
  name: "goldFDCreate",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(goldFDCreateApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(goldFDCreateApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(goldFDCreateApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default goldFDCreateSlice.reducer;
