import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const DeleteAccount = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate('/');
    }
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setShow(true);
  }, []);

  //   /logout/deleteAccount
  const keepSave = () => {

    setShow(false)
  };

  const confirm = () => {

    setShow(false)
    /// logout/deleteAccount call this api
  };
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Modal show={show} onHide={handleClose}>
                <div
                  className="text-center p-5"
                  style={{ color: "rgb(139 1 1)" }}
                >
                  <p>Are You Sure ?</p>
                  <h6>You want to delete your Account ? </h6>
                  <p>
                    Once You delete your account use the same number to create a
                    new account.
                  </p>
                  <hr />
                  <button
                    className="btn btn-save  float-start"
                    onClick={keepSave}
                  >
                    Keep Saving
                  </button>
                  <button
                    className="btn btn-confirm float-end"
                    onClick={confirm}
                  >
                    Confirm Delete
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
