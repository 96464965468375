import React from "react";
import "./PageNotFound.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function PageNotFound() {
  return (
    <div>
      <section class="page_404">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 ">
              <div class="col-sm-10 col-sm-offset-1  text-center">
                <div class="four_zero_four_bg">
                  <h1 class="text-center ">404</h1>
                </div>

                <div class="contant_box_404">
                  <h3 class="h2">Oops! Page not found</h3>
                  <p>We can't seem to find the page you're looking for.</p>
                  <Link to="/">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#4a2a7d",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#4a2a7d",
                        },
                      }}
                    >
                      Go to Home
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
