import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./Loader.css";

const Loader = () => {
  return (
    <>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          widht: "100%",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div>
          <div class="loader"></div>
        </div>
        <div style={{ color: "#4a2a7d", fontWeight: "bold" }}>
          Please wait...
        </div>
      </div>
    </>
  );
};

export default Loader;
