import React, { useRef, useEffect } from "react";
import ApexCharts from "apexcharts";
import { useLocation } from "react-router-dom";

const ApexChartComponent = ({ filteredData, minDate }) => {
  const chartRef = useRef(null);

  const location = useLocation();
  const path = location.pathname;

  const isGold = path.includes("gold");

  useEffect(() => {
    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split("-");
      return new Date(`${year}-${month}-${day}`);
    };

    const goldData = filteredData
      .filter((item) => item.type === "gold")
      .map((item) => [
        parseDate(item.todays_date).getTime(), // Convert to timestamp
        item.buy_rate,
      ]);

    const silverData = filteredData
      .filter((item) => item.type === "silver")
      .map((item) => [
        parseDate(item.todays_date).getTime(), // Convert to timestamp
        item.buy_rate,
      ]);

    const options = {
      series: [
        {
          name: "Price",
          data: isGold ? goldData : silverData,
        },
      ],
      chart: {
        id: "area-datetime",
        type: "area",
        height: 320,
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      annotations: {
        xaxis: [
          {
            x: new Date("14 Nov 2012").getTime(),
            borderColor: "#999",
            yAxisIndex: 0,
            label: {
              show: true,
              text: "Rally",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
            },
          },
        ],
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            {
              offset: 0,
              color: "#DCDAFF",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#DCDAFF",
              opacity: 0.5,
            },
          ],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: isGold ? ["#FFBA32"] : ["#808080"],
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        colors: isGold ? ["#FFBA32"] : ["#808080"], // Set marker color to gold
        strokeColor: isGold ? "#FFBA32" : "#808080", // Optional: Set border color of marker
        strokeWidth: 2,
        // image: {
        //   src: "./goldcoin.png",
        //   width: 40, // Adjust width as needed
        //   height: 40, // Adjust height as needed
        // },
      },
      xaxis: {
        type: "datetime",
        min: minDate ? minDate.getTime() : undefined, // Set min date dynamically from data
        tickAmount: 6,
      },
      grid: {
        show: false, // Disable grid lines on the x-axis
      },
      yaxis: {
        show: false,
        grid: {
          show: false, // Disable grid lines on the y-axis
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.2, // Adjust this to control the gradient effect
          colorStops: [
            {
              offset: 0,
              color: isGold ? "gold" : "#999999",
              opacity: 0.7,
            },
            {
              offset: 100,
              color: isGold ? "gold" : "#999999",
              opacity: 0.2,
            },
          ],
        },
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [filteredData, minDate]); // Re-run effect when filteredData or minDate changes

  return (
    <div id="chart">
      <div id="chart-timeline">
        <div ref={chartRef} />
      </div>
    </div>
  );
};

export default ApexChartComponent;
