import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const goldFDSchemaApi = createAsyncThunk('goldFD', async () => {
    const response = await axiosInstance.get('/fd/fdSchemeList');
    return response.data;
});

const goldFDSchemaSlice = createSlice({
    name: "goldFD",
    initialState: {
        data: [],
        errors: [],
        status: "idle"
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(goldFDSchemaApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(goldFDSchemaApi.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = "idle";
            })
            .addCase(goldFDSchemaApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

export default goldFDSchemaSlice.reducer;
