import React from 'react'
import { useTranslation } from 'react-i18next';

const CustomCoinCard = ({ img, price, qty, name, click, }) => {
  const { t } = useTranslation();
  const { deliveryAndCharges } = t("productsPage");
  return (
    <div className='row' onClick={click}>
            <div className='col-12 text-center'>
                <img src={img} className='img-fluid' style={{ width: "120px" }} />
            </div>
            <div className='col-12' style={{color: "#472878"}}>
                {/* <p className='h6 text-center'>{qty}</p> */}
                <p className='h6 text-center'>{name}</p>
                <p className='h6 text-center'>{deliveryAndCharges}</p>
                <p className='h6 text-center'>₹{price}</p>
            </div>
        </div>
  )
}

export default CustomCoinCard