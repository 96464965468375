import { createSlice } from "@reduxjs/toolkit";

// Get cart data from session storage
const storedCart = JSON.parse(sessionStorage.getItem("cart")) || [];

const getSessionCartSlice = createSlice({
  name: "sessionCart",
  initialState: {
    data: storedCart,
    errors: [],
    status: "idle",
  },
  reducers: {
    loadCart: (state) => {
      state.status = "loading";
      try {
        const cart = JSON.parse(sessionStorage.getItem("cart")) || [];
        state.data = cart;
        state.status = "idle";
      } catch (error) {
        state.errors.push(error.message);
        state.status = "idle";
      }
    },
    // Add other reducers as needed
  },
  // No need for extraReducers unless you have async actions
});

export const { loadCart } = getSessionCartSlice.actions;

export default getSessionCartSlice.reducer;
