import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { FaSearch } from "react-icons/fa";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { getProductCartApi } from "../redux/features/cart/getProductCartSlice";
import { loadCart } from "../redux/features/cart/getSessionCartSlice";

import Autocomplete from "@mui/material/Autocomplete";
import { getSpotGoldApi } from "../redux/features/spotGold/spotGoldSlice";
import { IoIosTrendingUp } from "react-icons/io";
import { IoIosTrendingDown } from "react-icons/io";
import { getGoldApi } from "../redux/features/goldSlice";

import { FaAngleDown } from "react-icons/fa6";

import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";

import { FaCartShopping } from "react-icons/fa6";
import { IoPersonSharp } from "react-icons/io5";
import NavbarMobile from "./navbarMobile/NavbarMobile";
import ResponsiveNavbar from "./navbarMobile/ResponsiveNavbar";
import { resetProfile } from "../redux/features/profileSlice";
import { resetKycDetails } from "../redux/features/kycDetailsSlice";
import { Search } from "@mui/icons-material";
import { FaUserCircle } from "react-icons/fa";
import io from "socket.io-client";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const baseUrl =
    process.env.REACT_APP_BASE_URL || "https://app.batuk.in/admin_panel"; // Your API base URL
  const socket = io(baseUrl); // Your Socket.IO server URL

  const getSessionCartResponse = useSelector(
    (state) => state.getSessionCartSlice.data
  );

  // console.log("session cart==============", getSessionCartResponse);

  const addProductSliceResponse = useSelector(
    (state) => state.addProductCartSlice.data.data
  );

  // console.log(
  //   "add product SliceResponse=================",
  //   addProductSliceResponse
  // );

  const getCartSliceResponse = useSelector(
    (state) => state.getProductCartSlice.data.data
  );
  // console.log("DISHANT+==========++====", getCartSliceResponse?.data?.items?.length);

  const spotGoldSliceResponse = useSelector(
    (state) => state.spotGoldSlice.spotGold?.data?.data
  );

  // console.log("DISHANT SPOT GOLD API$$$$$$$$$$$$$$$",spotGoldSliceResponse)

  // const productNames = Array.isArray(spotGoldSliceResponse)
  //   ? spotGoldSliceResponse.map((item) => item.productName)
  //   : [];

  const productOptions = Array.isArray(spotGoldSliceResponse)
    ? spotGoldSliceResponse.map((item) => ({
        label: item.productName,
        id: item.id,
      }))
    : [];

  useEffect(() => {
    dispatch(getProductCartApi());
  }, [dispatch, addProductSliceResponse?.totalAmount]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Token = sessionStorage.getItem("token");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const callLogoutAPI = async (e) => {
    e.preventDefault();
    console.log("dishant++++++++++++++++++++++++++", Token);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/logout`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
      });

      const data = await response.json();

      if (response.status == 200) {
        dispatch(resetProfile());
        dispatch(resetKycDetails());

        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const userId = userDetails?._id || null;

        socket.emit("user_logged_out", userId);

        // sessionStorage.removeItem("token");
        // sessionStorage.removeItem("isLOGIN");
        // localStorage.removeItem("userDetails");
        sessionStorage.clear(); // Clears all session storage
        localStorage.clear(); // Clears all local storage
        navigate("/login");
      }
      return data;
    } catch (error) {
      // console.error("Error connecting to API", error);
      throw error; // Re-throw the error to handle it in GotoSummary
    }
  };

  // // Function to handle logout
  // const handleLogout = () => {
  //   socket.emit("user_logged_out", userId);
  //   console.log("User has been logged out.", userId);
  //   navigate("/");
  // };

  const handleYourAccount = () => {
    if (Token) {
      navigate("/account");
    } else {
      navigate("/login");
    }
  };

  const [cartCount, setCartCount] = useState("0");

  useEffect(() => {
    // console.log("NAGENDER=============", getCartSliceResponse?.items);
    const count = Token
      ? getCartSliceResponse?.items?.length || 0
      : // : JSON.parse(sessionStorage.getItem("cart"))?.length || 0;
        getSessionCartResponse?.length || 0;
    setCartCount(count);
  }, [getCartSliceResponse, Token, cartCount, getSessionCartResponse]);

  useEffect(() => {
    dispatch(loadCart());
    dispatch(getSpotGoldApi());
  }, [dispatch]);

  const goldLivePrice = useSelector((state) => state.goldSlice.data.data);

  useEffect(() => {
    dispatch(getGoldApi());
  }, []);

  const { gBuy, sBuy } = goldLivePrice?.[0] || [];

  // console.log("Dishant++++++++++++", gBuy, sBuy);

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    navigate("/shop/allProducts");
  };

  // let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  // const userName = userDetails?.name || "";

  return (
    <div className="navbar_main">
      <div style={{ backgroundColor: "#4a2a7d", width: "100%" }}>
        <nav className="navbar-container">
          <Link to="/">
            <img
              src="/BatukLogo.png"
              width="130"
              height="auto"
              // className="d-inline-block align-top"
              alt="LOGO"
            />
          </Link>
          {/* =================================NAV LINKS============================== */}
          <div className="nav_links_container">
            <Link
              to="/"
              className={`link ${location.pathname === "/" ? "active" : ""}`}
            >
              HOME
            </Link>
            <Link
              to="/about-us"
              className={`link ${
                location.pathname === "/about-us" ? "active" : ""
              }`}
            >
              ABOUT US
            </Link>
            <div className="dropdown">
              <span
                className={`link ${
                  location.pathname === "/dashboard/buyGold" ||
                  location.pathname === "/dashboard/buysilver"
                    ? "active"
                    : ""
                }`}
              >
                DIGITAL SAVING
                <span className="dropdown-icon">
                  <FaAngleDown />
                </span>
              </span>
              <div className="dropdown-content">
                <Link
                  to="/digi-gold"
                  className={`link ${
                    location.pathname === "/digi-gold" ? "active-alt" : ""
                  }`}
                >
                  DIGITAL GOLD
                </Link>
                <Link
                  to="/digi-silver"
                  className={`link ${
                    location.pathname === "/digi-silver" ? "active-alt" : ""
                  }`}
                >
                  DIGITAL SILVER
                </Link>
              </div>
            </div>
            <Link
              to="/shop"
              className={`link ${
                location.pathname.includes("/shop") ? "active" : ""
              }`}
            >
              SHOP
            </Link>
            <Link
              to="/contact-us"
              className={`link ${
                location.pathname === "/contact-us" ? "active" : ""
              }`}
            >
              CONTACT US
            </Link>
            <Link
              to="/blogs"
              className={`link ${
                location.pathname === "/blogs" ? "active" : ""
              }`}
            >
              BLOGS
            </Link>
          </div>
          {/* =================================NAV SEARCHBAR============================== */}
          {location.pathname.includes("shop") && (
            <form className="nav-searchbar" onSubmit={handleSearchSubmit}>
              <Autocomplete
                id="free-solo-demo"
                size="small"
                fullWidth
                freeSolo
                options={productOptions}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => {
                  if (value && value.id) {
                    // Ensure there's a valid option selected before navigating
                    navigate(`/shop/${value.id}`);
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.label}
                    {"   "}
                    {option.id}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    placeholder="Search for Gold Jewellery, Diamond and more..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search style={{ color: "gray" }} />{" "}
                          {/* Search icon */}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              {/* <Button
                type="submit"
                sx={{
                  backgroundColor: "#DCDAFF",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#DCDAFF",
                  },
                }}
              >
                <FaSearch size={25} color="#472878" />
              </Button> */}
            </form>
          )}
          {/* NAV BUTTONS=========================== */}
          <div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                // gap: "0.5rem",
              }}
            >
              {/* {!Token ? (
                <Link
                  to="/login"
                  className={`link ${
                    location.pathname === "/login" ? "active" : ""
                  }`}
                >
                  SIGNIN / SIGNUP
                </Link>
              ) : ( */}
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 1 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  {/* <img src="/account.png" alt="Profile Logo" width="37px" /> */}
                  {/* <div
                    style={{
                      padding: "5px",
                      backgroundColor: "#DCDAFF",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IoPersonSharp size={25} color="#4a2a7d" />
                  </div> */}
                  <FaUserCircle size={35} color="#DCDAFF" />
                </IconButton>
              </Tooltip>
              {/* )} */}

              {(location.pathname.includes("shop") ||
                location.pathname.includes("category") ||
                location.pathname.includes("cart")) && (
                <Link to="/shop/cart">
                  <Tooltip title="Cart">
                    <IconButton
                      size="small"
                      sx={{ ml: 1 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      {/* <img src="./cartLogo.png" alt="cartLogo" width={40} /> */}
                      <div
                        style={{
                          padding: "5px",
                          backgroundColor: "#DCDAFF",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Badge badgeContent={cartCount} color="secondary">
                          <FaCartShopping size={25} color="#4a2a7d" />
                        </Badge>
                      </div>
                    </IconButton>
                  </Tooltip>
                </Link>
              )}

              <ResponsiveNavbar />
            </Box>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {!Token ? (
                <MenuItem
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent", // Disable hover background
                    },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.4rem",
                    }}
                  >
                    <strong style={{ color: "#4a2a7d" }}>Welcome</strong>
                    {/* <strong>{userName}</strong> */}
                    <div style={{ color: "#4a2a7d" }}>
                      To access account and manage orders
                    </div>
                    <div>
                      <Button
                        onClick={() => navigate("/login")}
                        variant="outlined"
                        sx={{
                          color: "#4a2a7d",
                          borderColor: "#4a2a7d",
                          "&:hover": {
                            backgroundColor: "#4a2a7d",
                            color: "#ffffff", // Change text color on hover if needed
                          },
                          "&:focus": {
                            outline: "none",
                            boxShadow: `0 0 0 3px rgba(74, 42, 125, 0.5)`, // Adjust focus outline if needed
                          },
                        }}
                      >
                        SignIn / SignUp
                      </Button>
                    </div>
                  </div>
                </MenuItem>
              ) : (
                <>
                  <MenuItem onClick={handleYourAccount}>
                    <Avatar /> Your Account
                  </MenuItem>
                  {Token && (
                    <MenuItem onClick={callLogoutAPI}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  )}
                </>
              )}
            </Menu>
          </div>
        </nav>
      </div>
      {/* =================================LIVE PRICES============================== */}
      <div className="live_price_main">
        {/* ================GOLD=============== */}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
            fontWeight: "500",
            color: "#4a2a7d",
          }}
        >
          Gold Price
          <span style={{ color: "green", fontWeight: "bold" }}>
            {gBuy && gBuy}/gm
          </span>
          <div
            style={{
              color: "red",
              display: "flex",
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="notification-dot"></div>
            Live
          </div>
        </div>
        {/* ================SILVER=============== */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
            fontWeight: "500",
            color: "#4a2a7d",
          }}
        >
          Silver Price
          <span style={{ color: "green", fontWeight: "bold" }}>
            {sBuy && sBuy}/gm
          </span>
          <div
            style={{
              color: "red",
              display: "flex",
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="notification-dot"></div>
            Live
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
