import React, { useEffect, useState } from "react";
import "./form.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const NomineeDetails = () => {
  const { t } = useTranslation();
  const { nameLabel, emailLabel, dobLabel, phoneLabel,relationshipLabel,updateBtn } = t(
    "profileSettingPage.personalDetailsTab"
  );
  const navigate = useNavigate();
  const [nomineeDetails, setNomineeDetails] = useState({
    dob: "",
    email: "",
    name: "",
    phone: "",
    relation: "",
  });

  const [errors, setErrors] = useState({
    dob: false,
    email: false,
    name: false,
    phone: false,
    relation: false,
  });

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    } else {
      getNomineeDetails();
    }
  }, []);

  const Token = sessionStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${Token}`,
  };

  const getNomineeDetails = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/nominee/listNominee`, { headers })
      .then((response) => {
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          const item = response.data.data[0];
          setNomineeDetails({
            dob: item.dob,
            email: item.email,
            name: item.name,
            phone: item.phone,
            relation: item.relation,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching nominee details:", error);
      });
  };

  const validateField = (name, value) => {
    let isValid = true;
    switch (name) {
      case "dob":
        isValid = value.trim() !== "";
        break;
      case "email":
        isValid = /\S+@\S+\.\S+/.test(value);
        break;
      case "name":
        isValid = value.trim() !== "";
        break;
      case "relation":
        isValid = value.trim() !== "";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !isValid,
    }));
    return isValid;
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setNomineeDetails({ ...nomineeDetails, [name]: value });
    validateField(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = Object.keys(nomineeDetails).every((key) => {
      if (key === "phone") return true; // Phone is optional
      return validateField(key, nomineeDetails[key]);
    });

    if (isFormValid) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/nominee/add`, nomineeDetails, {
          headers,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Nominee details updated successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          console.error("Error updating nominee details:", error);
          Swal.fire({
            icon: "error",
            title: "Error updating nominee details!",
            text: error.response ? error.response.data.message : error.message,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please fill in all required fields correctly!",
      });
    }
  };

  return (
    <div>
      <section id="NomineeDetails">
        <div className="mt-2">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12">
              <div className="card p-4" style={{ border: "1px solid #d6d0d0" }}>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          {nameLabel}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          id="name"
                          name="name"
                          placeholder="Name"
                          onChange={inputHandler}
                          value={nomineeDetails.name}
                        />
                        {errors.name && (
                          <div className="invalid-feedback">
                            Name is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="dob" className="form-label">
                          {dobLabel}
                        </label>
                        <input
                          type="date"
                          className={`form-control ${
                            errors.dob ? "is-invalid" : ""
                          }`}
                          id="dob"
                          name="dob"
                          onChange={inputHandler}
                          value={nomineeDetails.dob}
                          placeholder="Date Of Birth"
                        />
                        {errors.dob && (
                          <div className="invalid-feedback">
                            Date of Birth is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="relation" className="form-label">
                        {relationshipLabel}
                        </label>
                        <select
                          className={`form-select ${
                            errors.relation ? "is-invalid" : ""
                          }`}
                          id="relation"
                          name="relation"
                          onChange={inputHandler}
                          value={nomineeDetails.relation}
                        >
                          <option value="" disabled>
                            Relationship
                          </option>
                          <option value="Brother">Brother</option>
                          <option value="Father">Father</option>
                          <option value="Mother">Mother</option>
                          <option value="Husband">Husband</option>
                          <option value="Wife">Wife</option>
                          <option value="Sister">Sister</option>
                          <option value="Son">Son</option>
                          <option value="Daughter">Daughter</option>
                          <option value="Other">Other</option>
                        </select>
                        {errors.relation && (
                          <div className="invalid-feedback">
                            Relationship is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                        {emailLabel}
                        </label>
                        <input
                          type="email"
                          className={`form-control ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          id="email"
                          name="email"
                          placeholder="Email"
                          onChange={inputHandler}
                          value={nomineeDetails.email}
                        />
                        {errors.email && (
                          <div className="invalid-feedback">
                            Invalid email address
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="phone" className="form-label">
                          {phoneLabel}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          onChange={inputHandler}
                          value={nomineeDetails.phone}
                        />
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn submit_btn px-4">
                    {updateBtn}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NomineeDetails;

// import React, { useEffect } from "react";
// import "./form.scss";
// import { useState } from "react";
// // import { billingSchema } from "../schemas/index";
// import { useFormik } from "formik";
// import Swal from "sweetalert2";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const NomineeDetails = () => {
//   // const Token = sessionStorage.getItem('token')
//   useEffect(() => {
//     const login = sessionStorage.getItem("token");
//     if (!login) {
//       navigate("/");
//     }
//   }, []);
//   const Token = sessionStorage.getItem("token");
//   const navigate = useNavigate();
//   const [data, setData] = useState();
//   const [name, setName] = useState("");
//   const [email, setemail] = useState("");
//   const [dob, setDob] = useState("");
//   const [PAN_Number, setPAN_Number] = useState("");
//   const [Address_Line_1, setAddress_Line_1] = useState("");
//   const [Address_Line_2, setAddress_Line_2] = useState("");
//   const [State, setState] = useState("");
//   const [Pin_Code, setPin_Code] = useState("");
//   const [mobile_number, setMobile_number] = useState("");
//   const [city, setCity] = useState("");
//   const [Account_Holder_Name, setAccount_Holder_Name] = useState("");
//   const [Bank_Account_Number, setBank_Account_Number] = useState("");
//   const [gender, setGender] = useState("");

//   const [nomineeDetails, setnomineeDetails] = useState({
//     dob: "",
//     email: "",
//     name: "",
//     phone: "",
//     relation: "",
//   });

//   useEffect(() => {
//     // GetProfileAPI();

//     // const headers = {
//     //   "Content-Type": "application/json",
//     //   Accept: "application/json",
//     //   Authorization:
//     //     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lclJlZk5vIjoiNjYxN2I5YjExMzUyNDNlMGU3MTNkMzAyIiwibmFtZSI6InNhbmRlZXBhbiIsImlhdCI6MTcxMzE4Nzg3M30.HKdtkWzc25LU52IeoVl3HBq2fVmeySxu3Vzc6THi_0U",

//     // };

//     // axios.get(nominee/listNominee
//     // axios
//     // .get(`${process.env.REACT_APP_BASE_URL}/getProfile`, { headers })
//     // .then((response) => {
//     //   console.log(response.data.data);
//     //   setData(response.data.data);
//     //   const responseData = response.data.data;
//     //   responseData?.map((item) => {
//     //     console.log("item", item);
//     //     console.log("item", item.fullName);
//     //     setName(item.fullName);
//     //     setMobile_number(item.mobileNumber);
//     //     setGender(item.gender);
//     //     setemail(item.emailAddress);
//     //     setCity(item.city);
//     //     setAddress_Line_1(item.billAddressUser.line1);
//     //     setAddress_Line_2(item.billAddressUser.line2);
//     //     setDob(item.dob);
//     //     setPin_Code(item.billAddressUser.zip);
//     //     setPAN_Number(item.pan_number);
//     //     setAccount_Holder_Name(item.fullName);
//     //     setBank_Account_Number(item.kyc.account_no);
//     //     setState(item.state);
//     //   });
//     // })
//     // .catch((error) => {
//     //   console.log(error);
//     // });
//     getNoimee();
//   }, []);

//   const headers = {
//     "Content-Type": "application/json",
//     Accept: "application/json",
//     Authorization: `Bearer ${Token}`,
//   };

//   const getNoimee = () => {
//     axios
//       .get(`${process.env.REACT_APP_BASE_URL}/nominee/listNominee`, { headers })
//       .then((response) => {
//         console.log(response.data.data);
//         setnomineeDetails(response.data.data);
//         const nomineeDetails = response.data.data;
//         nomineeDetails?.map((item) => {
//           console.log("item from nominee", item);
//           nomineeDetails.name = item.name;
//           nomineeDetails.email = item.email;
//           nomineeDetails.phone = item.phone;
//           nomineeDetails.dob = item.dob;
//           nomineeDetails.relation = item.relation;
//           setDob(item.dob);
//         });
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const [payload, setPayload] = useState({
//     dob: "",
//     name: "",
//     phone: "",
//     email: "",
//     relation: "",
//   });

//   const inputHandeler = (e) => {
//     const name = e.target.name;
//     const value = e.target.value;
//     setnomineeDetails({ ...nomineeDetails, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(payload);

//     const headers = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${Token}`,
//     };

//     if (nomineeDetails) {
//       axios
//         .patch(`${process.env.REACT_APP_BASE_URL}/nominee/update`, payload, {
//           headers,
//         })
//         .then((response) => {
//           // console.log(response);
//         })
//         .catch((error) => {
//           // console.log(error);
//         });
//     } else {
//     }
//   };

//   console.log("nomineeDetails", nomineeDetails);
//   return (
//     <div>
//       <section id="NomineeDetails">
//         <div className="conatiner mt-2">
//           <div className="row d-flex justify-content-center">
//             <div className="col-sm-12">
//               {/* <h4 className="text-center my-4">Add Nominee</h4> */}
//               <div className="card p-4" style={{ border: "1px solid #d6d0d0" }}>
//                 <form onSubmit={handleSubmit}>
//                   <div className="row">
//                     <div className="col-sm-6">
//                       <div className="mb-3">
//                         <label htmlFor="name" className="form-label">
//                           Name
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="name"
//                           placeholder="name"
//                           name="name"
//                           onChange={inputHandeler}
//                           value={nomineeDetails.name}
//                         />
//                       </div>
//                     </div>
//                     <div className="col-sm-6">
//                       <div className="mb-3">
//                         <label htmlFor="dob" className="form-label">
//                           Date Of Birth
//                         </label>
//                         {console.log(nomineeDetails.dob)}
//                         <input
//                           type="date"
//                           class="form-control"
//                           id="dob"
//                           name="dob"
//                           onChange={inputHandeler}
//                           // value={nomineeDetails.dob}
//                           value={nomineeDetails.dob}
//                           placeholder="Date Of Birth"
//                         />
//                       </div>
//                     </div>

//                     <div className="col-sm-6">
//                       <div className="mb-3">
//                         <label htmlFor="address1" className="form-label">
//                           Relationship
//                         </label>

//                         <select
//                           class="form-select"
//                           aria-label="Default select example"
//                           name="relation"
//                           onChange={inputHandeler}
//                           value={nomineeDetails.relation}
//                         >
//                           <option selected> Relationship </option>
//                           <option value="Brother">Brother</option>
//                           <option value="Father">Father</option>
//                           <option value="Mother">Mother</option>
//                           <option value="Husband">Husband</option>
//                           <option value="Wife">Wife</option>
//                           <option value="Sister">Sister</option>
//                           <option value="Son">Son</option>
//                           <option value="Daughter">Daughter</option>
//                           <option value="other">other</option>
//                         </select>
//                       </div>
//                     </div>

//                     <div className="col-sm-6">
//                       <div class="mb-3">
//                         <label htmlFor="address2" className="form-label">
//                           Email
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="address2"
//                           name="email"
//                           onChange={inputHandeler}
//                           value={nomineeDetails.email}
//                           placeholder="Email"
//                         />
//                       </div>
//                     </div>

//                     <div className="col-sm-6">
//                       <div class="mb-3">
//                         <label htmlFor="state" className="form-label">
//                           Phone Number
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="state"
//                           name="Phone_Number"
//                           onChange={inputHandeler}
//                           value={nomineeDetails.phone}
//                           placeholder=" Phone Number"
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   {nomineeDetails ? (
//                     <button
//                       type="submit"
//                       className="btn submit_btn px-4"
//                       // onClick={handleSubmit}
//                     >
//                       Update
//                     </button>
//                   ) : (
//                     <button
//                       type="submit"
//                       className="btn submit_btn px-4"
//                       // onClick={handleSubmit}
//                     >
//                       Save
//                     </button>
//                   )}
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default NomineeDetails;
