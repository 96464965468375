import { Autocomplete, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { billingAddressSchema, panSchema } from "../../utils/validationSchemas";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { FaSave } from "react-icons/fa";

const fields = [
  { id: "name", label: "Name" },
  { id: "addressLine1", label: "Address 1" },
  { id: "addressLine2", label: "Address 2" },
  { id: "state", label: "State" },
  { id: "city", label: "City" },
  { id: "pincode", label: "Pincode" },
  { id: "mobileNum", label: "Mobile Number" },
];

export default function BillingAddressKyc({ handleNext, GetAddressKycAPI }) {
  const dispatch = useDispatch();
  const Token = sessionStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // const [editData, setEditData] = useState(data);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [state_code, setstate_code] = useState(0);
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");

  const [addressApiData, setAddressApiData] = useState("");
  const [loading, setLoading] = useState(false);

  //   ======================FORMIK=======================

  const { values, handleSubmit, handleChange, errors, setValues } = useFormik({
    initialValues: {
      name: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      pincode: "",
      mobileNum: "",
    },
    validationSchema: billingAddressSchema,
    onSubmit: async () => {
      console.log("values______________________", values);
      try {
        setLoading(true);
        const url = `${process.env.REACT_APP_BASE_URL}/address/addaddress`;
        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Token}`,
        };

        const body = {
          city: values.city,
          country: "India",
          line1: values.addressLine1,
          line2: values.addressLine2,
          mobileNumber: values.mobileNum,
          name: values.name,
          state: values.state,
          statecode: String(state_code),
          type: "B",
          zip: values.pincode,
        };

        console.log("PAYLOAD++++++++++++++", body);
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        });

        const data = await response.json();
        console.log("ADD ADDRESS API************", data);

        if (response.ok) {
          await GetAddressAPI();
          await GetAddressKycAPI();
          await handleNext("panel3");
          toast.success("Address updated successfully");
        } else {
          toast.error(`Error: ${data.message || "Failed to update address"}`);
        }
      } catch (error) {
        setLoading(false);
        console.error("ERROR IN CATCH+++++++++++++++++", error);
        toast.error("An error occurred while updating the address");
      } finally {
        setLoading(false);
      }
    },
    validateOnMount: false, // No validation on mount
    validateOnChange: false, // No validation on change
    validateOnBlur: true, // Validate only on blur
  });

  const GetAddressAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/address/get_address`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();
      console.log("DISHANT GET ADDRESS++++++++++++++++++++", data.data);

      const { name, line1, line2, state, city, zip, mobileNumber } =
        data?.data[data.data.length - 1] || [];

      setAddressApiData(state);

      // ==================================================
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));

      // Access the name and update state
      // if (userDetails && userDetails.name) {
      //   const userName = userDetails.name;
      //   console.log("User Name from localStorage:", userName);
      // }

      setValues({
        name: userDetails?.name || name || "",
        addressLine1: line1 || "",
        addressLine2: line2 || "",
        state: state || "",
        city: city || "",
        pincode: zip || "",
        mobileNum: userDetails?.phone || mobileNumber || "",
      });
    } catch (error) {
      console.error("ERROR IN CATCH+++++++++++++++++", error);
    }
  };

  useEffect(() => {
    GetAddressAPI();
    getStateApi();
  }, []);

  // ***************************************************************************

  const getStateApi = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stateCode/getstateCode`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(url, { headers });
      console.log("STATE API===============", response.data.data);
      setStateData(response.data.data);
      setstate_code(response.data);
      return response.data;
    } catch (error) {
      // console.error("Error connecting to state API", error);
      throw error; // Throw error to handle it later
    }
  };

  // ---------------------------------------City Api-----------------------
  const getCityApi = async (stateId) => {
    try {
      // const url = `${process.env.REACT_APP_BASE_URL}/stateCode/getcity`;
      const url = `${process.env.REACT_APP_BASE_URL}/stateCode/getCitiesOfState`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const body = {
        _id: stateId,
      };

      const response = await axios.post(url, body, { headers });
      // console.log("City API Response:", response.data.data);
      setCityData(response.data.data);
    } catch (error) {
      console.error("Error connecting to city API", error);
      throw error;
    }
  };

  // ================================HANDLE CHANGE==============================

  const handleStateChange = async (event, newValue) => {
    if (newValue) {
      setSelectedState(newValue._id); // Set the selected state ID
      setSelectedStateCode(newValue.state_code); // Set the state code
      setSelectedStateName(newValue.name); // Set the state name
      setValues({ ...values, state: newValue.name }); // Update Formik values

      // Call the city API with the selected state ID
      await getCityApi(newValue._id); // Make sure getCityApi accepts a state ID if needed
    } else {
      setSelectedState("");
    }
  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setSelectedCity(selectedCityId);

    // Find the selected city object from cityData
    const selectedCityObject = cityData.find(
      (city) => city._id === selectedCityId
    );

    if (selectedCityObject) {
      setSelectedCityName(selectedCityObject.city_name);

      // Set the city name in Formik's values
      setValues((prevValues) => ({
        ...prevValues,
        city: selectedCityObject.city_name,
      }));
    }
  };

  useEffect(() => {
    if (stateData.length > 0) {
      setSelectedState(stateData[0]._id); // Assuming stateData contains an array of objects with _id field
    }
  }, [stateData]);

  useEffect(() => {
    if (values.state !== "") {
      const filteredCities = cityData.filter(
        (city) => city.state_id === values.state
      );
      if (filteredCities.length > 0) {
        setSelectedCity(filteredCities[0]._id); // Assuming cityData contains an array of objects with _id field
      }
    }
  }, [cityData, selectedState, values.state]);

  // useEffect(() => {
  //   let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  //   // Access the name and update state
  //   if (userDetails && userDetails.name) {
  //     const userName = userDetails.name;
  //     console.log("User Name from localStorage:", userName);

  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       name: userName || "",
  //     }));
  //   }
  // }, []);

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
      >
        {/* Name Field */}
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          size="small"
          fullWidth
          value={values.name || ""}
          onChange={handleChange}
          error={Boolean(errors.name)}
          helperText={errors.name || ""}
          InputProps={{
            readOnly: addressApiData ? true : false, // Read-only if addressApiData is true
          }}
          sx={{ marginBottom: 2 }}
        />

        {/* Address 1 Field */}
        <TextField
          id="addressLine1"
          label="Address 1"
          variant="outlined"
          size="small"
          fullWidth
          value={values.addressLine1 || ""}
          onChange={handleChange}
          error={Boolean(errors.addressLine1)}
          helperText={errors.addressLine1 || ""}
          InputProps={{
            readOnly: addressApiData ? true : false, // Read-only if addressApiData is true
          }}
          sx={{ marginBottom: 2 }}
        />

        {/* Address 2 Field */}
        <TextField
          id="addressLine2"
          label="Address 2"
          variant="outlined"
          size="small"
          fullWidth
          value={values.addressLine2 || ""}
          onChange={handleChange}
          error={Boolean(errors.addressLine2)}
          helperText={errors.addressLine2 || ""}
          InputProps={{
            readOnly: addressApiData ? true : false, // Read-only if addressApiData is true
          }}
          sx={{ marginBottom: 2 }}
        />

        {/* State Autocomplete */}
        {addressApiData ? (
          <TextField
            id="state1"
            label="State"
            variant="outlined"
            size="small"
            fullWidth
            value={values.state || ""}
            sx={{ marginBottom: 2 }}
          />
        ) : (
          <Autocomplete
            disablePortal
            options={stateData || []}
            getOptionLabel={(option) => option.name || ""}
            fullWidth
            size="small"
            value={
              stateData.find((option) => option.name === values.state) || null
            }
            onChange={handleStateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                error={Boolean(errors.state)}
                helperText={errors.state || ""}
              />
            )}
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            sx={{ marginBottom: 2 }}
          />
        )}
        {/* City Autocomplete */}
        {addressApiData ? (
          <TextField
            id="city1"
            label="City"
            variant="outlined"
            size="small"
            fullWidth
            value={values.city || ""}
            sx={{ marginBottom: 2 }}
          />
        ) : (
          <Autocomplete
            disablePortal
            options={cityData || []}
            getOptionLabel={(option) => option || ""} // Adjust here if the city data is just an array of strings
            fullWidth
            size="small"
            value={cityData.find((option) => option === values.city) || ""}
            onChange={(event, newValue) => {
              setSelectedCity(newValue); // Just set the selected city name directly
              setValues({ ...values, city: newValue }); // Update Formik values
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                error={Boolean(errors.city)}
                helperText={errors.city || ""}
              />
            )}
            isOptionEqualToValue={(option, value) => option === value} // Adjust for strings
            sx={{ marginBottom: 2 }}
          />
        )}

        {/* Pincode Field */}
        <TextField
          id="pincode"
          label="Pincode"
          variant="outlined"
          size="small"
          fullWidth
          value={values.pincode || ""}
          onChange={(e) => {
            // Allow only numbers and restrict to a maximum of 6 digits
            const newValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
            if (newValue.length <= 6) {
              setValues({ ...values, pincode: newValue });
            }
          }}
          inputProps={{ maxLength: 6 }} // Restrict to 6 digits
          error={Boolean(errors.pincode)}
          helperText={errors.pincode || ""}
          InputProps={{
            readOnly: addressApiData ? true : false, // Read-only if addressApiData is true
          }}
          sx={{ marginBottom: 2 }}
        />

        {/* Mobile Number Field */}
        <TextField
          id="mobileNum"
          label="Mobile Number"
          variant="outlined"
          size="small"
          fullWidth
          value={values.mobileNum || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= 10 && /^[0-9]*$/.test(value)) {
              handleChange(e); // Proceed only if the value is 10 digits or less and contains only numbers
            }
          }}
          error={Boolean(errors.mobileNum)}
          helperText={errors.mobileNum || ""}
          InputProps={{
            readOnly: addressApiData ? true : false, // Read-only if addressApiData is true
          }}
          inputProps={{ maxLength: 10 }} // Set the max length to 10
          sx={{ marginBottom: 2 }}
        />

        {/* Submit Button */}
        {!addressApiData && (
          <div style={{ display: "flex", justifyContent: "end", gap: "1rem" }}>
            <LoadingButton
              type="submit"
              size="small"
              // onClick={handleClick}
              loading={loading}
              loadingPosition="start"
              startIcon={<FaSave color="white" />}
              variant="contained"
              sx={{
                backgroundColor: "#4a2a7d",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4a2a7d",
                },
              }}
            >
              Save
            </LoadingButton>
            {/* <Button
              variant="contained"
              onClick={() => handleNext("panel3")}
              sx={{
                backgroundColor: "#4a2a7d",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4a2a7d",
                },
              }}
            >
              Next
            </Button> */}
          </div>
        )}
      </form>
    </div>
  );
}
