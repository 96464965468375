import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { bankSchema, panSchema } from "../../utils/validationSchemas";
import axios from "axios";
import transactionId from "../../utils/transactionId";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getkycDetailsApi } from "../../redux/features/kycDetailsSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import { FaSave } from "react-icons/fa";

const fields = [
  { id: "name", label: "Account Holder's Name" },
  { id: "accountNum", label: "Bank Account Number" },
  { id: "ifsc", label: "Bank IFSC Code" },
];

export default function Bank({ kycDetailApiResponse }) {
  console.log(
    "kycDetailApiResponse======================",
    kycDetailApiResponse
  );
  const dispatch = useDispatch();
  const Token = sessionStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  //   ======================FORMIK=======================

  const { values, handleSubmit, handleChange, errors, setValues } = useFormik({
    initialValues: {
      name: "",
      accountNum: "",
      ifsc: "",
    },
    validationSchema: bankSchema,
    onSubmit: async () => {
      await bankKycVerify();
      await encryptApi();
      await dispatch(getkycDetailsApi());
    },
    validateOnMount: false, // No validation on mount
    validateOnChange: false, // No validation on change
    validateOnBlur: true, // Validate only on blur
  });

  const [bankDetail, setBankDetail] = useState("");

  const bankKycVerify = async () => {
    try {
      if (!values.ifsc) {
        throw new Error("IFSC code is required");
      }

      const response = await axios.get(
        `https://ifsc.razorpay.com/${values.ifsc}`
      );

      console.log("VERIFY API RESPONSE+++++++++++", response.data);

      if (response.status === 200) {
        setBankDetail(response.data);
        toast.success("Bank verified");
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching bank details:", error);
      throw error;
    }
  };

  console.log("OUTSIDE BANK DETAILS******************", bankDetail);
  
  const [loading, setLoading] = useState(false);

  const encryptApi = async () => {
    console.log("INSIDE BANK DETAILS******************", bankDetail);
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/encrypt/encrypted`;
    const encryptdata = JSON.stringify({
      transID: transactionId.transactionId,
      docType: "92",
      beneAccNo: values.accountNum,
      ifsc: values.ifsc,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const requestBody = {
        plainText: encryptdata,
      };
      const response = await axios.post(url, requestBody, { headers });

      const encr = response.data.data[0];
      await bankverify(encr);
    } catch (error) {
      setLoading(false);
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const bankverify = async (encryptdata) => {
    let data = {
      requestData: encryptdata,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://www.truthscreen.com/v1/apicall/bank/bankAccountVerification",
      headers: {
        username: "production@batuk.in",
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      const respo = response.data.responseData;

      // console.log("DISHANT BANK VERIFY =======================",respo)
      await decryptApi(respo);
    } catch (error) {
      console.log("ERROR IN CATCH+++++++++++++++", error);
    }
  };

  const decryptApi = async (encrData) => {
    const url = `${process.env.REACT_APP_BASE_URL}/decrypt/decrypted`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const requestBody = {
        encText: encrData,
      };
      const response = await axios.post(url, requestBody, { headers });

      // console.log("DISHANT BANK response===============", response);
      const parsedData = JSON.parse(response.data.data[0]);
      console.log("DISHANT DECRYPT response============", parsedData);

      if (parsedData.status == 0) {
        toast.error("Invalid Account number or IFSC code");
        return;
      } else if (parsedData.status == 1) {
        await BankYCAPI(parsedData);
      }

      // setdescription = parsedData.msg.description;
      // console.log("bank description", setdescription);
      // // console.log("dessss", description);
      // setname = parsedData.msg.name;
      // console.log("bank name", setname);
      // setTransid = parsedData.msg.transID;
      // console.log("bank transection id", setTransid);
      // setStatus = parsedData.msg.status;
      // console.log("set bank status", setStatus);
      // setTsTransid = parsedData.msg.tsTransID;
      // console.log("set ts transection", setTsTransid);
      // setStatus1 = parsedData.status;
      // console.log("set status of bank", setStatus1);

      // if (response.status === 200) {
      //   await BankYCAPI();
      // }
    } catch (error) {
      console.log("ERROR IN CATCH+++++++++++++++", error);
    }
  };

  const BankYCAPI = async (parsedData) => {
    const url = `${process.env.REACT_APP_BASE_URL}/kyc/bank`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    console.log("bankDetail===============", bankDetail);

    try {
      const requestBody = {
        msg: {
          ...parsedData.msg,
          account_no: values.accountNum,
          Bankname: bankDetail.BANK,
          Branchname: bankDetail.BRANCH,
          ifsc: values.ifsc,
        },
        status: parsedData.status,
      };

      console.log("requestBody from bank", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      toast.success("Bank Details saved");
      //   navigate("/dashboard/sellGold");

      console.log("kyc pan API Response:", response.data.data);
    } catch (error) {
      console.log("ERROR IN CATCH+++++++++++++++", error);
    }
  };

  // const kycDetailApiResponse = useSelector(
  //   (state) => state.kycDetailsSlice.data.data
  // );

  // console.log("KYC API RESPONSE=================", kycDetailApiResponse);

  const { name, account_no, ifsc } = kycDetailApiResponse?.[0]?.bank?.msg || {};

  console.log("ACCOUNT NUMBER===============", account_no);

  useEffect(() => {
    if (account_no) {
      setValues({
        accountNum: account_no || "",
        name: name || "",
        ifsc: ifsc || "",
      });
    }
  }, [kycDetailApiResponse, account_no]);

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
      >
        {fields.map((field) => (
          <TextField
            key={field.id}
            id={field.id}
            label={field.label}
            variant="outlined"
            size="small"
            onChange={handleChange}
            value={values[field.id] || ""}
            error={Boolean(errors[field.id])}
            helperText={errors[field.id] || ""}
            InputProps={{
              readOnly: !!account_no, // Conditionally set the read-only property
            }}
            sx={{ marginBottom: 2 }}
          />
        ))}
        {!account_no && (
          <div style={{ display: "flex", justifyContent: "end", gap: "1rem" }}>
            <LoadingButton
              type="submit"
              size="small"
              // onClick={handleClick}
              loading={loading}
              loadingPosition="start"
              startIcon={<FaSave color="white" />}
              variant="contained"
              sx={{
                backgroundColor: "#4a2a7d",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4a2a7d",
                },
              }}
            >
              Save
            </LoadingButton>
          </div>
        )}
      </form>
    </div>
  );
}
