import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSpotGoldProductDetailsApi } from "../../redux/features/spotGold/spotGoldProductDetailsSlice";
import { Carousel } from "react-bootstrap";

const SpotGoldProductDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    deliveryAndCharges,
    buyBtn,
    productDetails,
    weight,
    metalPurity,
    skuId,
    desc,
  } = t("productDetailsPage");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sliceResponse = useSelector(
    (state) => state.spotGoldProductDetailsSlice.spotGoldProductDetails.data
  );
  // console.log("SLICE RESPOSNE++++++++++++++++++++++++++++", sliceResponse);

  const Token = sessionStorage.getItem("token");
  // useEffect(() => {
  //   const login = sessionStorage.getItem("token");
  //   if (!login) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    dispatch(getSpotGoldProductDetailsApi(id));
  }, [id]);

  const [coinData, setCoinData] = useState(null);

  const location = useLocation();
  // console.log("LOCATION^^^^^^^^^^^^^^^^^^^", location);
  const {
    productImages,
    purity,
    productWeight,
    sku,
    description,
    basePrice,
    metalType,
  } = location.state;
  //   const { productImages, purity, productWeight, sku, description, basePrice } = data;
  const [mainImage, setMainImage] = useState(null);

  const [checkaddress, setcheckaddress] = useState([]);

  // useEffect to call API once when component mounts
  useEffect(() => {
    callAPI(); // Call the API function
    GetAddressAPI();
  }, []);

  const callAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/products/show-product/${sku}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setCoinData(data?.data[0]?.result?.data);
      setMainImage(data?.data[0]?.result?.data?.productImages[0]?.url);

      // setSumdata(data?.data?.[0]?.result?.data?.filter(item => item.metalType === 'gold') || []);
    } catch (error) {
      // console.error("Error connecting to API", error);
    }
  };

  const GetAddressAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/address/get_address`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();
      console.log("DISHANT++++++++++++++++++++", data.data);
      // setcheckaddress(
      //   data?.data?.filter((item) => {
      //     return item.type === "D";
      //   })
      // );

      setcheckaddress(
        data.data.map((item, i) => {
          return item;
        })
      );
    } catch (error) {
      console.error("ERROR IN CATCH+++++++++++++++++", error);
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <>
      <div>
        {/* <BackButton link="/dashboard/buygold/products" /> */}

        <div>
          <div
            className="card p-4 "
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            <div className="row">
              <div
                className="col-md-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="row text-center">
                  <div
                    className="col-md-12"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <div className="row">
                      <div className="c-l-md-12">
                        <img
                          className="img-fluid"
                          style={{ width: 200 }}
                          src={sliceResponse?.productImage}
                          alt="img"
                        />
                      </div>
                    </div> */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Carousel
                        controls={false}
                        indicators={false}
                        interval={3000}
                        pause={false}
                        activeIndex={currentIndex}
                        onSelect={setCurrentIndex}
                        style={{
                          width: "50%",
                          backgroundColor: "#DCDAFF",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        <Carousel.Item>
                          <img
                            className="d-block w-100 img-height"
                            src={location.state.productImage}
                            alt="productImg"
                          />
                        </Carousel.Item>
                        {location.state.productImages.map((image, i) => (
                          <Carousel.Item>
                            <img
                              className="d-block w-100 img-height"
                              src={image.URL}
                              alt="productImg"
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>

                      <div className="d-flex justify-content-evenly align-items-center gap-2">
                        {location?.state?.productImages?.map((image, index) => (
                          <img
                            key={index}
                            width={60}
                            height={60}
                            style={{
                              border: "2px solid gold",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            src={image.URL}
                            alt={sliceResponse?.title}
                            onClick={() => handleThumbnailClick(index)}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-12">
                        <div>
                          <h5 className="mt-4" style={{ color: "#4A2A7D" }}>
                            {sliceResponse?.productName}
                          </h5>
                          <h5 style={{ color: "#4A2A7D" }}>
                            &#8377;
                            {sliceResponse?.productPrice[0]?.finalProductPrice}
                          </h5>
                          <p style={{ color: "#4A2A7D" }}>
                            (Inclusive of all taxes)
                          </p>
                        </div>
                        <button
                          onClick={() =>
                            navigate(`/dashboard/spotGold/${id}/address`, {
                              state: sliceResponse,
                            })
                          }
                          style={{
                            backgroundColor: "#4A2A7D",
                            color: "white",
                            padding: "10px 10px",
                            borderRadius: "10px",
                            border: "none",
                          }}
                        >
                          BUY NOW
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div
                  className="contentshadow"
                  style={{ border: "2px solid gold", borderRadius: "10px" }}
                >
                  <h4
                    className="colore"
                    style={{ color: "#4A2A7D", textAlign: "center" }}
                  >
                    {productDetails}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <div>
                      <div
                        style={{
                          color: "#4A2A7D",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4>{weight}</h4>
                        <h4 style={{ color: "#FFB901" }}>
                          {sliceResponse?.weight}gm
                        </h4>
                      </div>
                      <div
                        style={{
                          color: "#4A2A7D",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4>{metalPurity}</h4>
                        <h4 style={{ color: "#FFB901" }}>
                          {" "}
                          {
                            sliceResponse?.subCategory?.category?.metalType
                              ?.metalFitness
                          }
                        </h4>
                      </div>
                      <div
                        style={{
                          color: "#4A2A7D",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4>SKU</h4>
                        <h4 style={{ color: "#FFB901" }}>{sku}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="contentshadow"
                  style={{ border: "2px solid gold", borderRadius: "10px" }}
                >
                  <h4
                    className="colore"
                    style={{ color: "#4A2A7D", textAlign: "center" }}
                  >
                    {desc}
                  </h4>
                  <p
                    className="coloreddescriptio"
                    style={{ color: "#4A2A7D", textAlign: "center" }}
                  >
                    {sliceResponse?.metaDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpotGoldProductDetails;
