import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { PiVault } from "react-icons/pi";
import { TbTruckDelivery } from "react-icons/tb";

import Footer from "../../components/footer/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getGoldApi } from "../../redux/features/goldSlice";
import { Avatar, Button, InputAdornment, OutlinedInput } from "@mui/material";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import CustomBarChart from "../../components/BarChart";
import LineChart from "../../components/LineChart";
import { oneTimeSipApi } from "../../redux/features/sip/oneTimeSipSlice";
import { PiCoins } from "react-icons/pi";
import { goldAndSliverGraphApi } from "../../redux/features/goldAndSilverGraph/goldAndSilverGraphSlice";
import Loader from "../../components/Loader";
import { LuPackageCheck } from "react-icons/lu";

import "./CustomGoldAndSilver.css";
import CustomerReviews from "../../components/CustomerReviews";

const trendingData = [
  {
    img: "",
    title: "Gold continues to consolida...",
    content:
      "Gold continues to remain around its recent record highs of around $2500, but its upward momentum is under threat as the US dollar seems oversold.",
    date: "3 Sep 2024",
  },
  {
    img: "",
    title: "Gold continues to hold gains...",
    content:
      "The U.S. economy continues to defy expectations, with GDP growing strongly in the second quarter. Despite this favourable economic news, gold prices",
    date: "30 Aug 2024",
  },
  {
    img: "",
    title: "Strong physical demand...",
    content:
      "The US Dollar Index's modest resurgence has led to a short-term fall in gold prices, despite an overall rising trend. Increased buying activity in North",
    date: "29 Aug 2024",
  },
];

export default function CustomGoldAndSilver() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  // Check if the URL contains "gold" or "silver"
  const isGold = path.includes("gold");
  const isSilver = path.includes("silver");

  const token = sessionStorage.getItem("token");
  const [activeBtn, setActiveBtn] = useState("one-time");

  // ==========================================================================
  const [sipUrl, setSipUrl] = useState("sipOneTime");

  const [isRupeeSelected, setIsRupeeSelected] = useState(true);
  const [rupeeValue, setRupeeValue] = useState("");
  const [gramValue, setGramValue] = useState("");

  const { t } = useTranslation();

  const {
    inRupee,
    inGram,
    proceedToPay,
    inputAmountPlaceholder,
    inputWeightPlaceholder,
  } = t("buySellPage");

  const handleUnitButtonClick = (isRupee) => {
    setIsRupeeSelected(isRupee);
  };

  const goldLivePrice = useSelector((state) => state.goldSlice.data.data);

  // console.log("GOLD LIVE PRICE=======", goldLivePrice);
  useEffect(() => {
    dispatch(getGoldApi());
  }, []);

  const { gBuy, sBuy } = goldLivePrice?.[0] || [];

  // console.log("Dishant++++++++++++", gBuy, sBuy);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (isRupeeSelected) {
      setRupeeValue(value);
      setGramValue(value ? (value / (isGold ? gBuy : sBuy)).toFixed(4) : "");
    } else {
      setGramValue(value);
      setRupeeValue(value ? (value * (isGold ? gBuy : sBuy)).toFixed(2) : "");
    }
  };

  const handleAmountButtonClick = (amount) => {
    setActiveAmount(amount);
    const value = amount.replace("₹", "");
    if (isRupeeSelected) {
      setRupeeValue(value);
      setGramValue(value ? (value / (isGold ? gBuy : sBuy)).toFixed(4) : "");
    } else {
      setGramValue(value);
      setRupeeValue(value ? (value * (isGold ? gBuy : sBuy)).toFixed(2) : "");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!token) {
      navigate("/login");
    } else {
      if (isGold) {
        navigate("/dashboard/buygold", {
          state: isRupeeSelected ? rupeeValue : gramValue,
        });
      } else {
        navigate("/dashboard/buysilver", {
          state: isRupeeSelected ? rupeeValue : gramValue,
        });
      }
    }
  };

  // ==============================================

  const [value, setValue] = React.useState(10);
  const [timePeriod, setTimePeriod] = React.useState(10);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSipInputChange = (event) => {
    const inputValue = event.target.value;

    // Allow any input, including empty and numbers below 10
    if (!isNaN(inputValue) || inputValue === "") {
      setValue(inputValue);
    }
  };

  const handleInputBlur = () => {
    // Enforce min and max when the input loses focus
    if (value < 10) {
      setValue(10); // Set to the minimum value
    } else if (value > 20000) {
      setValue(20000); // Set to the maximum value
    }
  };

  const handleTimePeriodSliderChange = (event, newValue) => {
    setTimePeriod(newValue);
  };

  const handleTimePeriodInputChange = (event) => {
    const inputValue = event.target.value;

    // Allow any input while typing
    if (!isNaN(inputValue) || inputValue === "") {
      setTimePeriod(inputValue === "" ? "" : Number(inputValue));
    }
  };

  const handleBlur = () => {
    // Enforce min/max only when input loses focus
    if (timePeriod < 1) {
      setTimePeriod(1); // Minimum is 1
    } else if (timePeriod > 40) {
      setTimePeriod(40); // Maximum is 40
    }
  };

  // ====================================================================

  const sipResponse = useSelector((state) => state.oneTimeSipSlice.data.data);
  const graphResponse = useSelector(
    (state) => state.goldAndSilverGraphSlice.data.data
  );

  // console.log("GRAPH==========", graphResponse);

  const [filteredData, setFilteredData] = useState([]);
  const [graphDate, setGraphDate] = useState("");

  // Function to convert date strings to Date objects
  const convertToDate = (dateStr) => {
    if (!dateStr || typeof dateStr !== "string") {
      return "";
    }

    const [day, month, year] = dateStr.split("-");
    if (!day || !month || !year) {
      return "";
    }

    return new Date(`${year}-${month}-${day}`);
  };

  // Function to filter data based on years
  const filterByYears = (years) => {
    const currentDate = new Date();
    const cutoffDate = new Date(
      currentDate.setFullYear(currentDate.getFullYear() - years)
    );

    return graphResponse?.filter(
      (item) => convertToDate(item.todays_date) >= cutoffDate
    );
  };

  const handleLastOneYear = () => {
    const data = filterByYears(1);
    setFilteredData(data);
    setGraphDate(getMinDateFromData(data)); // Calculate min date from data
  };

  const handleLastTwoYears = () => {
    const data = filterByYears(2);
    setFilteredData(data);
    setGraphDate(getMinDateFromData(data)); // Calculate min date from data
  };

  const handleLastThreeYears = () => {
    const data = filterByYears(3);
    setFilteredData(data);
    setGraphDate(getMinDateFromData(data)); // Calculate min date from data
  };

  const handleLastFiveYears = () => {
    const data = filterByYears(5);
    setFilteredData(data);
    setGraphDate(getMinDateFromData(data)); // Calculate min date from data
  };

  // Function to get the minimum date from the data
  const getMinDateFromData = (data) => {
    if (!data || data.length === 0) return null;

    const minDate = data.reduce((earliest, current) => {
      const currentDate = convertToDate(current.todays_date);
      return currentDate < earliest ? currentDate : earliest;
    }, convertToDate(data[0].todays_date));

    return minDate;
  };

  useEffect(() => {
    setFilteredData(filterByYears(1));
  }, [graphResponse]);

  // console.log("FILTER DATA====================", filteredData);

  const [activeButton, setActiveButton] = useState("1");

  const handleButtonClick = (years) => {
    setActiveButton(years);
    switch (years) {
      case "1":
        handleLastOneYear();
        break;
      case "2":
        handleLastTwoYears();
        break;
      case "3":
        handleLastThreeYears();
        break;
      case "5":
        handleLastFiveYears();
        break;
      default:
        break;
    }
  };

  // ==========================================SIP=================================================

  const { Grams, totalAmount } = sipResponse || {};

  const formattedAmount = totalAmount ? `₹${totalAmount?.toFixed(2)}` : "";

  const sipPayload = {
    sipUrl,
    payload: {
      amount: value || "",
      period: timePeriod || "",
      type: isGold ? "gold" : "silver",
    },
  };

  useEffect(() => {
    dispatch(oneTimeSipApi(sipPayload));
  }, []);

  useEffect(() => {
    const paylaod = {
      fromDate: "2023-05-01",
      toDate: "2023-05-12",
      metalType: "gold",
    };
    dispatch(goldAndSliverGraphApi(paylaod));
  }, [path]);

  const handleCalculate = () => {
    dispatch(oneTimeSipApi(sipPayload));
  };

  const smartChoiceData = [
    {
      title: isGold ? "Digital Gold" : "Digital Silver",
      point1: `Buy and sell ${
        isGold ? "gold" : "silver"
      } digitally starting from ₹100.`,
      point2: "Secure, insured storage accessible via our intuitive app.",
    },
    {
      title: isGold ? "Gold SIP" : "Silver SIP",
      point1: `Make regular contributions to build your ${
        isGold ? "gold" : "silver"
      } savings.`,
      point2:
        "Benefit from cost averaging and align with long-term financial goals.",
    },
    {
      title: "Gold FD",
      point1: "Earn guaranteed returns with interest rates upto 2.5 p.a.",
      point2: "Flexible tenures and insured deposits for added security.",
    },
    {
      title: "Jewellery",
      point1:
        "High-quality certified gold coins, bars, and stunning jewellery.",
      point2: "Delivered securely and insured, cheaper than market prices.",
    },
  ];

  const easyStepsData = [
    {
      img: <TbTruckDelivery size={70} color="#4D16A4" />,
      title: `Buy ${isGold ? "Gold" : "Silver"}`,
      content: `Easily purchase digital ${
        isGold ? "gold" : "silver"
      } at competitive rates with just one click.`,
    },
    {
      img: <PiVault size={70} color="#4D16A4" />,
      title: `Store ${isGold ? "Gold" : "Silver"}`,
      content: `Keep your digital ${
        isGold ? "gold" : "silver"
      } safe in our secure vaults.`,
    },
    {
      img: <LuPackageCheck size={70} color="#4D16A4" />,
      title: `Get ${isGold ? "Gold" : "Silver"}`,
      content: `Receive your ${
        isGold ? "gold" : "silver"
      } in physical form or convert it to cash.`,
    },
  ];

  const [activeAmount, setActiveAmount] = useState(null); // State to track the active amount

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "70px" }}>
      <div>
        <div className="universal_container">
          <Breadcrumb
            currentPageName={isGold ? "Digital Gold" : "Digital Silver"}
          />
          {/* <h2>{isGold ? "Digital Gold" : isSilver ? "Digital Silver" : ""}</h2> */}
        </div>
        {/* =================================================================== */}
        <div
          style={{
            display: "flex",
            gap: "20px",
          }}
          className="universal_container calculator_main"
        >
          <div
            style={{
              border: "2px solid #4a2a7d",
              borderRadius: "20px",
              width: "100%",
              padding: "1rem",
            }}
          >
            {filteredData ? (
              <LineChart filteredData={filteredData} minDate={graphDate} />
            ) : (
              <Loader />
            )}

            <div
              style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
            >
              <button
                onClick={() => handleButtonClick("1")}
                className={`buttonBase ${
                  activeButton === "1" ? "buttonActive" : ""
                }`}
              >
                1 Yr
              </button>
              <button
                onClick={() => handleButtonClick("2")}
                className={`buttonBase ${
                  activeButton === "2" ? "buttonActive" : ""
                }`}
              >
                2 Yrs
              </button>
              <button
                onClick={() => handleButtonClick("3")}
                className={`buttonBase ${
                  activeButton === "3" ? "buttonActive" : ""
                }`}
              >
                3 Yrs
              </button>
              <button
                onClick={() => handleButtonClick("5")}
                className={`buttonBase ${
                  activeButton === "5" ? "buttonActive" : ""
                }`}
              >
                5 Yrs
              </button>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <h5 style={{ color: "#4a2a7d" }}>Let’s grow together</h5>
              <h2 style={{ color: "#4a2a7d" }}>
                Start Your {isGold ? "Gold" : isSilver ? "Silver" : ""} Saving
                Today
              </h2>
            </div>
            <div className="button-row-bottom">
              <div className="irg">
                <button
                  className={`btn-ir ir-btn ${isRupeeSelected ? "active" : ""}`}
                  onClick={() => handleUnitButtonClick(true)}
                >
                  {inRupee}
                </button>
                <button
                  className={`btn-ir ig-btn ${
                    !isRupeeSelected ? "active" : ""
                  }`}
                  onClick={() => handleUnitButtonClick(false)}
                >
                  {inGram}
                </button>
              </div>
            </div>

            <form onSubmit={handleFormSubmit}>
              <div className="input-container">
                <div
                  className={isGold ? "input-border" : "input_border_silver"}
                >
                  <div className="input-1">
                    <span
                      className="rupee"
                      style={{
                        // color: metalType == "SILVER" ? "#A6A6A6" : "#FFDF00",
                        margin: "0px 10px",
                      }}
                    >
                      {/* {unit} */}
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder={
                      isRupeeSelected
                        ? inputAmountPlaceholder
                        : inputWeightPlaceholder
                    }
                    value={isRupeeSelected ? rupeeValue : gramValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only numbers
                      if (/^\d*$/.test(value)) {
                        handleInputChange(e); // Call your existing input change handler
                      }
                    }}
                    className="input-field"
                    style={{
                      border: "none",
                      outline: "none",
                      fontSize: "20px",
                      width: "100%",
                    }}
                    required
                  />

                  <div className="input-2">
                    <span
                      className="rupee"
                      style={{
                        // color: metalType == "SILVER" ? "#A6A6A6" : "#FFDF00",
                        color: isGold ? "#FFDF00" : "#A6A6A6",
                        margin: "0px 10px",
                      }}
                    >
                      {isRupeeSelected ? `${gramValue}gm` : `₹${rupeeValue}`}
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "1rem" }}>Recommendations</div>
              <div className="button-container" style={{ gap: "0.5rem" }}>
                {["100", "200", "400", "500"].map((amount) => (
                  <button
                    type="button"
                    className={`buttonBase ${
                      activeAmount === amount ? "buttonActive" : ""
                    }`}
                    style={{ fontWeight: "bold" }}
                    key={amount}
                    onClick={() => handleAmountButtonClick(amount)}
                  >
                    {isRupeeSelected ? `₹${amount}` : `${amount}gm`}
                  </button>
                ))}
              </div>
              <div>
                <button
                  type="submit"
                  style={{
                    width: "60%",
                    border: "2px solid #442673",
                    borderRadius: "2rem",
                    backgroundColor: "white",
                    padding: "10px",
                    color: "#442673",
                    fontSize: "20px",
                    transition:
                      "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "translateY(-10px)";
                    e.currentTarget.style.boxShadow =
                      "0 10px 20px rgba(0, 0, 0, 0.3)";
                    e.currentTarget.style.backgroundColor = "#442673"; // Change background color on hover
                    e.currentTarget.style.color = "white"; // Change font color on hover
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "translateY(0)";
                    e.currentTarget.style.boxShadow = "none";
                    e.currentTarget.style.backgroundColor = "white"; // Revert background color
                    e.currentTarget.style.color = "#442673"; // Revert font color
                  }}
                >
                  BUY NOW
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* ================================SMART CHOICE=================================== */}
      <div>
        <div className="universal_container">
          <h2 style={{ marginBottom: "2rem", color: "#4a2a7d" }}>
            Make a smart choice
          </h2>
          <div className={isGold ? "card_container" : "silver_card_container"}>
            {smartChoiceData
              ?.filter(
                (item) =>
                  isGold ||
                  (item.title !== "Gold FD" && item.title !== "Jewellery")
              )
              ?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: "#DCDAFF",
                    padding: "10px",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <PiCoins size={30} style={{ color: "#4a2a7d" }} />
                    <h5 style={{ color: "#4a2a7d" }}>{item.title}</h5>
                  </div>
                  <ul>
                    <li style={{ color: "#4a2a7d" }}>{item.point1}</li>
                    <li style={{ color: "#4a2a7d" }}>{item.point2}</li>
                  </ul>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* ===============================EASY STEPS==================================== */}
      <div className="universal_container">
        <h2 style={{ marginBottom: "30px", color: "#4a2a7d" }}>
          3 Easy Steps to Buy {isGold ? "Gold" : "Silver"}
        </h2>
        <div className="steps_card_main">
          {easyStepsData?.map((item, index) => (
            <div
              key={index}
              // style={{
              //   padding: "10px",
              //   borderRadius: "10px",
              //   // border: "2px solid #4D16A4",
              //   boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              // }}
              className="buy_gold_card"
            >
              <div className="vibrate-on-hover">{item.img}</div>
              <h4 style={{ color: "#4a2a7d" }}>{item.title}</h4>
              <p style={{ color: "#4a2a7d" }}>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
      {/* ===================================BANNER================================ */}
      {isGold && (
        <div style={{ backgroundColor: "#DCDAFF" }}>
          <div className="universal_container goldfd_banner">
            <div className="goldfd_inner_box">
              <img src="./goldFDLogo.png" alt="goldCoin" width={150} />
              <div>
                <h2 style={{ color: "#4a2a7d" }}>Explore Gold FD</h2>
              </div>
            </div>
            <div>
              <button
                style={{
                  borderRadius: "10px",
                  color: "white",
                  backgroundColor: "#4a2a7d",
                  padding: "15px 30px",
                  border: "none",
                  fontSize: "20px",
                  fontWeight: "bold",
                  // border: "2px solid black",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-10px)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 20px rgba(0, 0, 0, 0.3)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow = "none";
                }}
              >
                Start Saving in Gold FD
              </button>
            </div>
          </div>
        </div>
      )}
      {/* =================================================================== */}
      <div>
        <div className="universal_container">
          <h2 style={{ marginBottom: "2rem", color: "#4a2a7d" }}>
            Still confused about {isGold ? "gold" : "silver"} Savings?
          </h2>
          <div className="saving_cards">
            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                padding: "0.5rem",
                borderRadius: "10px",
                width: "100%",
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="./lowestprice.png"
                alt="lowestprice"
                width="30%"
                // height={150}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h4 style={{ color: "#4a2a7d" }}>Lowest Price</h4>
                <p style={{ color: "#4a2a7d" }}>
                  Start saving in Digital {isGold ? "Gold" : "Silver"} with as
                  little as ₹100
                </p>
              </div>
            </div>
            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                padding: "0.5rem",
                borderRadius: "10px",
                width: "100%",
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                // src="./lockerImg.png"
                src="/safe_secure.png"
                alt="lockerLogo"
                width="30%"
                // height={150}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h4 style={{ color: "#4a2a7d" }}>Safe & Secure</h4>
                <p style={{ color: "#4a2a7d" }}>
                  Your {isGold ? "Gold" : "Silver"} is securely store in
                  trustee-monitered vaults
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =================================CALCULATE SIP================================== */}
      <div>
        <div className="universal_container">
          <h2 style={{ marginBottom: "2rem", color: "#4a2a7d" }}>
            Calculate your SIP returns
          </h2>
          <div className="sip_calculator_main">
            <div
              style={{
                width: "100%",
                border: "2px solid #4A2A7D",
                padding: "1rem",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {totalAmount ? (
                <CustomBarChart totalAmount={totalAmount} sipUrl={sipUrl} />
              ) : (
                <Loader />
              )}
            </div>
            <div
              style={{
                // border: "1px solid black",
                width: "100%",
                // padding: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                <Button
                  variant={activeBtn === "one-time" ? "contained" : "outlined"}
                  sx={{
                    backgroundColor:
                      activeBtn === "one-time" ? "#472878" : "transparent",
                    color: activeBtn === "one-time" ? "white" : "#472878",
                    borderColor:
                      activeBtn === "one-time" ? "transparent" : "#472878",
                    "&:hover": {
                      backgroundColor: "#472878",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    setActiveBtn("one-time");
                    setSipUrl("sipOneTime");
                  }}
                >
                  One Time
                </Button>

                <Button
                  variant={
                    activeBtn === "monthly-sip" ? "contained" : "outlined"
                  }
                  sx={{
                    backgroundColor:
                      activeBtn === "monthly-sip" ? "#472878" : "transparent",
                    color: activeBtn === "monthly-sip" ? "white" : "#472878",
                    borderColor:
                      activeBtn === "monthly-sip" ? "transparent" : "#472878",
                    "&:hover": {
                      backgroundColor: "#472878",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    setActiveBtn("monthly-sip");
                    setSipUrl("sipMonthly");
                  }}
                >
                  Monthly SIP
                </Button>
              </div>
              <div>
                {/* DAILY SAVING=========== */}
                <Box>
                  <Typography
                    id="input-slider"
                    sx={{ fontWeight: "bold", color: "#4a2a7d" }}
                    gutterBottom
                  >
                    Daily Savings:
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      gridTemplateColumns: "3fr 2fr",
                      display: "grid",
                    }}
                  >
                    <Grid item xs>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ color: "#4a2a7d" }}>₹10</div>
                        <div style={{ color: "#4a2a7d" }}>₹20000</div>
                      </div>
                      <Slider
                        value={value || 10} // Fallback to 10 if value is empty
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        min={10}
                        max={20000}
                        sx={{
                          color: "#4a2a7d",
                          "& .MuiSlider-thumb": {
                            backgroundColor: "#4a2a7d",
                          },
                          "& .MuiSlider-track": {
                            backgroundColor: "#4a2a7d",
                          },
                          "& .MuiSlider-rail": {
                            backgroundColor: "grey.400",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <OutlinedInput
                        fullWidth
                        id="outlined-adornment-amount"
                        startAdornment={
                          <InputAdornment position="start">₹</InputAdornment>
                        }
                        value={value}
                        size="small"
                        onChange={handleSipInputChange}
                        onBlur={handleInputBlur} // Validate on blur
                        inputProps={{
                          step: 10,
                          "aria-labelledby": "input-slider",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* Time Period=========== */}
                <Box>
                  <Typography
                    id="input-slider"
                    sx={{ fontWeight: "bold", color: "#4a2a7d" }}
                    gutterBottom
                  >
                    Time Period:
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      gridTemplateColumns: "3fr 2fr",
                      display: "grid",
                    }}
                  >
                    <Grid item xs>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ color: "#4a2a7d" }}>1 year</div>
                        <div style={{ color: "#4a2a7d" }}>40 years</div>
                      </div>
                      <Slider
                        value={timePeriod || 1} // Fallback to 1 if empty
                        onChange={handleTimePeriodSliderChange}
                        aria-labelledby="input-slider"
                        min={1}
                        max={40}
                        sx={{
                          color: "#4a2a7d",
                          "& .MuiSlider-thumb": { backgroundColor: "#4a2a7d" },
                          "& .MuiSlider-track": { backgroundColor: "#4a2a7d" },
                          "& .MuiSlider-rail": { backgroundColor: "grey.400" },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <OutlinedInput
                        fullWidth
                        id="outlined-adornment-amount"
                        startAdornment={
                          <InputAdornment position="start">
                            Years
                          </InputAdornment>
                        }
                        value={timePeriod}
                        size="small"
                        onChange={handleTimePeriodInputChange}
                        onBlur={handleBlur} // Add blur event to apply validation
                        inputProps={{
                          step: 1,
                          min: 1,
                          max: 40,
                          "aria-labelledby": "input-slider",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <button
                style={{
                  width: "100%",
                  // border: "2px solid #442673",
                  border: "none",
                  borderRadius: "10px",
                  backgroundColor: "#442673",
                  padding: "12px",
                  color: "white",
                  fontSize: "20px",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-10px)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 20px rgba(0, 0, 0, 0.3)";
                  e.currentTarget.style.backgroundColor = "#442673"; // Change background color on hover
                  e.currentTarget.style.color = "white"; // Change font color on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow = "none";
                  e.currentTarget.style.backgroundColor = "#442673"; // Revert background color
                  e.currentTarget.style.color = "white"; // Revert font color
                }}
                onClick={handleCalculate}
              >
                Calculate SIP
              </button>
              <div
                style={{
                  backgroundColor: "#DCDAFF",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#4a2a7d",
                  }}
                >
                  <div>Expected Gold:</div>
                  <div style={{ fontWeight: "bold" }}>
                    {Grams && Grams.toString().slice(0, 5)}gm
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#4a2a7d",
                  }}
                >
                  <div>Expected Amount:</div>
                  <div style={{ fontWeight: "bold" }}>{formattedAmount}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================================CUSTOMER REVIEWS=================================== */}
      <div style={{ marginBottom: "2rem" }}>
        <h2
          style={{ marginBottom: "2rem", color: "#4a2a7d" }}
          className="universal_container"
        >
          What our customers have to say!
        </h2>
        <div>
          <CustomerReviews />
        </div>
      </div>
      {/* =================================================================== */}
      {/* <Footer /> */}
    </div>
  );
}
