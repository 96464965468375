import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getSpotGoldApi } from "../../redux/features/spotGold/spotGoldSlice.js";
import "./SpotGold.css";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { FaShoppingCart } from "react-icons/fa";

const SpotGold = () => {
  const dispatch = useDispatch();
  const sliceLoading = useSelector((state) => state.spotGoldSlice.status);
  const sliceResponse = useSelector(
    (state) => state.spotGoldSlice.spotGold.data
  );

  // console.log("DISHANT+++++++++++++++++++", sliceResponse);

  useEffect(() => {
    dispatch(getSpotGoldApi());
  }, []);

  const { t } = useTranslation();
  const { goldProducts } = t("productsPage");

  const navigate = useNavigate();

  // useEffect(() => {
  //   const login = sessionStorage.getItem("token");
  //   if (!login) {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <div
      className="row mx-auto d-flex justify-content-center"
      style={{
        color: "#4A2A7D",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h2 style={{ margin: "20px 0" }}>{goldProducts}</h2>
      <div className="products-container">
        {sliceResponse?.data
          ? sliceResponse?.data?.map((item, index) => (
              <Link
                to={`/dashboard/spotGold/${item?.id}`}
                key={index}
                className="p-2 rounded goldcoin-card"
                style={{
                  // border: "2px solid black",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  cursor: "pointer",
                  width: "100%",
                }}
                state={item}
              >
                <div className="row">
                  <div className="col-12">
                    <img
                      src={item?.productImage}
                      style={{ width: "140px", height: "140px" }}
                    />
                  </div>
                  <div className="col-12" style={{ color: "#472878" }}>
                    <h6>{item?.productName}</h6>
                    <p>{item?.weight}gm</p>
                    <p>
                      ({item?.subCategory?.category?.metalType?.metalFitness}{" "}
                      Purity)
                    </p>
                    <p className="h6">
                      ₹{item?.productPrice[0]?.finalProductPrice}
                    </p>
                    {/* <button
                      style={{
                        width: "100%",
                        padding: "10px 10px",
                        borderRadius: "6px",
                        backgroundColor: "#472878",
                        color: "white",
                        border: "none",
                      }}
                    >
                      BUY NOW
                    </button> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#472878",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#472878",
                          },
                        }}
                        endIcon={<FaShoppingCart />}
                        fullWidth
                      >
                        buy now
                      </Button>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          : [1, 2, 3, 4].map((index) => (
              <div
                key={index}
                className="col-md-3 p-2 rounded m-3 goldcoin-card"
                style={{ border: "2px solid #FAD356", width: "20%" }}
              >
                <Skeleton variant="rectangular" width={120} height={120} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="rectangular" width="100%" height={36} />
              </div>
            ))}
      </div>
      <div style={{marginTop:"50px"}}>
        <Footer />
      </div>
    </div>
  );
};

export default SpotGold;
