import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const historyInvoiceApi = createAsyncThunk('historyInvoice', async (payload) => {
    const response = await axiosInstance.post('/customTrade/getOrderDetails',payload);
    return response.data;
});

const historyInvoiceSlice = createSlice({
    name: "historyInvoice",
    initialState: {
        data: [],
        errors: [],
        status: "idle"
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(historyInvoiceApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(historyInvoiceApi.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = "idle";
            })
            .addCase(historyInvoiceApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

export default historyInvoiceSlice.reducer;
