import { configureStore } from "@reduxjs/toolkit";

import historyReducer from "./historyDataSlice.js";

import historySlice from "./features/historySlice.js";
import goldSlice from "./features/goldSlice.js";
import kycDetailsSlice from "./features/kycDetailsSlice.js";
import profileSlice from "./features/profileSlice.js";
import spotGoldSlice from "./features/spotGold/spotGoldSlice.js";
import spotGoldProductDetailsSlice from "./features/spotGold/spotGoldProductDetailsSlice.js";

import historyInvoiceSlice from "./features/history/historyInvoiceSlice.js";
import spotGoldInvoiceSlice from "./features/history/spotGoldInvoiceSlice.js";
import spotGoldCancelSlice from "./features/history/spotGoldCancelSlice.js";
import cancelChargeSlice from "./features/history/cancelChargeSlice.js";

import stateSlice from "./features/address/stateSlice.js";
import citySlice from "./features/address/citySlice.js";

import razorpayOrderSlice from "./features/razorpay/razorpayOrderSlice.js";
import razorpayPostOrderSlice from "./features/razorpay/razorpayPostOrderSlice.js";
import razorpaySpotGoldOrderSlice from "./features/razorpay/razorpaySpotGoldOrderSlice.js";

import goldFDSchemaSlice from "./features/goldFD/goldFDSchemaSlice.js";
import goldFDCreateSlice from "./features/goldFD/goldFDCreateSlice.js";
import goldFDPreOrderSlice from "./features/goldFD/goldFDPreOrderSlice.js";
import goldFDHistorySlice from "./features/goldFD/goldFDHistorySlice.js";
import goldFDCloseSlice from "./features/goldFD/goldFDCloseSlice.js";
import goldFDCloseConfirmSlice from "./features/goldFD/goldFDCloseConfirmSlice.js";
import tAndCSlice from "./features/goldFD/tAndCSlice.js";

import categorySlice from "./features/home/categorySlice.js";
import categoryProductsSlice from "./features/home/categoryProductsSlice.js";

import addProductCartSlice from "./features/cart/addProductCartSlice.js";
import getProductCartSlice from "./features/cart/getProductCartSlice.js";
import removeProductCartSlice from "./features/cart/removeProductCartSlice.js";
import productQuantityCartSlice from "./features/cart/productQuantityCartSlice.js";
import sessionCartSlice from "./features/cart/sessionCartSlice.js";
import getSessionCartSlice from "./features/cart/getSessionCartSlice.js";

import userAddressSlice from "./features/user/userAddressSlice.js";
import portfolioSlice from "./features/portfolio/portfolioSlice.js";

import oneTimeSipSlice from "./features/sip/oneTimeSipSlice.js";
import createSipSlice from "./features/sip/createSipSlice.js";
import sipHistorySlice from "./features/sip/sipHistorySlice.js";
import sipHistoryDetailsSlice from "./features/sip/sipHistoryDetailsSlice.js";
import cancelSipSlice from "./features/sip/cancelSipSlice.js";

import goldAndSilverGraphSlice from "./features/goldAndSilverGraph/goldAndSilverGraphSlice.js";

import contactUsSlice from "./features/contactUs/contactUsSlice.js";

import goldAndSilverBuySlice from "./features/goldAndSilver/goldAndSilverBuySlice.js";
import goldAndSilverSellSlice from "./features/goldAndSilver/goldAndSilverSellSlice.js";

import shopBannerSlice from "./features/shop/shopBannerSlice.js";

const store = configureStore({
  reducer: {
    historyReducer: historyReducer,
    historySlice,
    historyInvoiceSlice,

    spotGoldInvoiceSlice,
    spotGoldCancelSlice,
    cancelChargeSlice,
    goldSlice,
    profileSlice,
    spotGoldSlice,
    spotGoldProductDetailsSlice,
    stateSlice,
    citySlice,
    razorpayOrderSlice,
    razorpayPostOrderSlice,
    razorpaySpotGoldOrderSlice,
    kycDetailsSlice,

    goldFDSchemaSlice,
    goldFDPreOrderSlice,
    goldFDCreateSlice,
    goldFDHistorySlice,
    goldFDCloseSlice,
    goldFDCloseConfirmSlice,
    tAndCSlice,

    categorySlice,
    categoryProductsSlice,

    addProductCartSlice,
    getProductCartSlice,
    removeProductCartSlice,
    productQuantityCartSlice,
    sessionCartSlice,

    userAddressSlice,

    getSessionCartSlice,

    portfolioSlice,

    oneTimeSipSlice,
    createSipSlice,
    sipHistorySlice,
    sipHistoryDetailsSlice,
    cancelSipSlice,

    goldAndSilverGraphSlice,

    contactUsSlice,

    goldAndSilverBuySlice,
    goldAndSilverSellSlice,

    shopBannerSlice,
  },
});

export default store;
