import React, { useEffect, useState } from "react";
import transactionId from "./transationid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "./goldify.scss";
import { TextField } from "@mui/material";
import { encrypt } from "./encrpetd";
import { toast } from "react-toastify";
import "./bankdetails.css";
// import ifsc from "ifsc";

const inputFields = [
  {
    name: "holdername",
    label: "ACCOUNT HOLDER'S NAME",
    type: "text",
    placeholder: "Enter name here",
  },
  {
    name: "accountno",
    label: "ACCOUNT NUMBER",
    type: "number",
    placeholder: "Enter account number here",
  },
  // {
  //   name: "confirmaccountno",
  //   label: "CONFIRM ACCOUNT NUMBER",
  //   type: "number",
  //   placeholder: "confirm account number here",
  // },
  {
    name: "ifsccode",
    label: "IFSC CODE",
    type: "text",
    placeholder: "Enter IFSC code here",
  },
  {
    name: "bankname",
    label: "Bank Name",
    type: "text",
    placeholder: "Enter bank name here",
  },
  {
    name: "branchname",
    label: "Branch Name",
    type: "text",
    placeholder: "Enter branch name here",
  },
];

function BankDetails() {
  const [values, setValues] = useState({
    holdername: "",
    accountno: "",
    // confirmaccountno: "",
    ifsccode: "",
    bankname: "",
    branchname: "",
  });

  const [showApiError, setShowApiError] = useState(false);

  const [errors, setErrors] = useState({
    holdername: "",
    accountno: "",
    // confirmaccountno: "",
    ifsccode: "",
    bankname: "",
    branchname: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "holdername":
        if (!value) error = "Account holder's name is required";
        break;
      case "accountno":
        if (!value) {
          error = "Account number is required";
        }
        break;
      // case "confirmaccountno":
      //   if (!value) {
      //     error = "Please confirm your account number";
      //   }
      //   break;
      case "ifsccode":
        if (!value) {
          error = "IFSC code is required";
        } else if (!/^[A-Za-z]{4}\d{7}$/.test(value)) {
          error =
            "IFSC code must be 11 characters (4 letters followed by 7 digits)";
        }
        break;
      case "bankname":
        if (!value) error = "Bank name is required";
        break;
      case "branchname":
        if (!value) error = "Branch name is required";
        break;
      default:
        break;
    }
    return error;
  };

  const handleErrors = (name, value) => {
    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const Token = sessionStorage.getItem("token");
  // console.log("login token in inbox", Token);

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  // ----------global variabl--------------
  let setdescription = "";
  let setname = "";
  let setTransid = "";
  let setStatus = "";
  let setTsTransid = "";
  let setStatus1 = "";
  // -----------------end-----------------

  const [CurifscCode, setCurifscCode] = useState("");
  const [ifscCode, setIFSCCode] = useState("");
  const [iSifscCode, setIsIFSCCode] = useState("");
  const [error, setError] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [Accname, setinputname] = useState("");

  // const [name, setname] = useState();
  // const [transid, setTransid] = useState();
  // const [description, setdescription] = useState();
  // const [status, setStatus] = useState();
  // const [tsTransID, setTsTransid] = useState();
  // const [status1, setStatus1] = useState();
  const [mismatchAccountNumber, setMismatchAccountNumber] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState("");
  const [bankName, setbankName] = useState("");
  const [branchname, setbranchname] = useState("");

  // const validateIFSC = () => {
  //     try {
  //         // ifsc.validate(ifscCode);
  //         // setError('');
  //         return true;
  //     } catch (err) {
  // setError('Please enter a valid IFSC code');
  //         return false;
  //     }
  // };

  //---------------------------------Encrypt----------

  useEffect(() => {
    // encryptApi("50350953625", "IDIB000H530");
    // decryptApi();
  }, []);

  const encryptApi = async (accountNumber, ifsc) => {
    const url = `${process.env.REACT_APP_BASE_URL}/encrypt/encrypted`;
    const encryptdata = JSON.stringify({
      transID: transactionId.transactionId,
      docType: "92",
      beneAccNo: values.accountno,
      ifsc: values.ifsccode,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const requestBody = {
        plainText: encryptdata,
      };
      const response = await axios.post(url, requestBody, { headers });

      const encr = response.data.data[0];
      await bankverify(encr);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const bankverify = async (encryptdata) => {
    let data = {
      requestData: encryptdata,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://www.truthscreen.com/v1/apicall/bank/bankAccountVerification",
      headers: {
        username: "production@batuk.in",
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      const respo = response.data.responseData;
      await decryptApi(respo);
    } catch (error) {
      console.log("ERROR IN CATCH+++++++++++++++", error);
    }
  };

  const decryptApi = async (encrData) => {
    const url = `${process.env.REACT_APP_BASE_URL}/decrypt/decrypted`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const requestBody = {
        encText: encrData,
      };
      const response = await axios.post(url, requestBody, { headers });

      // console.log("DISHANT BANK response===============", response);
      const parsedData = JSON.parse(response.data.data[0]);
      console.log("DISHANT DECRYPT response============", parsedData);

      if (parsedData.status == 0) {
        toast.error("Invalid Account number or IFSC code");
        setShowApiError(true);
        return;
      }else if(parsedData.status == 1){
        setShowApiError(false);
        toast.error("Invalid Account number or IFSC code");
        await BankYCAPI();
      }

      setdescription = parsedData.msg.description;
      console.log("bank description", setdescription);
      // console.log("dessss", description);
      setname = parsedData.msg.name;
      console.log("bank name", setname);
      setTransid = parsedData.msg.transID;
      console.log("bank transection id", setTransid);
      setStatus = parsedData.msg.status;
      console.log("set bank status", setStatus);
      setTsTransid = parsedData.msg.tsTransID;
      console.log("set ts transection", setTsTransid);
      setStatus1 = parsedData.status;
      console.log("set status of bank", setStatus1);

      // if (response.status === 200) {
      //   await BankYCAPI();
      // }
    } catch (error) {
      console.log("ERROR IN CATCH+++++++++++++++", error);
    }
  };
  // const ifscHandeler = (e) => {
  //   const value = e.target.value;
  //   const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

  //   if (regex.test(value)) {
  //     setIFSCCode(value);
  //   } else {
  //     alert("invalid Ifsc code");
  //   }
  // };
  //=================================================================

  const BankYCAPI = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/kyc/bank`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const requestBody = {
        msg: {
          description: setdescription,
          name: setname,
          status: setStatus,
          transID: setTransid,
          tsTransID: setTransid,
          account_no: values.accountno,
          Bankname: values.bankname,
          Branchname: values.branchname,
          ifsc: values.ifsccode,
        },
        status: setStatus1,
      };
      console.log("requestBody from bank", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      toast.success("Bank Details saved");
      navigate("/dashboard/sellGold");

      console.log("kyc pan API Response:", response.data.data);
    } catch (error) {
      console.log("ERROR IN CATCH+++++++++++++++", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    Object.keys(values).forEach((key) => {
      const error = validateField(key, values[key]);
      if (error) {
        newErrors[key] = error;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      await encryptApi(accountNumber, ifscCode);
    }

    const value = ifscCode;
    const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    // if (regex.test(value)) {
    //   if (
    //     accountNumber &&
    //     Accname &&
    //     confirmationNumber &&
    //     accountNumber === confirmationNumber
    //   ) {
    //     navigate("/dashboard/sellGold");
    //   } else {
    //     setMismatchAccountNumber(true);
    //     // alert("Account Number Mismatch");
    //     Swal.fire("Account Number Mismatch!");
    //   }
    // } else {
    //   alert("invalid Ifsc code");
    // }
  };

  const handleCancel = (e) => {
    navigate("/dashboard/sellGold");
  };

  return (
    <section>
      <h3
        className="d-flex justify-content-center align-items-center mb-4"
        style={{ color: "#472878" }}
      >
        Bank Details
      </h3>

      <div className="bankdetails-form">
        <div className="row d-flex justify-content-center">
          <div className="card p-4" style={{ border: "1px solid #E3B041" }}>
            {showApiError == true && (
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "15px",
                  background: "red",
                  borderRadius: "5px",
                  padding: "10px",
                  textAlign: "center",
                  fontStyle: "italic",
                }}
              >
                Invalid Account number or IFSC code*
              </div>
            )}
            {/* <form>

              <div className="mb-3">
                <label htmlFor="account_name" className="form-label">
                  ACCOUNT HOLDER'S NAME
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="account_name"
                  name="accountName"
                  value={Accname}
                  onChange={(e) => setinputname(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="ACCOUNT_NUM" className="form-label">
                  ACCOUNT NUMBER
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ACCOUNT_NUM"
                  name="account_no"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="ACCOUNT_NUMBER_C" className="form-label">
                  CONFIRM ACCOUNT NUMBER
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ACCOUNT_NUMBER_C"
                  name="ConfirmationNumber"
                  value={confirmationNumber}
                  onChange={(e) => setConfirmationNumber(e.target.value)}
                  required
                />
                {confirmationNumber && accountNumber !== confirmationNumber ? (
                  <span className="text-danger">Account Number Mismatch</span>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="IFSC_CODE" className="form-label">
                  IFSC CODE
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="IFSC_CODE"
                  name="ifsc"
                  value={ifscCode}
                  onChange={(e) => setIFSCCode(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="IFSC_CODE" className="form-label">
                  Bank Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Bank_Name"
                  name="ifsc"
                  value={bankName}
                  onChange={(e) => setbankName(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="IFSC_CODE" className="form-label">
                  Branch Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="branch_Name"
                  name="branchName"
                  value={branchname}
                  onChange={(e) => setbranchname(e.target.value)}
                />
              </div>



              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={handleSubmit}
                  style={{ backgroundColor: "#880e4f", color: "white" }}
                >
                  Submit
                </button>
              </div>
              </form> */}

            <form onSubmit={handleFormSubmit}>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {inputFields.map((item, i) => (
                  <TextField
                    key={i}
                    name={item.name}
                    value={values[item.name]}
                    label={item.label}
                    type={item.type}
                    placeholder={item.placeholder}
                    variant="outlined"
                    required
                    size="small"
                    fullWidth
                    inputProps={item.name === "pincode" ? { maxLength: 6 } : {}}
                    error={!!errors[item.name]}
                    helperText={errors[item.name]}
                    onChange={(e) => {
                      handleChange(e);
                      handleErrors(item.name, e.target.value);
                    }}
                    style={{ marginBottom: "1rem", width: "100%" }}
                  />
                ))}
              </div>

              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn"
                  onClick={handleCancel}
                  style={{ backgroundColor: "#472878", color: "white" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn"
                  // onClick={handleSubmit}
                  style={{ backgroundColor: "#472878", color: "white" }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BankDetails;
