import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const userAddressApi = createAsyncThunk("userAddress", async () => {
  const response = await axiosInstance.get("/merchant/order_Address");
  return response.data;
});

const userAddressSlice = createSlice({
  name: "userAddress",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userAddressApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userAddressApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(userAddressApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default userAddressSlice.reducer;
