import React, { useEffect, useState } from "react";
import { Button, Rating } from "@mui/material";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaShoppingCart } from "react-icons/fa";

import Navbar from "../../components/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSpotGoldProductDetailsApi } from "../../redux/features/spotGold/spotGoldProductDetailsSlice";
import { addProductCartApi } from "../../redux/features/cart/addProductCartSlice";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/Breadcrumb";
import { getProductCartApi } from "../../redux/features/cart/getProductCartSlice";
import { loadCart } from "../../redux/features/cart/getSessionCartSlice";
import "./ProductDetail.css";
import { IoMdStar, IoMdStarOutline } from "react-icons/io";
import Loader from "../../components/Loader";
import InnerImageZoom from "react-inner-image-zoom";
import { FaCartShopping } from "react-icons/fa6";
import ProductsSkeleton from "../../components/ProductsSkeleton";
import CustomProductCard from "../../components/CustomProductCard";

export default function ProductDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const sliceResponse = useSelector(
    (state) => state.spotGoldProductDetailsSlice.spotGoldProductDetails.data
  );

  const [selectedImage, setSelectedImage] = useState("");

  const spotGoldSliceResponse = useSelector(
    (state) => state.spotGoldSlice.spotGold.data
  );

  const spotGoldSliceLoading = useSelector(
    (state) => state.spotGoldSlice.status
  );

  useEffect(() => {
    if (sliceResponse?.productImage) {
      setSelectedImage(sliceResponse?.productImage);
    } else if (sliceResponse?.productImages?.[0]?.URL) {
      setSelectedImage(sliceResponse?.productImages?.[0]?.URL);
    }
  }, [sliceResponse]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  useEffect(() => {
    dispatch(getSpotGoldProductDetailsApi(id));
  }, [id]);

  const handleAddCart1 = (item = {}) => {
    const fallback = sliceResponse || {}; // Fallback to sliceResponse if item is missing fields

    const product = {
      product_id: item.id || fallback.id,
      name: item.productName || fallback.productName,
      img: item.productImage || fallback.productImage,
      weight: item.weight || fallback.weight,
      quantity: 1,
      price:
        (item.productPrice?.[0]?.finalProductPrice ||
          fallback.productPrice?.[0]?.finalProductPrice) ??
        0, // Ensure valid price
    };

    console.log("DISHANT PAYLOAD^^^^^^^^", product);

    if (token) {
      dispatch(addProductCartApi(product));
      dispatch(getProductCartApi());
      toast.success("Item added to cart");
    } else {
      let cart = JSON.parse(sessionStorage.getItem("cart")) || [];

      // Check if the product is already in the cart
      const productIndex = cart.findIndex((p) => p.product_id === fallback.id);

      if (productIndex !== -1) {
        // If the product is already in the cart, increase the quantity
        cart[productIndex].quantity += 1;
      } else {
        // If the product is not in the cart, add it
        cart.push(product);
      }

      sessionStorage.setItem("cart", JSON.stringify(cart));
      dispatch(loadCart());
      toast.success("Item added to cart");
    }
  };

  const handleBuyNow = () => {
    const product = {
      product_id: sliceResponse?.id,
      name: sliceResponse?.productName,
      img: sliceResponse?.productImage,
      weight: sliceResponse?.weight,
      quantity: 1,
      price: sliceResponse?.productPrice[0]?.finalProductPrice,
    };

    // console.log("DISHANT PAYLOAD^^^^^^^^", product);

    if (token) {
      dispatch(addProductCartApi(product));
      toast.success("Item added to cart");
    } else {
      let cart = JSON.parse(sessionStorage.getItem("cart")) || [];

      // Check if the product is already in the cart
      const productIndex = cart.findIndex(
        (p) => p.product_id === sliceResponse?.id
      );

      if (productIndex !== -1) {
        // If the product is already in the cart, increase the quantity
        cart[productIndex].quantity += 1;
      } else {
        // If the product is not in the cart, add it
        cart.push(product);
      }

      sessionStorage.setItem("cart", JSON.stringify(cart));
      toast.success("Item added to cart");
    }
    navigate("/shop/cart");
  };

  const handleAddCart = (item) => {
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@item", item);

    const product = {
      product_id: item.id,
      name: item.productName,
      img: item.productImage,
      weight: item.weight,
      quantity: 1,
      price: item?.productPrice[0]?.finalProductPrice,
    };

    if (token) {
      dispatch(addProductCartApi(product));
      dispatch(getProductCartApi());
      toast.success("Item added to cart");
    } else {
      let cart = JSON.parse(sessionStorage.getItem("cart")) || [];

      // Check if the product is already in the cart
      const productIndex = cart.findIndex((p) => p.product_id === item.id);

      if (productIndex !== -1) {
        // If the product is already in the cart, increase the quantity
        cart[productIndex].quantity += 1;
      } else {
        // If the product is not in the cart, add it
        cart.push(product);
      }

      sessionStorage.setItem("cart", JSON.stringify(cart));
      dispatch(loadCart());
      toast.success("Item added to cart");
    }
  };

  return (
    <div>
      <Breadcrumb currentPageName="Shop" />
      <div style={{ margin: "2rem" }}>
        <div className="universal_container" style={{ marginBottom: "2rem" }}>
          <div>
            <div className="product_detail_main">
              <div>
                {selectedImage ? (
                  <div
                    style={{
                      border: "1px solid lightgray",
                      padding: "1rem",
                      borderRadius: "10px",
                    }}
                  >
                    <InnerImageZoom
                      src={selectedImage}
                      zoomSrc={selectedImage}
                      zoomType="hover"
                      hideHint={false}
                    />
                  </div>
                ) : (
                  <Loader />
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="zoom-container"
                    onClick={() =>
                      handleImageClick(sliceResponse?.productImage)
                    }
                    style={{
                      border:
                        selectedImage === sliceResponse?.productImage
                          ? "2px solid #4a2a7d"
                          : "1px solid lightgray",
                    }}
                  >
                    <img
                      src={sliceResponse?.productImage || ""}
                      alt="item"
                      className="zoom-img"
                      width="1rem"
                    />
                  </div>
                  {sliceResponse?.productImages?.map((item, i) => (
                    <div
                      key={i}
                      className="zoom-container"
                      onClick={() => handleImageClick(item?.URL)}
                      style={{
                        border:
                          selectedImage === item?.URL
                            ? "2px solid #4a2a7d"
                            : "1px solid lightgray",
                      }}
                    >
                      <img
                        src={item?.URL}
                        alt="item"
                        className="zoom-img"
                        width="1rem"
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  border: "1px solid lightgray",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <h3 style={{ color: "#4a2a7d" }}>
                  {sliceResponse?.productName}
                </h3>
                <div style={{ display: "flex", gap: "5px" }}>
                  <div>
                    <IoMdStar color="#EDBF00" />
                    <IoMdStar color="#EDBF00" />
                    <IoMdStar color="#EDBF00" />
                    <IoMdStar color="#EDBF00" />
                    <IoMdStarOutline color="#EDBF00" />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <div
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      color: "#4a2a7d",
                    }}
                  >
                    ₹{sliceResponse?.productPrice[0]?.finalProductPrice}
                  </div>
                  {/* <s>₹{sliceResponse?.productPrice[0]?.finalProductPrice}</s>
                <span style={{ color: "green", fontWeight: "bold" }}>
                  0% off
                </span> */}
                </div>

                {/* <div style={{ color: "#4a2a7d", fontWeight: "bold" }}>
                  Weight - <span>{sliceResponse?.weight}gm</span>
                </div>
                <div style={{ color: "#4a2a7d" }}>
                  SKU - {sliceResponse?.sku}
                </div>
                <div style={{ color: "#4a2a7d" }}>
                  Purity -{" "}
                  {
                    sliceResponse?.subCategory?.category?.metalType
                      ?.metalFitness
                  }
                </div> */}

                <div style={{ display: "flex", gap: "1rem", color: "#4a2a7d" }}>
                  <div style={{ fontWeight: "bold" }}>
                    <div>Weight</div>
                    <div>SKU</div>
                    <div>Purity</div>
                  </div>
                  <div>
                    <div>:</div>
                    <div>:</div>
                    <div>:</div>
                  </div>
                  <div>
                    <div>{sliceResponse?.weight || "null"}gm</div>
                    <div>{sliceResponse?.sku || "null"}</div>
                    <div>
                      {sliceResponse?.subCategory?.category?.metalType
                        ?.metalFitness || "null"}
                    </div>
                  </div>
                </div>

                {sliceResponse?.metaDescription && (
                  <div style={{ color: "#4a2a7d", marginTop: "1rem" }}>
                    <div style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                      Description
                    </div>
                    <p>{sliceResponse?.metaDescription}</p>
                  </div>
                )}
                <div
                  style={{ display: "flex", gap: "20px", marginTop: "2rem" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    endIcon={<BsFillLightningChargeFill />}
                    onClick={handleBuyNow}
                    sx={{
                      backgroundColor: "#472878",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#472878",
                      },
                    }}
                  >
                    Buy Now
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    endIcon={<FaShoppingCart />}
                    onClick={() => handleAddCart(sliceResponse)}
                    sx={{
                      backgroundColor: "#472878",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#472878",
                      },
                    }}
                  >
                    Add to cart
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="universal_container"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ color: "#4a2a7d" }}>Gold Collection</h2>
          <Button
            onClick={() => navigate("/shop/allProducts")}
            variant="container"
            size="small"
            sx={{
              backgroundColor: "#472878",
              color: "white",
              "&:hover": {
                backgroundColor: "#472878",
              },
            }}
          >
            View All
          </Button>
        </div>

        <div className="gold-products-container">
          {spotGoldSliceLoading === "loading" ? (
            <>
              {[...Array(5)].map((_, index) => (
                <ProductsSkeleton key={index} />
              ))}
            </>
          ) : (
            spotGoldSliceResponse?.data
              ?.slice(0, 10)
              ?.map((item, index) => (
                <CustomProductCard
                  key={index}
                  item={item}
                  handleAddCart={handleAddCart}
                />
              ))
          )}
        </div>
      </div>
    </div>
  );
}
