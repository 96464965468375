import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const goldFDHistoryApi = createAsyncThunk(
  "goldFDHistory",
  async (status, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/fd/orderList/5ebabbef-7?status=${status}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const goldFDHistorySlice = createSlice({
  name: "goldFDHistory",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(goldFDHistoryApi.pending, (state) => {
        state.status = "loading";
        state.errors = [];
      })
      .addCase(goldFDHistoryApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(goldFDHistoryApi.rejected, (state, action) => {
        state.errors.push(action.payload);
        state.status = "idle";
      });
  },
});

export default goldFDHistorySlice.reducer;
