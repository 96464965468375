import React, { useEffect, useState } from "react";
// import gold from "../../images/Goldd.png";
import { FaRupeeSign } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ConfirmationPage = ({ data, useraddress }) => {
  console.log("DISHANT DATA+++++++++++++++", data);
  console.log("DISHANT ADDRESS+++++++++++++++", useraddress);
  const navigate = useNavigate();

  useEffect(() => {
    const login = sessionStorage.getItem("isLOGIN");
    if (!login) {
      navigate("/");
    }
  }, []);

  const Token = sessionStorage.getItem("token");
  const [razorpayResponse, setRazorpayResponse] = useState(null);

  const handleOpenRazorpay = async (responseData) => {
    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
      amount: Number(responseData.amount),
      currency: responseData.currency,
      name: "Bharat Batuk Pvt.ltd.",
      description: "XYZ",
      order_id: responseData.id,
      handler: function (response) {
        console.log(response);
        oderplaceApi();
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const oderplaceApi = () => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Token}`,
    };
  
    const payload = {
      product1: data.sku,
      quantity1: "1",
      addressId: useraddress._id,
    };
  
    console.log("DISHANT PAYLOAD++++++++++++++++++", payload);
  
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/redeem/postOrder`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        const responseData = res.data?.data[0];
            console.log("responseData@@@@@@@@@@@@@@@@",responseData)
            console.log("DISHANT RESPONSE+++++++++++++++++++++", res?.data?.data[0]?.result?.data.merchantTransactionId);
            Swal.fire({
                title: 'Payment Successful',
                text: `Transaction Number: ${res?.data?.data[0]?.result?.data.merchantTransactionId}`,
                icon: 'success'
            })
            navigate("/dashboard/history");
      })
      .catch((err) => {
        console.log("DISHANT ERROR+++++++++++++++++++++", err);
      });
  };
  

  const handlePayment = (TotalAmount) => {
    const requestData = { TotalAmount: TotalAmount };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/orders`, requestData)
      .then((res) => {
        const responseData = res.data.data;
        handleOpenRazorpay(responseData);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div 
    // style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)" }}
    >
      <div className="p-4">
        <div className="row">
          <div className="col-12">
            <div className="contentshadow">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src="/goldHome.png" alt="gold" style={{ width: "80px" }} />
                </div>
                <div>
                  <h6>{data.name}</h6>
                  <p>{data.productWeight} gm</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="contentshadow">
              <h5 className="colored">Purchase Details</h5>
              <div>{data.description}</div>
            </div>
          </div>
          <div className="col-12">
            <div className="contentshadow">
              <h5 className="colored">
                <FaRupeeSign />
                {data.basePrice}
              </h5>
            </div>
          </div>
          <div className="col-12">
            <div className="contentshadow">
              <h5 className="colored">Deliver At</h5>
              <div>
                {useraddress.line1} {useraddress.line2}
              </div>
              <div>
                {useraddress.city} {useraddress.state}
              </div>
              <div>{useraddress.mobileNumber}</div>
              <div>{useraddress.zip}</div>
            </div>
          </div>
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 20,
            }}
          >
            <button
              className="btn w-75 text-light"
              onClick={() => handlePayment(data.basePrice)}
              style={{ backgroundColor: "#472878" }}
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
