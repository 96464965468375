import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

const AboutBatuk = () => {
  const { t } = useTranslation();
  const {link1,link2,link3} = t("aboutBatukTab");
  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="AboutBatuk" style={{ color: "#4A2A7D" }}>
        <div>
          <div className="row">
            <Link target="blank" to="https://batuk.in/">
              <Accordion className="mb-4">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {link1}
                </AccordionSummary>
              </Accordion>
            </Link>

            <Link target="blank" to="https://batuk.in/terms-conditions/">
              <Accordion className="mb-4">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  {link2}
                </AccordionSummary>
              </Accordion>
            </Link>

            <Link target="blank" to="https://batuk.in/terms-conditions/">
              <Accordion className="mb-4">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  {link3}
                </AccordionSummary>
              </Accordion>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutBatuk;
