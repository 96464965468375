import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './Language.css';

const languages = [
  { code: "en", lang: "English", name: "अंग्रेज़ी" },
  { code: "hi", lang: "Hindi", name: "हिंदी" },
  { code: "mr", lang: "Marathi", name: "मराठी" },
  { code: "bn", lang: "Bangla", name: "বাংলা" },
  { code: "gu", lang: "Gujarati", name: "ગુજરાતી" },
  { code: "pa", lang: "Punjabi", name: "ਪੰਜਾਬੀ" },
  { code: "ta", lang: "Tamil", name: "தமிழ்" },
  { code: "te", lang: "Telugu", name: "తెలుగు" },
  { code: "kn", lang: "Kannada", name: "ಕನ್ನಡ" },
  { code: "ml", lang: "Malayalam", name: "മലയാളം" },
];

const Language = () => {
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const changeLanguage =(lng)=>{
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <div className="section">
        <div className=" mt-5">
          <div className="row">
            {languages.map((item, i) => (
              <div className="col-sm-3 mb-4" key={i}>
                <div
                  // className="language_card p-3"
                  className={item.code === i18n.language ?"active_card p-3" : "language_card p-3"}
                  onClick={()=>changeLanguage(item.code)}
                >
                  <h4>{item.lang}</h4>
                  <h5>{item.name}</h5>
                </div>
              </div>
            ))}

            {/* <div className="col-sm-3 mb-4">
              <div
                className="language_card p-3"
                style={{ backgroundColor: "#4A2A7D", color: "white" }}
              >
                <h4>English</h4>
                <h5>अंग्रेज़ी</h5>
              </div>
            </div>
            <div className="col-sm-3 mb-4">
              <div className="language_card p-3">
                <h4>Hindi</h4>
                <h5>हिंदी</h5>
              </div>
            </div>
            <div className="col-sm-3 mb-4">
              <div className="language_card p-3">
                <h4>Marathi</h4>
                <h5>मराठी</h5>
              </div>
            </div>
            <div className="col-sm-3 mb-4">
              <div className="language_card p-3">
                <h4>Bangla</h4>
                <h5>বাংলা</h5>
              </div>
            </div>
            <div className="col-sm-3 mb-4">
              <div className="language_card p-3">
                <h4>Gujarati</h4>
                <h5>ગુજરાતી</h5>
              </div>
            </div>
            <div className="col-sm-3 mb-4">
              <div className="language_card p-3">
                <h4>Punjabi</h4>
                <h5>ਪੰਜਾਬੀ</h5>
              </div>
            </div>
            <div className="col-sm-3 mb-4">
              <div className="language_card p-3">
                <h4>Tamil</h4>
                <h5>தமிழ்</h5>
              </div>
            </div>
            <div className="col-sm-3 mb-4">
              <div className="language_card p-3">
                <h4>Telugu</h4>
                <h5>తెలుగు</h5>
              </div>
            </div>
            <div className="col-sm-3 mb-4">
              <div className="language_card p-3">
                <h4>Kannada</h4>
                <h5>ಕನ್ನಡ</h5>
              </div>
            </div>
            <div className="col-sm-3 mb-4">
              <div className="language_card p-3">
                <h4>Malayalam</h4>
                <h5>മലയാളം</h5>
              </div>
            </div> */}



          </div>
        </div>
      </div>
    </>
  );
};

export default Language;
