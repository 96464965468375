import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const oneTimeSipApi = createAsyncThunk("sip", async (sipPayload) => {
  const { sipUrl, payload } = sipPayload;
  const response = await axiosInstance.post(`/merchant/${sipUrl}`, payload);
  return response.data;
});

const oneTimeSipSlice = createSlice({
  name: "sip",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(oneTimeSipApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(oneTimeSipApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(oneTimeSipApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default oneTimeSipSlice.reducer;
