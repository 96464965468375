import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const portfolioApi = createAsyncThunk(
  "portfolio-goldInLocker",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/act/getport", payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const portfolioSlice = createSlice({
  name: "portfolio-goldInLocker",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(portfolioApi.pending, (state) => {
        state.status = "loading";
        state.errors = [];
      })
      .addCase(portfolioApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(portfolioApi.rejected, (state, action) => {
        state.errors.push(action.payload);
        state.status = "idle";
      });
  },
});

export default portfolioSlice.reducer;
