import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { categoryProductsApi } from "../../redux/features/home/categoryProductsSlice";
import { Link, useParams } from "react-router-dom";
import { Button, Rating, Skeleton } from "@mui/material";
import { FaCartShopping } from "react-icons/fa6";
import { addProductCartApi } from "../../redux/features/cart/addProductCartSlice";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { getSpotGoldApi } from "../../redux/features/spotGold/spotGoldSlice";
import ProductsSkeleton from "../../components/ProductsSkeleton";
import { IoMdStar, IoMdStarOutline } from "react-icons/io";
import Footer from "../../components/footer/Footer";
import CustomProductCard from "../../components/CustomProductCard";

export default function AllProducts() {
  const dispatch = useDispatch();

  const token = sessionStorage.getItem("token");

  const categoryProductsSliceLoading = useSelector(
    (state) => state.categoryProductsSlice
  );

  //   console.log("DISHANT Loading===============", categoryProductsSliceLoading);

  const categoryProductsSliceResponse = useSelector(
    (state) => state.categoryProductsSlice.data.data
  );

  //   console.log(
  //     "DISHANT product API===============",
  //     categoryProductsSliceResponse
  //   );

  const handleAddCart = (item) => {
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@item", item);

    const product = {
      product_id: item.id,
      name: item.productName,
      img: item.productImage,
      weight: item.weight,
      quantity: 1,
      price: item?.productPrice[0]?.finalProductPrice,
    };

    if (token) {
      dispatch(addProductCartApi(product));
      toast.success("Item added to cart");
    } else {
      let cart = JSON.parse(sessionStorage.getItem("cart")) || [];

      // Check if the product is already in the cart
      const productIndex = cart.findIndex((p) => p.product_id === item.id);

      if (productIndex !== -1) {
        // If the product is already in the cart, increase the quantity
        cart[productIndex].quantity += 1;
      } else {
        // If the product is not in the cart, add it
        cart.push(product);
      }

      sessionStorage.setItem("cart", JSON.stringify(cart));
      toast.success("Item added to cart");
    }
  };

  const spotGoldSliceResponse = useSelector(
    (state) => state.spotGoldSlice.spotGold.data
  );
  const spotGoldSliceLoading = useSelector(
    (state) => state.spotGoldSlice.status
  );

  console.log("DISHANT Loading===============", spotGoldSliceLoading);

  useEffect(() => {
    dispatch(getSpotGoldApi());
  }, []);

  return (
    <div style={{ margin: "2rem 0" }}>
      <h2 style={{ textAlign: "center", color: "#4a2a7d" }}>Gold Jewellery</h2>
      <div className="gold-products-container">
        {spotGoldSliceLoading === "loading" ? (
          <>
            {[...Array(5)].map((_, index) => (
              <ProductsSkeleton key={index} />
            ))}
          </>
        ) : (
          spotGoldSliceResponse?.data?.map((item, index) => (
            <CustomProductCard
              key={index}
              item={item}
              handleAddCart={handleAddCart}
            />
          ))
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
}
