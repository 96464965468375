import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  const {
    title1,
    details1,
    title2,
    details2,
    title3,
    details3,
    title4,
    details4,
    title5,
    details5,
    title6,
    details6,
  } = t("helpSupportTab");

  const faqItems = [
    { title: title1, details: details1 },
    { title: title2, details: details2 },
    { title: title3, details: details3 },
    { title: title4, details: details4 },
    { title: title5, details: details5 },
    { title: title6, details: details6 },
  ];


  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  return (
    <div className="AboutBatuk">
      <div className="row">
        {faqItems.map((item, index) => (
          <Accordion className="mb-4" key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              {item.title}
            </AccordionSummary>
            <AccordionDetails>
              {item.details}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
