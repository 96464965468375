import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const { bbpl, line1, line2, line3, line4, termsAndConditions } = t(
    "goldOrderSummaryPage"
  );
  return (
    <div className="col-md-12  " style={{ color: "#4A2A7D" }}>
      <h6 className="text-center mt-1" style={{ color: "#4A2A7D" }}>
        {bbpl}
      </h6>
      <p className="text-center mt-2">
        {line1}
        <br></br>
        {line2}.<br></br>
        {line3}{" "}
        <Link
          target="blank"
          to="https://batuk.in/terms-conditions/"
          style={{ color: "#4A2A7D", fontWeight: "bold" }}
        >
          {termsAndConditions}
        </Link>{" "}
        {line4}
      </p>
    </div>
  );
};

export default Footer;
