import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import Swal from "sweetalert2"; // import Swal

export const createSipApi = createAsyncThunk(
  "createSip",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/sip/create`, payload);
      return response.data;
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        // Display Swal alert based on the status code
        if (status === 400) {
          Swal.fire("Error", "Bad Request. Please check your input.", "error");
        } else if (status === 404) {
          Swal.fire(
            "Error",
            "Not Found. The requested resource could not be found.",
            "error"
          );
        } else {
          Swal.fire("Error", "An unexpected error occurred.", "error");
        }
      } else {
        // Handle other types of errors (network, etc.)
        Swal.fire(
          "Error",
          "Something went wrong. Please try again later.",
          "error"
        );
      }
      return rejectWithValue(error.message);
    }
  }
);

const createSipSlice = createSlice({
  name: "createSip",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {
    resetCreateSipState: (state) => {
      state.data = [];
      state.errors = [];
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSipApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSipApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(createSipApi.rejected, (state, action) => {
        state.errors.push(action.payload); // Add error message to state
        state.status = "idle";
      });
  },
});

// Export the resetState action
export const { resetCreateSipState } = createSipSlice.actions;

export default createSipSlice.reducer;
