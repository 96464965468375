import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const spotGoldCancelApi = createAsyncThunk(
  "spotGoldCancel",
  async (values) => {
    console.log("DISHANT===============", values);
    const payload = {
      id: values.id,
      customerName: values.name,
      customerAccountNo: values.accountNo,
      ifscCode: values.ifsc,
      reason: values.reason,
      transactionId: values.transactionId,
    };
    const response = await axiosInstance.put(
      `/merchant/cancelMerchantOrder2`,
      payload
    );
    return response.data;
  }
);

const spotGoldCancelSlice = createSlice({
  name: "spotGoldCancel",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(spotGoldCancelApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(spotGoldCancelApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(spotGoldCancelApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default spotGoldCancelSlice.reducer;
