import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import "./Address.css";
import { useFormik } from "formik";
import {
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useEffect } from "react";
import {
  BillingAddressSchema,
  ShippingAddressSchema,
} from "../../utils/validationSchemas";
import { getStateApi } from "../../redux/features/address/stateSlice";
import { getCityApi } from "../../redux/features/address/citySlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { razorpayOrderApi } from "../../redux/features/razorpay/razorpayOrderSlice";
import { razorpaySpotGoldOrderApi } from "../../redux/features/razorpay/razorpaySpotGoldOrderSlice";
import { getProfileApi } from "../../redux/features/profileSlice";
import Carousel from "react-bootstrap/Carousel";

// const productImg = [
//   {
//     img:location.state.productImage,
//   },
//   {
//     img:location.state.productImage,
//   },
//   {
//     img:location.state.productImage,
//   },
// ]

const steps = ["Billing Address", "Shipping Address", "Payment"];

export default function Address() {
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("DISHANT LOCATION++++++++++++++++++++++++++++", location.state);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const sliceResponse = useSelector(
    (state) => state.spotGoldProductDetailsSlice.spotGoldProductDetails.data
  );

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // ============================PROFILE===================================

  const profileSliceResponse = useSelector(
    (state) => state?.profileSlice?.data
  );
  console.log("PROFILE SLICE=====================", profileSliceResponse);
  console.log(
    "PROFILE PHONE NUMBER=====================",
    profileSliceResponse?.data?.[0]?.billAddressUser?.name
  );
  useEffect(() => {
    dispatch(getProfileApi());
  }, []);

  // -------------------------BILLING ADDRESS---------------------

  const dispatch = useDispatch();
  const stateSliceResponse = useSelector((state) => state.stateSlice.data);
  const citySliceResponse = useSelector((state) => state.citySlice.data);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      pan: "",
      dob: "",
      address: "",
      state: "",
      city: "",
      landmark: "",
      zip: "",
    },
    validationSchema: BillingAddressSchema,
    onSubmit: (values) => {
      console.log(values);
      handleNext();
    },
    validateOnMount: false, // No validation on mount
    validateOnChange: false, // No validation on change
    validateOnBlur: true, // Validate only on blur
  });

  const handleStateChange = (event, value) => {
    if (value) {
      dispatch(getCityApi(value.id));
      setFieldValue("state", value ? value.name : "");
    } else {
      console.log("Selection cleared");
    }
  };

  const handleCityChange = (event, value) => {
    setFieldValue("city", value ? value.name : "");
  };

  useEffect(() => {
    dispatch(getStateApi());
  }, []);

  useEffect(() => {
    if (
      profileSliceResponse &&
      profileSliceResponse.data &&
      profileSliceResponse.data[0]
    ) {
      const profileData = profileSliceResponse.data[0];
      let firstName = "";
      let lastName = "";

      if (profileData?.billAddressUser?.name) {
        const nameParts = profileData.billAddressUser.name.trim().split(" ");
        firstName = nameParts[0] || ""; // First part is considered as the first name

        // If there's only one part, use it as both first and last name
        if (nameParts.length > 1) {
          lastName = nameParts.slice(1).join(" "); // Remaining parts are considered as the last name
        } else {
          lastName = firstName; // If no last name, use the first name as the last name
        }
      }

      setValues({
        dob: profileData.dob || "",
        phone: profileData.mobileNumber || "",
        firstName: firstName || "",
        lastName: lastName || "",
        email: profileData.emailAddress || "",
        pan: profileData.pan_number || "",
        // Add other fields as necessary
      });
    } else {
      resetForm();
    }
  }, [profileSliceResponse, setValues, resetForm]);

  // ===================================SHIPPING ADDRESS============================================

  const [checkBoxState, setCheckBoxState] = React.useState(false);

  const formikShipping = useFormik({
    initialValues: {
      address: "",
      state: "",
      city: "",
      landmark: "",
      zip: "",
    },
    validationSchema: ShippingAddressSchema,
    onSubmit: (values) => {
      console.log(
        "Shipping Address formik VALUES+++++++++++:",
        formikShipping.values
      );
      console.log("Shipping Address VALUES---------------:", values);
      handleNext();
    },
  });

  const handleShippingState = (event, value) => {
    if (value) {
      dispatch(getCityApi(value.id));
      formikShipping.setFieldValue("state", value ? value.name : "");
    } else {
      console.log("Selection cleared");
    }
  };

  const handleShippingCity = (event, value) => {
    formikShipping.setFieldValue("city", value ? value.name : "");
  };

  const handleCheckboxChange = (event) => {
    setCheckBoxState(!checkBoxState);
  };

  useEffect(() => {
    if (checkBoxState) {
      formikShipping.setValues({
        address: values.address,
        state: values.state,
        city: values.city,
        landmark: values.landmark,
        zip: values.zip,
      });
    } else {
      formikShipping.resetForm();
    }
  }, [checkBoxState, values]);

  // ========================================PAYMENT===============================

  const razorpayOrderSliceResponse = useSelector(
    (state) => state.razorpayOrderSlice.data.data
  );

  // console.log(
  //   "DISHANT SLICE RESPONSE=======================",
  //   razorpayOrderSliceResponse
  // );

  const paymentHandler = (TotalAmount) => {
    if (!TotalAmount) {
      toast.error("Please try again later...");
      return;
    }

    const payload = {
      TotalAmount: location.state.productPrice[0].finalProductPrice,
    };
    dispatch(razorpayOrderApi(payload));
  };

  const handleOpenRazorpay = async () => {
    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
      amount: razorpayOrderSliceResponse?.amount,
      currency: razorpayOrderSliceResponse?.currency,
      name: "Bharat Batuk Pvt.ltd.",
      description: "XYZ",
      order_id: razorpayOrderSliceResponse?.id,
      handler: function (response) {
        console.log("DISHANT RESPONSE+++++++++++++++++++", response);

        const payload = {
          productDetails: [
            {
              productId: location.state.id,
              quantity: 1,
              paymentTypeId: 4,
            },
          ],
          metalType: location.state.subCategory.category.metalType.metalType,
          mobileNumber: values.phone,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          customerAddress: values.address,
          landMark: values.landmark,
          postalCode: values.zip,
          stateName: values.state,
          cityName: values.city,
          shippingAddress: formikShipping.values.address,
          shippingLandMark: formikShipping.values.landmark,
          shippingPostalCode: formikShipping.values.zip,
          shippingStateName: formikShipping.values.state,
          shippingCityName: formikShipping.values.city,
          panCardNumber: values.pan,
          dateOfBirth: values.dob,
        };

        // console.log("DISHANT PAYLOAD+++++++++++++++++++++", payload);

        const orderResponse = dispatch(razorpaySpotGoldOrderApi(payload));

        console.log("DISHANT=================", orderResponse);
        Swal.fire({
          title: "Payment Successful",
          icon: "success",
        });
        navigate("/dashboard/history");
      },
    };

    // console.log("DISHANT OPTIONS++++++++++++++++++++", options);

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  useEffect(() => {
    if (razorpayOrderSliceResponse?.id) {
      handleOpenRazorpay();
    }
  }, [razorpayOrderSliceResponse]);

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="main-box">
      {/* ======================PRODUCT DETAILS================================== */}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          border: "1px solid lightgray",
          borderRadius: "10px",
          padding: "20px",
          // margin: "20px",
        }}
      >
        <Carousel
          controls={false}
          indicators={false}
          interval={3000}
          pause={false}
          activeIndex={currentIndex}
          onSelect={setCurrentIndex}
          style={{
            width: "50%",
            backgroundColor: "#DCDAFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          <Carousel.Item>
            <img
              className="d-block w-100 img-height"
              src={location.state.productImage}
              alt="productImg"
            />
          </Carousel.Item>
          {location.state.productImages.map((image, index) => (
            <Carousel.Item>
              <img
                className="d-block w-100 img-height"
                src={image.URL}
                alt="productImg"
              />
            </Carousel.Item>
          ))}
        </Carousel>

        <div className="d-flex justify-content-evenly align-items-center gap-2">
          {location.state.productImages.map((image, index) => (
            <img
              key={index}
              width={60}
              height={60}
              style={{
                border: "2px solid gold",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              src={image.URL}
              alt={sliceResponse?.title}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
        <div
          className="col-12"
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h5 className="mt-4" style={{ color: "#4A2A7D" }}>
            {location.state.productName}
          </h5>
          <h5 style={{ color: "#4A2A7D" }}>
            &#8377;
            {location.state.productPrice[0]?.finalProductPrice}
          </h5>
          <p style={{ color: "#4A2A7D" }}>(Inclusive of all taxes)</p>
        </div>
      </div>

      {/* ==============================ADDRESS============================== */}

      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && (
              <div>
                <form className="main-container" onSubmit={handleSubmit}>
                  <div className="inputfield-container">
                    <TextField
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.firstName}
                      onChange={handleChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                      // defaultValue="Hello World"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.lastName}
                      onChange={handleChange}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.email}
                      onChange={handleChange}
                      error={!!errors.email}
                      helperText={errors.email}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="phone"
                      name="phone"
                      label="Phone Number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.phone}
                      onChange={handleChange}
                      error={!!errors.phone}
                      helperText={errors.phone}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="pan"
                      name="pan"
                      label="Pan Number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.pan}
                      onChange={handleChange}
                      error={!!errors.pan}
                      helperText={errors.pan}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="dob"
                      name="dob"
                      // label="Date Of Birth"
                      variant="outlined"
                      placeholder="date"
                      size="small"
                      type="date"
                      fullWidth
                      value={values.dob}
                      onChange={handleChange}
                      error={!!errors.dob}
                      helperText={errors.dob}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div className="inputfield-container">
                    <Autocomplete
                      disablePortal
                      id="state-autocomplete"
                      options={stateSliceResponse?.data || []}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      size="small"
                      value={
                        (stateSliceResponse?.data || []).find(
                          (option) => option.name === values.state
                        ) || null
                      }
                      onChange={handleStateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          error={!!errors.state}
                          helperText={errors.state}
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      id="city-autocomplete"
                      options={citySliceResponse?.data || []}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      size="small"
                      value={
                        (citySliceResponse?.data || []).find(
                          (option) => option.name === values.city
                        ) || null
                      }
                      onChange={handleCityChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City"
                          error={!!errors.city}
                          helperText={errors.city}
                        />
                      )}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="landmark"
                      name="landmark"
                      label="Landmark"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.landmark}
                      onChange={handleChange}
                      error={!!errors.landmark}
                      helperText={errors.landmark}
                    />
                    <TextField
                      id="zip"
                      name="zip"
                      label="Zip Code"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.zip}
                      onChange={handleChange}
                      error={!!errors.zip}
                      helperText={errors.zip}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.address}
                      onChange={handleChange}
                      error={!!errors.address}
                      helperText={errors.address}
                    />
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#472878",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#472878",
                        },
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              </div>
            )}
            {activeStep === 1 && (
              <div>
                <form
                  className="main-container"
                  onSubmit={formikShipping.handleSubmit}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Same as shipping Address"
                    onClick={handleCheckboxChange}
                  />
                  <div className="inputfield-container">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={stateSliceResponse?.data || []}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      size="small"
                      value={
                        (stateSliceResponse?.data || []).find(
                          (option) =>
                            option.name ===
                            (checkBoxState
                              ? values.state
                              : formikShipping.values.state)
                        ) || null
                      }
                      onChange={handleShippingState}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          onChange={handleShippingState}
                          error={formikShipping.errors.state ? true : false}
                          helperText={formikShipping.errors.state}
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={citySliceResponse?.data || []}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      size="small"
                      value={
                        (citySliceResponse?.data || []).find(
                          (option) =>
                            option.name ===
                            (checkBoxState
                              ? values.city
                              : formikShipping.values.city)
                        ) || null
                      }
                      onChange={handleShippingCity}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City"
                          onChange={handleShippingCity}
                          error={formikShipping.errors.city ? true : false}
                          helperText={formikShipping.errors.city}
                        />
                      )}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="landmark"
                      name="landmark"
                      label="Landmark"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        checkBoxState
                          ? values.landmark
                          : formikShipping.values.landmark
                      }
                      onChange={formikShipping.handleChange}
                      error={formikShipping.errors.landmark ? true : false}
                      helperText={formikShipping.errors.landmark}
                    />
                    <TextField
                      id="zip"
                      type="tel"
                      name="zip"
                      label="Zip Code"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        checkBoxState ? values.zip : formikShipping.values.zip
                      }
                      onChange={formikShipping.handleChange}
                      error={formikShipping.errors.zip ? true : false}
                      helperText={formikShipping.errors.zip}
                      inputProps={{ maxLength: 6 }}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        checkBoxState
                          ? values.address
                          : formikShipping.values.address
                      }
                      onChange={formikShipping.handleChange}
                      error={formikShipping.errors.address ? true : false}
                      helperText={formikShipping.errors.address}
                    />
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                      sx={{
                        backgroundColor: "#472878",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#472878",
                        },
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        backgroundColor: "#472878",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#472878",
                        },
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              </div>
            )}
            {activeStep === 2 && (
              <div
                style={{
                  margin: "20px",
                  padding: "20px",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <h4 style={{ marginBottom: "10px" }}>Billing Address</h4>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>Name:</div>
                    <div>
                      {values.firstName} {values.lastName}
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>Address:</div>
                    <div>{values.address}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>State:</div>
                    <div>{values.state}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>City:</div>
                    <div>{values.city}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>Landmark:</div>
                    <div>{formikShipping.values.landmark}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>Zip Code:</div>
                    <div>{formikShipping.values.zip}</div>
                  </div>
                </div>
                <div
                  style={{
                    borderBottom: "1px dashed gray",
                    margin: "20px 0px",
                  }}
                ></div>
                <div style={{ textAlign: "center", marginBottom: "25px" }}>
                  <h4 style={{ marginBottom: "10px" }}>Shipping Address</h4>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>Address:</div>
                    <div>{formikShipping.values.address}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>State:</div>
                    <div>{formikShipping.values.state}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>City:</div>
                    <div>{formikShipping.values.city}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>Landmark:</div>
                    <div>{formikShipping.values.landmark}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>Zip Code:</div>
                    <div>{formikShipping.values.zip}</div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleBack}
                    sx={{
                      backgroundColor: "#472878",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#472878",
                      },
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={paymentHandler}
                    sx={{
                      backgroundColor: "#472878",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#472878",
                      },
                    }}
                  >
                    Proceed To Pay
                  </Button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}
