import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const productQuantityCartApi = createAsyncThunk(
  "addProductInCart",
  async ({ productId, payload }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/merchant/updateCart/${productId}`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data || "Failed to update product quantity"
      );
    }
  }
);

const productQuantityCartSlice = createSlice({
  name: "addProductInCart",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(productQuantityCartApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(productQuantityCartApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(productQuantityCartApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default productQuantityCartSlice.reducer;
