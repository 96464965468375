import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./Faq.css";
import Footer from "../../components/footer/Footer";
import Breadcrumb from "../../components/Breadcrumb";

const AccordionData = [
  {
    title: "What is digital gold?",
    content:
      "Digital Gold is a way to buy and sell backed by physical gold stored securely.",
  },
  {
    title: "Is Digital Gold Safe?",
    content:
      "Yes, digital gold is backed by physical gold and stored securely in insured vaults.",
  },
  {
    title: "What are the benefits of saving with digital gold?",
    content: (
      <ul>
        <li>
          <b> Ease of Account Opening:</b> Opening an account with us is a
          simple and easy process.
        </li>
        <li>
          <b> Convenience:</b> Buy & sell anytime, anywhere.
        </li>
        <li>
          <b>No Additional Costs:</b> No brokerage is charged. No charges for
          storage and insurance.
        </li>
        <li>
          <b>Small Quantity:</b> Save in small amounts as low as ₹100.
        </li>
        <li>
          <b>Cash Availability:</b> Easily convert to cash.
        </li>
      </ul>
    ),
  },
  {
    title: "How is Digital Gold Different from Physical Gold?",
    content:
      "Digital Gold offers the same value without storage and security hassles, allowing complete ownership.",
  },
  {
    title: "Can I Convert Digital Gold to Physical Gold?",
    content:
      "Yes, you can convert your digital holdings into physical gold coins or bars for delivery. ",
  },
  {
    title: "How can I convert my digital gold into physical gold?",
    content:
      "Simply request to convert your digital gold into gold coins or bars, with the option of home delivery.",
  },
  {
    title: "What is the purity of the gold provided by Batuk?",
    content: "We provide 24 karat 999 pure Gold through our platform.",
  },
  {
    title: "What is the minimum amount to buy gold from Batuk?",
    content: "You can buy gold for as low as ₹100.",
  },
  {
    title: "Where can I find the invoice for my orders?",
    content:
      "You can download the invoice from your order history section, and we'll email it to you.",
  },
  {
    title: "How Can orders be monitored?",
    content:
      "Customers can monitor their transactions in the history section to keep track of all activities.",
  },
  {
    title: "Can orders be cancelled?",
    content:
      "Once you have successfully placed an order, it cannot be cancelled.",
  },
  {
    title: "Is KYC mandatory for digital gold?",
    content:
      "Completing KYC is mandatory to purchase products on the Batuk Platform. This ensures accurate identification of gold and silver owners in our vaults. ",
  },
  {
    title: "Where is the physical Gold/Silver store?",
    content: "The physical Gold / Silver is securely stored in the vaults.",
  },
  {
    title: "What quantity of Gold/Silver can I buy and sell back to Batuk?",
    content:
      "You can buy any amount of Gold/Silver on Batuk, with a minimum purchase of ₹100. You can sell back the Gold/Silver bought from us after a 7-day cooling-off period for security reasons.",
  },
  {
    title: "Are GST and other taxes included in the quoted prices?",
    content:
      " All prices on our website are exclusive of GST and other applicable taxes, which are added at the final stage of checkout.",
  },
  {
    title: "Why is there a difference in the Buy and Sell price on Batuk?",
    content:
      "The difference between our buy and sell prices is influenced by factors like price volatility and supply. We offer transparent live pricing. GST applies to the buy price, but not the sell price, and additional charges for payment gateways, trustees, and services also contribute to this difference.",
  },
  {
    title: "What happens when I sell my gold or silver?",
    content:
      "You will receive the amount from the sale in your confirmed bank account. The funds will be credited to your bank account within T+2 working days. Please reach out to our customer care team if there are any delays.",
  },
  {
    title: "Is there any additional charge when I sell back my gold /silver?",
    content:
      "No, there is no additional charge. You can sell it back at the live market price displayed on the portal/app.",
  },
  {
    title: "Is Batuk App safe to use?",
    content:
      "Yes, the Batuk App is 100% safe and convenient for your saving journey. It is powered by Augmont and MMTC-PAMP, ensuring the highest security and trust standards.",
  },
  {
    title: "How can I earn money from Batuk?",
    content: `You can earn money through Batuk by using the "Refer and Earn" program. Simply refer the Batuk app to your friends and family, and when they sign up and make a minimum transaction of ₹10,000, you can earn up to ₹100. Additionally, you can start saving with Gold FD, where you can accumulate upto 2.5% in simple terms.`,
  },
  {
    title: "How do I download the Batuk app?",
    content: (
      <div>
        Download the Batuk app for Android and iOS. Click the link below to
        start your saving journey!
        <br />
        <a
          href="https://me-qr.com/z3dr3n8z"
          target="_blank"
          style={{ color: "blue" }}
        >
          https://me-qr.com/z3dr3n8z
        </a>
      </div>
    ),
  },
  {
    title: "How to check the live price of Digital Gold/Silver?",
    content: `To check live Digital Gold/Silver prices, download the Batuk App. On the home screen, click on "Buy Gold/Silver" to see the current prices.`,
  },
  {
    title: "How to buy Digital gold?",
    content: (
      <div>
        Download the Batuk app or login to{" "}
        <a
          href="https://online.batuk.gold"
          target="_blank"
          style={{ color: "blue" }}
        >
          https://online.batuk.gold
        </a>{" "}
        and complete your KYC to buy 24k pure digital gold.{" "}
      </div>
    ),
  },
  {
    title: "How to sell Digital Gold?",
    content: (
      <div>
        <ol>
          <li>
            Download the Batuk app or log in to{" "}
            <a
              href="https://online.batuk.gold"
              target="_blank"
              style={{ color: "blue" }}
            >
              https://online.batuk.gold
            </a>
            .
          </li>
          <li>
            Click the "Sell" option and enter the amount of gold you wish to
            sell.
          </li>
          <li>Now, click on the sell button.</li>
        </ol>
        <p>
          The amount will be credited to your confirmed bank account in T+2
          days.
        </p>
      </div>
    ),
  },
  {
    title: "How can I convert my Digital Gold/Silver into physical form?",
    content: `Just click on the "Delivery" option in the Batuk app, select the type of coin you want, and click on "Buy Now!"`,
  },
  {
    title: "How can I change my e-mail ID?",
    content: (
      <div>
        Currently, we do not provide the option to change profile details. If
        you still need to make changes, please contact us via email at{" "}
        <a href="mailto:connect@batuk.in" style={{ color: "blue" }}>
          connect@batuk.in
        </a>
      </div>
    ),
  },
  {
    title: "How can I change my Phone No?",
    content: (
      <div>
        Currently, we do not provide the option to change profile details. If
        you still need to make changes, please contact us via email at{" "}
        <a href="mailto:connect@batuk.in" style={{ color: "blue" }}>
          connect@batuk.in
        </a>{" "}
      </div>
    ),
  },
  {
    title: "How to add nominee details?",
    content: (
      <ol>
        <li>
          Click on the profile menu in the bottom right of the app's home
          screen.
        </li>
        <li>
          {`
          Select "Profile Settings" -> "Nominee Details" -> "Add Nominee
          Details."`}
        </li>
        <li>Click on save </li>
      </ol>
    ),
  },
  {
    title: "Is GST Applicable?",
    content:
      "3% GST is applicable only once (during purchase) in case of purchase of Digital Gold and Digital Silver.",
  },
  {
    title: "Will my data be sold?",
    content:
      "No. We ensure end-to-end encryption on our application and payment channels.",
  },
  {
    title: "Is my data safe ?",
    content:
      "Yes, Batuk has a very secured system implemented to ensure that user's data is protected. We abide by the best-in-class practices and guidelines when it comes to data security.",
  },
];

export default function Faq() {
  return (
    <div>
      <div style={{ maxWidth: "1240px", margin: "auto", padding: "0.5rem" }}>
        <Breadcrumb currentPageName="FAQs" />
      </div>
      {/* ====================BANNER=================== */}
      <div
        style={{
          position: "relative",
        }}
      >
        <div class="faq-banner">
          <h1 style={{ color: "white" }}>Frequently Asked Questions</h1>
        </div>
      </div>
      {/* ====================FAQ=================== */}
      <div className="faq_box">
        <h1 style={{ marginBottom: "30px" }}>FAQ's</h1>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {AccordionData?.map((item, i) => (
            <Accordion
              key={i}
              sx={{ bgcolor: "#DCDAFF", borderRadius: "10px" }}
              defaultExpanded={i == 0}
            >
              <AccordionSummary
                sx={{ padding: "10px", fontSize: "20px", borderRadius: "20px" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {item.title}
              </AccordionSummary>
              <AccordionDetails>{item.content}</AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      {/* ====================FOOTER=================== */}
      {/* <Footer /> */}
    </div>
  );
}
