import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

// Async thunk for fetching profile data
export const getProfileApi = createAsyncThunk('profile', async () => {
    const response = await axiosInstance.get('/getProfile');
    return response.data;
});

// Initial state
const initialState = {
    data: [],
    errors: [],
    status: "idle"
};

// Profile slice
const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        // Reset action to clear the state
        resetProfile: (state) => {
            state.data = [];
            state.errors = [];
            state.status = "idle";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfileApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getProfileApi.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = "idle";
            })
            .addCase(getProfileApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

// Export actions and reducer
export const { resetProfile } = profileSlice.actions;
export default profileSlice.reducer;
