import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const getCityApi = createAsyncThunk('city', async (id) => {
    const response = await axiosInstance.get(`/augmontGold/city/${id}`);
    return response.data;
});

const citySlice = createSlice({
    name: "city",
    initialState: {
        data: [],
        errors: [],
        status: "idle"
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCityApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getCityApi.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = "idle";
            })
            .addCase(getCityApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

export default citySlice.reducer;
