import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const razorpaySpotGoldOrderApi = createAsyncThunk('razorpayPostOrder', async (payload) => {
    const response = await axiosInstance.post('/merchant/order/create2',payload);
    return response.data;
});

const razorpaySpotGoldOrderSlice = createSlice({
    name: "razorpayPostOrder",
    initialState: {
        data: [],
        errors: [],
        status: "idle"
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(razorpaySpotGoldOrderApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(razorpaySpotGoldOrderApi.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = "idle";
            })
            .addCase(razorpaySpotGoldOrderApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

export default razorpaySpotGoldOrderSlice.reducer;
