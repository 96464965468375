import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const addProductCartApi = createAsyncThunk(
  "addProductInCart",
  async (payload) => {
    const response = await axiosInstance.post("/merchant/addCart", payload);
    return response.data;
  }
);

const addProductCartSlice = createSlice({
  name: "addProductInCart",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addProductCartApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addProductCartApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(addProductCartApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default addProductCartSlice.reducer;
