import React, { useState } from "react";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import "./NavbarMobile.css";
import { NavLink } from "react-router-dom";

const sidebarLinks = [
  {
    name: "Home",
    link: "/",
    img: "/home.png",
    img2: "/home2.png",
  },
  {
    name: "Digital Gold",
    link: "/dashboard/buygold",
    img: "/goldHome.png",
  },
  {
    name: "Digital Silver",
    link: "/dashboard/buysilver",
    img: "/silverHome.png",
  },
  {
    name: "History",
    link: "/dashboard/history",
    img: "/historyHome.png",
    img2: "/history2.png",
  },
  {
    name: "Portfolio",
    link: "/dashboard/portfolio",
    img: "/portfolioHome.png",
    img2: "/portfolio2.png",
  },
  {
    name: "Profile",
    link: "/dashboard/profile",
    img: "/profileHome.png",
    img2: "/profile2.png",
  },
  {
    name: "Spot Gold",
    link: "/dashboard/spotGold",
    img: "/spotGold.png",
  },
];

const NavbarMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = React.useState(null);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <IconButton
        onClick={toggleNavbar}
        edge="start"
        sx={{
          marginRight: 1,
          marginTop:1,
        }}
      >
        <img
          src="/menuicon.png"
          alt="batuk"
          height="30px"
          width="45px"
          style={{ display: "flex", marginRight: 1 }}
        />
      </IconButton>

      <div className={`side-nav ${isOpen ? "open" : ""}`}>
        {/* {isOpen && (
          <IconButton onClick={toggleNavbar} className="close-button">
            <CloseIcon fontSize="large" />
          </IconButton>
        )} */}

        <div
          style={{ color: "white", fontSize: "40px" }}
          onClick={toggleNavbar}
          className="close-button"
        >
          {" "}
          <CloseIcon fontSize="large" />
        </div>
        <nav
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {sidebarLinks.map((item, index) => (
            <NavLink
              to={item.link}
              onClick={() => setIsOpen(false)}
              key={index}
              className={({ isActive }) =>
                isActive ? "active-link" : "sidebar-link"
              }
            >
              <ListItem
                disablePadding
                sx={{ display: "flex", justifyContent: "center" }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <ListItemButton
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 4fr",
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={
                        window.location.pathname === item.link ||
                        hoveredIndex === index
                          ? item.img2 || item.img
                          : item.img
                      }
                      alt={item.name}
                      width={45}
                    />
                  </ListItemIcon>
                  <div
                    style={{
                      //   alignItems: "center",
                      margin: "0px",
                      fontSize: "30px",
                    }}
                  >
                    {item.name}
                  </div>
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default NavbarMobile;
