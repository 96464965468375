import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getProfileApi } from "../../redux/features/profileSlice";

const PersonalDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { nameLabel, emailLabel, dobLabel, phoneLabel } = t(
    "profileSettingPage.personalDetailsTab"
  );
  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const profileSliceResponse = useSelector((state) => state.profileSlice.data);

  const Token = sessionStorage.getItem("token");
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [mobile_number, setMobilenumber] = useState("");
  const [gender, setGender] = useState("");
  const [city, setCity] = useState("");
  const [country, setcountry] = useState("");
  const [dob, setDob] = useState("");

  // ---------------------------profile api------------------------------

  useEffect(() => {
    dispatch(getProfileApi());
  }, []);

  useEffect(() => {
    GetProfileAPI();
  }, []);

  const GetProfileAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/getProfile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const allPersnol = response.data.data[0];
      // console.log("Get Profile API Response", response);
      setName(allPersnol.billAddressUser.name);
      setemail(allPersnol.emailAddress);
      // setCity(allPersnol.city);
      // setcountry(allPersnol.billAddressUser.country);
      setDob(allPersnol.dob);
      setMobilenumber(allPersnol.mobileNumber);
      setGender(allPersnol.gender);
    } catch (error) {
      // console.error("Error connecting to API in getprofile function", error);
    }
  };

  return (
    <>
      <div className="section">
        <div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card p-4">
                <form>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          {nameLabel}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Name"
                          value={profileSliceResponse?.data?.[0]?.fullName}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="Email" className="form-label">
                          {emailLabel}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="Email"
                          placeholder="Email"
                          value={profileSliceResponse?.data?.[0]?.emailAddress}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="date_of_birth" className="form-label">
                          {dobLabel}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="date_of_birth"
                          value={profileSliceResponse?.data?.[0]?.dob}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="Phone_Number" className="form-label">
                          {phoneLabel}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="Phone_Number"
                          placeholder="Phone Number"
                          value={profileSliceResponse?.data?.[0]?.mobileNumber}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="mb-3">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="Male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={() => setGender("Male")}
                        readOnly
                      />
                      <label className="form-check-label" htmlFor="Male">
                        Male
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="Female"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={() => setGender("Female")}
                        readOnly
                      />
                      <label className="form-check-label" htmlFor="Female">
                        Female
                      </label>
                    </div>
                  </div> */}
                  {/* <button type="button" className="btn  btn-red">
                    Submit
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetail;
