import React, { useEffect, useState } from "react";
import transactionId from "./transationid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
// import ifsc from "ifsc";

function BankDetails() {
  const Token = sessionStorage.getItem("token");
  // console.log("login token in inbox", Token);
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate('/');
    }
  }, []);


  const [CurifscCode, setCurifscCode] = useState("");
  const [ifscCode, setIFSCCode] = useState("");
  const [iSifscCode, setIsIFSCCode] = useState("");
  const [error, setError] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [Accname, setinputname] = useState("");

  const [name, setname] = useState();
  const [transid, setTransid] = useState();
  const [description, setdescription] = useState();
  const [status, setStatus] = useState();
  const [tsTransID, setTsTransid] = useState();
  const [status1, setStatus1] = useState();
  const [mismatchAccountNumber, setMismatchAccountNumber] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState("");
  const [bankName, setbankName] = useState("");
  const [branchname, setbranchname] = useState("");

  // const validateIFSC = () => {
  //     try {
  //         // ifsc.validate(ifscCode);
  //         // setError('');
  //         return true;
  //     } catch (err) {
  // setError('Please enter a valid IFSC code');
  //         return false;
  //     }
  // };

  //---------------------------------Encrypt----------

  useEffect(() => {
    // Enone("50350953625", "IDIB000H530");
    // Dnone();
  }, []);

  const Enone = async (accountNumber, ifsc) => {
    console.log("ifsc", ifsc);
    const url = `${process.env.REACT_APP_BASE_URL}/encrypt/encrypted`;
    const encryptdata = JSON.stringify({
      transID: transactionId.transactionId,
      docType: "92",
      beneAccNo: accountNumber,
      ifsc: ifsc,
    });
    // console.log("gfnfgfvh", encryptdata);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const requestBody = {
        plainText: encryptdata,
      };
      const response = await axios.post(url, requestBody, { headers });

      // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", response);
      const encr = response.data.data[0];
      // console.log("encripted data ====", encr);
      // Dnone(encr);
      bankverify(encr);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  //     if (accountNumber !== confirmationNumber) {
  //         setMismatchAccountNumber(true)
  //     } else {
  //         setMismatchAccountNumber(false)
  // }

  // ----------------------------
  const Dnone = async (encrData) => {
    const url = `${process.env.REACT_APP_BASE_URL}/decrypt/decrypted`;
    // const encryptdata =
    //   "HSn5ZTK8BwXYm7Wy6Fmh98RFAfLlBRCzXMhf0B61tIGIAQxIrmP3Rlaqs1/z7dghE2zLTiAGjwxOgbM7AKxcFNUU3F8SZHkQo/gOtmQkgF5PKMrr6VSxlDmQsdm1JWgW:X+6H8ntvX0u8DlouAWUJ+w==";
    const encryptdata = encrData;
    console.log("jasjdbjksncj", encryptdata);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const requestBody = {
        encText: encrData,
      };
      const response = await axios.post(url, requestBody, { headers });

      // console.log("main response", response);
      const parsedData = JSON.parse(response.data.data[0]);
      // console.log("poigufydtsfghjk", parsedData);

      setdescription(parsedData.msg?.description);
      // console.log("dessss", description);
      setname(parsedData.msg?.name);
      setTransid(parsedData.msg?.transID);
      setStatus(parsedData.msg?.status);
      setTsTransid(parsedData.msg?.tsTransID);
      setStatus1(parsedData?.status);

      if (response.status === 200) {
        // PanKYCAPI();
        BankYCAPI();
      }
    } catch (error) {
      // console.log("Error:", error);
    }
  };

  //=================================================================
  const bankverify = async (encryptdata) => {
    let data = {
      requestData: encryptdata,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://www.truthscreen.com/v1/apicall/bank/bankAccountVerification",
      headers: {
        username: "production@batuk.in",
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      // console.log("Response pan verify api:", response);
      const respo = response.data.responseData;
      // console.log("ttttttttttttt", respo);
      Dnone(respo);
    } catch (error) {
      // console.log("Error:", error);
    }
  };
  // const ifscHandeler = (e) => {
  //   const value = e.target.value;
  //   const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

  //   if (regex.test(value)) {
  //     setIFSCCode(value);
  //   } else {
  //     alert("invalid Ifsc code");
  //   }
  // };
  //=================================================================

  const BankYCAPI = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/kyc/bank`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const requestBody = {
        msg: {
          description: description,
          name: name,
          status: status,
          transID: transid,
          tsTransID: tsTransID,
          account_no: accountNumber,
          Bankname: bankName,
          Branchname: branchname,
          ifsc: ifscCode
        },
        status: status1,
      };
      // console.log("requestBody from bank", requestBody)
      const response = await axios.post(url, requestBody, { headers });

      // console.log("kyc pan API Response:", response.data.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // ------------------end -----------------------------
  const navigate = useNavigate();
  const handleSubmit = () => {
    // Enone("50350953625", "IDIB000H530");
    Enone(accountNumber, ifscCode);
    // console.log("Navigating...");

    const value = ifscCode;
    const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    if (regex.test(value)) {
      if (
        accountNumber &&
        Accname &&
        confirmationNumber &&
        accountNumber === confirmationNumber
      ) {
        navigate("/dashboard/sellGold");
      } else {
        setMismatchAccountNumber(true);
        // alert("Account Number Mismatch");
        Swal.fire("Account Number Mismatch!");
      }
    } else {
      alert("invalid Ifsc code");
    }
  };

  const handleCancel = (e) => {
    navigate("/dashboard/sellGold");
  };

  return (
    <section>
      <h3
        className="d-flex justify-content-center align-items-center mb-4"
        style={{ color: "#880e4f" }}
      >
        Bank Details
      </h3>

      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-sm-5">
            <div className="card p-4" style={{ border: "1px solid #d6d0d0" }}>
              <div className="mb-3">
                <label htmlFor="account_name" className="form-label">
                  ACCOUNT HOLDER'S NAME
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="account_name"
                  name="accountName"
                  value={Accname}
                  onChange={(e) => setinputname(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="ACCOUNT_NUM" className="form-label">
                  ACCOUNT NUMBER
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ACCOUNT_NUM"
                  name="account_no"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="ACCOUNT_NUMBER_C" className="form-label">
                  CONFIRM ACCOUNT NUMBER
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ACCOUNT_NUMBER_C"
                  name="ConfirmationNumber"
                  value={confirmationNumber}
                  onChange={(e) => setConfirmationNumber(e.target.value)}
                  required
                />
                {confirmationNumber && accountNumber !== confirmationNumber ? (
                  <span className="text-danger">Account Number Mismatch</span>
                ) : null}
                {/* {mismatchAccountNumber?`Account Number Mismatch`:null} */}
              </div>
              <div className="mb-3">
                <label htmlFor="IFSC_CODE" className="form-label">
                  IFSC CODE
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="IFSC_CODE"
                  name="ifsc"
                  value={ifscCode}
                  onChange={(e) => setIFSCCode(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="IFSC_CODE" className="form-label">
                  Bank Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Bank_Name"
                  name="ifsc"
                  value={bankName}
                  onChange={(e) => setbankName(e.target.value)}
                />
                {/* <div className="text-danger">{emailError}</div> */}
              </div>
              <div className="mb-3">
                <label htmlFor="IFSC_CODE" className="form-label">
                  Branch Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="branch_Name"
                  name="branchName"
                  value={branchname}
                  onChange={(e) => setbranchname(e.target.value)}
                />
                {/* <div className="text-danger">{emailError}</div> */}
              </div>















              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={handleSubmit}
                  style={{ backgroundColor: "#880e4f", color: "white" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BankDetails;
