import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackButton = ({ link }) => {
  return (
    // <Link to="/dashboard/buygold">
    <Link to={link}>
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        sx={{ color: "#472878" }}
      >
        Back
      </Button>
    </Link>
  );
};

export default BackButton;
