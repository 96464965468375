import React from "react";
import Footer from "../../components/footer/Footer";
import Breadcrumb from "../../components/Breadcrumb";

export default function RefundPolicy() {
  return (
    <div>
      <Breadcrumb currentPageName="Refund Policy" />
      <div
        style={{
          maxWidth: "1240px",
          margin: "auto",
          padding: "2rem 0.5rem",
          color: "#4a2a7d",
        }}
      >
        <h5>Refund Policy</h5>
        <p>
          At Batuk, we are committed to providing exceptional products and
          services. While we strive for customer satisfaction, we do not
          currently offer refunds for any purchases made through our platform.
          <br /> <br />
          Exceptional Circumstances
          <br /> <br />
          In the rare event of a product or service defect or error on our part,
          we will review the situation on a case-by-case basis and may offer an
          appropriate resolution.
          <br /> <br />
          Contact Us
          <br /> <br />
          If you have any questions or concerns about your purchase, please
          don’t hesitate to contact us at connect@batuk.com. We’re here to
          assist you in any way we can.
        </p>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
