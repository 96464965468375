import React from "react";
import Slider from "react-slick";
import { Avatar } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const customerData = [
  {
    img: "P",
    name: "Prateek Dixit",
    location: "Bengaluru, India",
    review: "Simple interface, quick transactions. Perfect for saving!",
  },
  {
    img: "A",
    name: "Akshay Mehta",
    location: "Lucknow, India",
    review: "Batuk is a great app for Digital Gold saving.",
  },
  {
    img: "S",
    name: "Sneha Mathur",
    location: "Faridabad, India",
    review: "Batuk has changed how I save money.",
  },
  {
    img: "D",
    name: "Dishant",
    location: "Delhi, India",
    review: "Simple interface, quick transactions. Perfect for saving!",
  },
  {
    img: "N",
    name: "Nagender",
    location: "Lucknow, India",
    review: "Batuk is a great app for Digital Gold saving.",
  },
  {
    img: "J",
    name: "Jaspal",
    location: "Noida, India",
    review: "Batuk has changed how I save money.",
  },
];

export default function CustomerReviews() {
  const settings = {
    infinite: true, // Ensures the slider loops
    slidesToShow: 3, // Number of slides visible
    slidesToScroll: 1, // Scroll one slide at a time
    dots: false, // Disable dots navigation
    arrows: false, // Disable arrows (optional)
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1000, // Set speed to 0 to keep it moving without delay
    speed: 5000, // Set speed of the transition (adjust as needed)
    // cssEase: "linear", // Linear easing for smooth continuous movement
    pauseOnHover: true, // Prevent pausing on hover (optional)
    responsive: [
      {
        breakpoint: 1024, // For laptop and larger screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For tablets and small screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For mobile screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {customerData?.map((item, index) => (
        <div key={index}>
          <div
            style={{
              backgroundColor: "#DCDAFF",
              minHeight: "170px",
              borderRadius: "10px",
              margin: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                backgroundColor: "#472878",
                padding: "1rem",
                color: "white",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <Avatar>{item.img}</Avatar>
              <div>
                <div style={{ fontWeight: "bold", color: "white" }}>
                  {item.name}
                </div>
                <div style={{ color: "white" }}>{item?.location}</div>
              </div>
            </div>
            <div style={{ padding: "1rem", color: "#4a2a7d" }}>
              {item.review}
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}
