import React, { useEffect, useState } from "react";
// import "./buynow.scss";
import axios from "axios";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const ShippingAddressForm = ({ data, close, handleModalClose }) => {
  console.log("DIshANt++++++++++++++++++", handleModalClose);
  const Token = sessionStorage.getItem("token");

  const [editData, setEditData] = useState(data);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [state_code, setstate_code] = useState(0);
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [pinmsg, setpinmsg] = useState("");

  const navigate = useNavigate();

  const PINAPI = async (pinCode) => {
    console.log("PIN CODE+++++++++++++++++++", pinCode);
    let data = JSON.stringify({
      pinCode: pinCode,
    });

    console.log("PAYLOAD PIN CODE+++++++++++++++++++", data);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/act/pin`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      console.log("RESPONSEEEEEEEEEEEEEEEEEEE", response.data);
      setpinmsg(response.data.message);
      return response.data;
    } catch (error) {
      console.log("ERROR IN CATCH OF PINCODE API", error);
    }
  };

  useEffect(() => {
    callAPI();
    callCityAPI();
  }, []);

  useEffect(() => {
    if (stateData.length > 0) {
      setSelectedState(stateData[0]._id); // Assuming stateData contains an array of objects with _id field
    }
  }, [stateData]);

  useEffect(() => {
    if (selectedState !== "") {
      const filteredCities = cityData.filter(
        (city) => city.state_id === selectedState
      );
      if (filteredCities.length > 0) {
        setSelectedCity(filteredCities[0]._id); // Assuming cityData contains an array of objects with _id field
      }
    }
  }, [cityData, selectedState]);
  // ------------------------------------------State API---------------------------

  const callAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stateCode/getstateCode`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(url, { headers });
      setStateData(response.data.data);
      // console.log("state api response", response.data);
      setstate_code(response.data);
      // const state_code = response.data;
      return response.data;

      // console.log("state code");
    } catch (error) {
      // console.error("Error connecting to state API", error);
      throw error; // Throw error to handle it later
    }
  };

  // ---------------------------------------City Api-----------------------
  const callCityAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stateCode/getcity`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(url, { headers });
      setCityData(response.data.data);
      return response.data; // Return response data
    } catch (error) {
      // console.error("Error connecting to city API", error);
      throw error; // Throw error to handle it later
    }
  };

  const handleStateChange = (event) => {
    const selectedStateId = event.target.value;
    setSelectedState(selectedStateId);

    // Find the selected state object from stateData
    const selectedStateObject = stateData.find(
      (state) => state._id === selectedStateId
    );
    if (selectedStateObject) {
      setSelectedStateCode(selectedStateObject.state_code);
      setSelectedStateName(selectedStateObject.name);
      const State_Name = selectedStateObject.name;
      const State_code = selectedStateObject.state_code;
      // console.log("Selected State Code:", selectedStateObject.state_code);
      // console.log("Selected State Name:", selectedStateObject.name);
    }
  };
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setSelectedCity(selectedCityId);

    // Find the selected city object from cityData
    const selectedCityObject = cityData.find(
      (city) => city._id === selectedCityId
    );
    if (selectedCityObject) {
      setSelectedCityName(selectedCityObject.city_name);
      // console.log("Selected City Name:", selectedCityObject.city_name);
    }
  };

  let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const [formData, setFormData] = useState({
    name: userDetails?.name || "",
    line1: "",
    line2: "",
    state: "",
    statecode: "",
    city: "",
    zip: "",
    mobileNumber: userDetails?.phone || "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.line1) {
      newErrors.line1 = "Address Line 1 is required";
    }
    // if (!formData.state) {
    //   newErrors.state = "State is required";
    // }
    // if (!formData.city) {
    //   newErrors.city = "City is required";
    // }
    if (!formData.zip) {
      newErrors.zip = "Pin Code is required";
    } else if (!/^\d{6}$/.test(formData.zip)) {
      newErrors.zip = "Pin Code must be 6 digits";
    }
    if (!formData.mobileNumber) {
      newErrors.mobileNumber = "Mobile Number is required";
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber = "Mobile Number must be 10 digits";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    console.log("DISHANT+++++++++++++++++++++++++", formData);
    if (validateForm()) {
      if (editData !== undefined) {
        handleSubmit1();
      } else {
        handleSubmit();
      }
    }
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const formDataWithStateCity = {
      ...formData,
      state: selectedStateName,
      city: selectedCityName,
      statecode: selectedStateCode,
    };

    // const result = await PINAPI(formDataWithStateCity.zip);
    // console.log("API RESPONSE+++++++++++++++++++++", pinmsg);
    // console.log(" PIN API RESPONSE+++++++++++++++++++++", result);

    // if (result.error === true) {
    //   toast.error(result.message);
    //   return;
    // }

    // if (pinmsg === "Pincode is invalid") {
    //   toast.error(pinmsg);
    //   return;
    // }
    // if (pinmsg === "success") {
    // if (result.error === false) {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/address/addaddress`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const body = {
        city: formDataWithStateCity.city,
        country: "India",
        line1: formDataWithStateCity.line1,
        line2: formDataWithStateCity.line2,
        mobileNumber: formDataWithStateCity.mobileNumber,
        name: formDataWithStateCity.name,
        state: formDataWithStateCity.state,
        statecode: String(formDataWithStateCity.statecode),
        type: "D",
        zip: formDataWithStateCity.zip,
      };

      console.log("PAYLOAD++++++++++++++", body);
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });

      const data = await response.json();
      handleModalClose();
      toast.success("Address updated successfully");
      // navigate("/dashboard/profile");
    } catch (error) {
      console.error("ERROR IN CATCH+++++++++++++++++", error);
    }
    // }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    console.log("..............edit address");
    const formDataWithStateCity = {
      ...editData,
      state: selectedStateName,
      city: selectedCityName, // Use selectedCityName instead of selectedCity
      statecode: selectedStateCode, // Optionally add state code if needed
    };

    // console.log("Form submitted with data function 1:", formDataWithStateCity); // Log form data to console

    try {
      const url = `${process.env.REACT_APP_BASE_URL}/address/updateaddress`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const body = {
        _id: editData._id,
        city: editData.city,
        country: "India",
        line1: editData.line1,
        line2: formDataWithStateCity.line2,
        mobileNumber: editData.mobileNumber,
        name: editData.name,
        state: formDataWithStateCity.state,
        statecode: String(formDataWithStateCity.statecode),
        type: "D",
        zip: formDataWithStateCity.zip,
      };

      // console.log(body)
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      // console.log("updatedata", data);
    } catch (error) {
      // console.error("Error connecting to API", error);
    }
  };

  const editInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevFormData) => ({
      ...editData,
      [name]: value,
    }));
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div>
          <form onSubmit={handleSubmitForm}>
            <div className="row mb-3">
              <div className="col-md-12">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  required
                  className="form-control text-dark"
                  id="name"
                  name="name"
                  value={editData !== undefined ? editData.name : formData.name}
                  onChange={
                    editData !== undefined ? editInputChange : handleInputChange
                  }
                />
                {errors.name && (
                  <div className="text-danger">{errors.name}</div>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="address1" className="form-label">
                  Address Line 1
                </label>
                <input
                  type="text"
                  required
                  className="form-control text-dark"
                  id="line1"
                  name="line1"
                  value={
                    editData !== undefined ? editData.line1 : formData.line1
                  }
                  onChange={
                    editData !== undefined ? editInputChange : handleInputChange
                  }
                />
                {errors.line1 && (
                  <div className="text-danger">{errors.line1}</div>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="address2" className="form-label">
                  Address Line 2
                </label>
                <input
                  type="text"
                  className="form-control text-dark"
                  id="line2"
                  name="line2"
                  value={
                    editData !== undefined ? editData.line2 : formData.line2
                  }
                  onChange={
                    editData !== undefined ? editInputChange : handleInputChange
                  }
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="State" className="form-label">
                  State
                </label>
                <select
                  className="form-control"
                  id="State"
                  name="state"
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  <option value="" selected disabled hidden>
                    Select State
                  </option>
                  {stateData.map((state) => (
                    <option value={state._id} key={state._id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <div className="text-danger">{errors.state}</div>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="City" className="form-label">
                  City
                </label>
                <select
                  className="form-control"
                  id="City"
                  name="city"
                  value={selectedCity}
                  onChange={handleCityChange}
                >
                  <option value="" selected disabled hidden>
                    Open this select menu
                  </option>
                  {cityData
                    .filter((city) => city.state_id === selectedState)
                    .map((city) => (
                      <option value={city._id} key={city._id}>
                        {city.city_name}
                      </option>
                    ))}
                </select>
                {errors.city && (
                  <div className="text-danger">{errors.city}</div>
                )}
              </div>
            </div>
            {/* ==================ZIP & PHONE========================== */}
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="Zip" className="form-label">
                  Pin Code
                </label>
                <input
                  required
                  className="form-control text-dark"
                  id="zip"
                  name="zip"
                  value={editData !== undefined ? editData.zip : formData.zip}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 6) {
                      // Only allow numbers
                      editData !== undefined
                        ? editInputChange(e)
                        : handleInputChange(e);
                    }
                  }}
                />
                {errors.zip && <div className="text-danger">{errors.zip}</div>}
              </div>
              <div className="col-md-6">
                <label htmlFor="mobile" className="form-label">
                  Mobile Number
                </label>
                <input
                  required
                  className="form-control text-dark"
                  id="mobile"
                  name="mobileNumber"
                  value={
                    editData !== undefined
                      ? editData.mobileNumber
                      : formData.mobileNumber
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 10) {
                      // Only allow numbers
                      editData !== undefined
                        ? editInputChange(e)
                        : handleInputChange(e);
                    }
                  }}
                />
                {errors.mobileNumber && (
                  <div className="text-danger">{errors.mobileNumber}</div>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="btn my-3 w-100 text-light"
              style={{ backgroundColor: "#4A2A7D" }}
            >
              Submit
            </button>
            {/* <button onClick={handleModalClose}>cancel</button> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShippingAddressForm;
