import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

export default function Breadcrumb({ currentPageName }) {
  //   console.log("CURRENT PAGE NAME==========", currentPageName);
  const breadcrumbs = [
    <Link
      key="1"
      to="/"
      className="hover-underline"
      style={{ color: "#4A2A7D" }}
    >
      {/* <IoIosArrowRoundBack size={25} /> */}
      Home
    </Link>,
    <Typography key="3" sx={{ color: "#4A2A7D", fontWeight: "bold" }}>
      {currentPageName && currentPageName}
    </Typography>,
  ];

  return (
    <Stack spacing={1} className="universal_container">
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
