export function formatMessageTime(createdAt) {
  const date = new Date(createdAt);

  const istDate = new Date(date.getTime());

  // Get the current time
  const now = new Date();
  const timeDifference = now - date;
  const hoursDifference = timeDifference / (1000 * 60 * 60);

  if (hoursDifference >= 24) {
    // Display the date if older than 24 hours
    return istDate.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  } else {
    // Format the time with AM/PM
    return istDate.toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }
}
