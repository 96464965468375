import React from "react";
import Slider from "react-slick";

export default function ShopBanner({ shopBannerApi }) {
  console.log("BANNER%%%%%%%%%%%%%%%%", shopBannerApi);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      {shopBannerApi?.map((banner, index) => {
        console.log(
          banner.imageUrl,
          "pawnesh poooooooooooooooooooooooooooooooo"
        );
        return (
          <div key={index}>
            <img
              src={banner?.imageUrl}
              alt={`banner${index}`}
              width="100%"
              style={{ display: "block" }} // Ensure proper block-level rendering
            />
          </div>
        );
      })}
    </Slider>
  );
}
