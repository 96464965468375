import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sipHistoryApi } from "../../redux/features/sip/sipHistorySlice";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";

export default function SipHistory() {
  const dispatch = useDispatch();
  const [activeFDFilter, setActiveFDFilter] = useState("active");

  const getGoldFDHistory = (filter) => {
    setActiveFDFilter(filter);
  };

  const sipHistoryApiLoading = useSelector(
    (state) => state.sipHistorySlice.status
  );
  const sipHistoryApiResponse = useSelector(
    (state) => state.sipHistorySlice.data.sipList
  );

  console.log("SIP API==============", sipHistoryApiResponse);

  useEffect(() => {
    dispatch(sipHistoryApi());
  }, [dispatch]);

  // Determine which list to display based on the active filter
  const currentList =
    activeFDFilter === "active"
      ? sipHistoryApiResponse?.activeList
      : sipHistoryApiResponse?.inActiveList;

  return (
    <div>
      {/* ====================BUTTONS========================= */}
      <div
        style={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          marginBottom: "2rem",
        }}
      >
        <Button
          variant={activeFDFilter === "active" ? "contained" : "outlined"}
          onClick={() => getGoldFDHistory("active")}
          sx={{
            backgroundColor:
              activeFDFilter === "active" ? "#472878" : "transparent",
            color: activeFDFilter === "active" ? "white" : "#472878",
            borderColor:
              activeFDFilter === "active" ? "transparent" : "#472878",
            "&:hover": {
              backgroundColor: "#472878",
              color: "white",
            },
          }}
        >
          Active
        </Button>

        <Button
          variant={activeFDFilter === "close" ? "contained" : "outlined"}
          onClick={() => getGoldFDHistory("close")}
          sx={{
            backgroundColor:
              activeFDFilter === "close" ? "#472878" : "transparent",
            color: activeFDFilter === "close" ? "white" : "#472878",
            borderColor: activeFDFilter === "close" ? "transparent" : "#472878",
            "&:hover": {
              backgroundColor: "#472878",
              color: "white",
            },
          }}
        >
          InActive
        </Button>
      </div>

      {/* ====================SIP's========================= */}
      <div className="mx-auto">
        {sipHistoryApiLoading === "loading" ? (
          <div style={{ height: "90vh" }}>
            <Loader />
          </div>
        ) : currentList?.length > 0 ? (
          currentList?.map((item, index) => (
            <Link
              to={`/dashboard/${item.subReferenceId}`}
              state={{ metalType: item.metal_type }}
            >
              <div key={index} className="card p-2 mb-4 history_card">
                <div>
                  {item.metal_type == "gold" ? (
                    <img
                      src="/gold_shield.png"
                      alt="gold"
                      style={{ width: 60 }}
                    />
                  ) : (
                    <img
                      src="/silver_shield.png"
                      alt="silver"
                      style={{ width: 60 }}
                    />
                  )}
                </div>

                <div style={{ color: "#4a2a7d" }}>
                  <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                    AUGMONT
                  </div>
                  <div style={{ fontSize: "1rem" }}>
                    24 Karat {item.metal_type == "gold" ? "Gold" : "Silver"}
                  </div>
                </div>
                <div style={{ color: "#4A2A7D" }}>
                  <div>₹{item.amount}</div>
                  <div>Monthly - Every {item.day_of_month} date</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: item.status === "ACTIVE" ? "green" : "red",
                  }}
                >
                  {item.status}
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="text-dark text-center">
            <img
              src="/images/history-not-found.png"
              className="noHistoryFound p-2"
              alt="No History"
            />
            <h6>No History Found</h6>
            <p>Fill this Space with your Order</p>
          </div>
        )}
      </div>
    </div>
  );
}
