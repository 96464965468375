import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const sipHistoryDetailsApi = createAsyncThunk(
  "sipHistoryDetails",
  async (sipId) => {
    const response = await axiosInstance.get(`/sip/getEmandate/${sipId}`);
    return response.data;
  }
);

const sipHistoryDetailsSlice = createSlice({
  name: "sipHistoryDetails",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sipHistoryDetailsApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sipHistoryDetailsApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(sipHistoryDetailsApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default sipHistoryDetailsSlice.reducer;
