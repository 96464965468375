import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

// Async thunk for fetching KYC details
export const getkycDetailsApi = createAsyncThunk('kycDetails', async () => {
    const response = await axiosInstance.get('/kyc/getDetails');
    return response.data;
});

// Initial state
const initialState = {
    data: [],
    errors: [],
    status: "idle"
};

// KYC details slice
const kycDetailsSlice = createSlice({
    name: "kycDetails",
    initialState,
    reducers: {
        // Reset action to clear the state
        resetKycDetails: (state) => {
            state.data = [];
            state.errors = [];
            state.status = "idle";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getkycDetailsApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getkycDetailsApi.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = "idle";
            })
            .addCase(getkycDetailsApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

// Export actions and reducer
export const { resetKycDetails } = kycDetailsSlice.actions;
export default kycDetailsSlice.reducer;
