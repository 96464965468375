import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import KYC from "./KYC";
import PersonalDetail from "./PersonalDetail";
import NomineeDetails from "./NomineeDetails";
import { Link, useNavigate } from "react-router-dom";
import "./tiles.scss";
import { useTranslation } from "react-i18next";

const ProfileSetting = () => {
  const { t } = useTranslation();
  const { profileSetting } = t("profileSettingPage");
  const { personalDetailsTab, kycTab, nomineeDetailsTab } = t(
    "profileSettingPage.profileSettingTabs"
  );

  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <section>
        <div>
          {/* <Link to="/dashboard/profile" className="goBack mt-4 btn text-light" style={{ backgroundColor: "#4A2A7D" }}>
            <span>
              Back
            </span>
          </Link> */}
          <div className="row">
            {/* <Link to="/" className="goBack mt-4">
            <span>
              <GoArrowLeft className="goBackIcon" />
            </span>
          </Link> */}
            <div className="col-sm-12">
              <h4
                className="text-center"
                style={{ color: "#4A2A7D", marginBottom: "10px" }}
              >
                {profileSetting}
              </h4>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#4a2a7d", // Active tab indicator (underline) color
                        },
                      }}
                    >
                      <Tab
                        label={personalDetailsTab}
                        value="1"
                        sx={{
                          color: "#472878",
                          borderBottom: "2px solid transparent", // Default border
                          "&.Mui-selected": {
                            color: "#4a2a7d", // Active tab color
                            borderBottom: "2px solid #4a2a7d", // Active tab border color
                            fontWeight: "bold", // Bold text for active tab
                          },
                        }}
                      />
                      <Tab
                        label={kycTab}
                        value="2"
                        sx={{
                          color: "#472878",
                          borderBottom: "2px solid transparent", // Default border
                          "&.Mui-selected": {
                            color: "#4a2a7d", // Active tab color
                            borderBottom: "2px solid #4a2a7d", // Active tab border color
                            fontWeight: "bold", // Bold text for active tab
                          },
                        }}
                      />
                      <Tab
                        label={nomineeDetailsTab}
                        value="3"
                        sx={{
                          color: "#472878",
                          borderBottom: "2px solid transparent", // Default border
                          "&.Mui-selected": {
                            color: "#4a2a7d", // Active tab color
                            borderBottom: "2px solid #4a2a7d", // Active tab border color
                            fontWeight: "bold", // Bold text for active tab
                          },
                        }}
                      />
                    </TabList>
                  </Box>

                  <TabPanel value="1">
                    <PersonalDetail />
                  </TabPanel>
                  <TabPanel value="2">
                    <KYC />
                  </TabPanel>
                  <TabPanel value="3">
                    <NomineeDetails />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileSetting;
