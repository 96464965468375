import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ref, onValue, runTransaction } from "firebase/database";
import { database } from "../../notifications/firebase";

const ViewBlog = () => {
  const { blogId } = useParams();
  const [blogDetails, setBlogDetails] = useState([]);
  const [viewCount, setViewCount] = useState(0);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    fetchBlogDetails(blogId);
  }, [blogId]);

  const fetchBlogDetails = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin_panel/blog_Details/${blogId}`
      );
      console.log(response.data.data, ">>>>>>>>>>>>");
      setBlogDetails(response.data.data);
    } catch (error) {
      console.log("Failed to fetch blog details.");
    }
  };

  useEffect(() => {
    // Increment the view count when the page loads
    const pageViewsRef = ref(database, `pageViews/${blogId}`);
    runTransaction(pageViewsRef, (current) => (current || 0) + 1);

    // Fetch the current view count
    const handleValueChange = (snapshot) => {
      const count = snapshot.val();
      setViewCount(count || 0);
    };

    // Set up the listener
    const unsubscribe = onValue(pageViewsRef, handleValueChange);

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [blogId]);

  return (
    <>
      <div className="blog-content-container">
        {blogDetails.length > 0 &&
          blogDetails.map((blog, index) => (
            <div key={index} className="blog-content">
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </div>
          ))}
      </div>
      {/* <p>Number of views: {viewCount}</p> */}
    </>
  );
};

export default ViewBlog;
