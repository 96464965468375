import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const sipHistoryApi = createAsyncThunk("sipHistory", async () => {
  const response = await axiosInstance.get(`/sip/sips_list`);
  return response.data;
});

const sipHistorySlice = createSlice({
  name: "sipHistory",
  initialState: {
    data: [],
    errors: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sipHistoryApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sipHistoryApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(sipHistoryApi.rejected, (state, action) => {
        state.errors.push(action.error.message);
        state.status = "idle";
      });
  },
});

export default sipHistorySlice.reducer;
