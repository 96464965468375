import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CoinDetial from "./CoinBuy";
import "./coinbuy.css";
import Coincard from "./CoinCard";
import { config } from "../../config";
import { GoArrowLeft } from "react-icons/go";
import CustomCoinCard from "../../components/CustomCoinCard";

const DeliveryGold = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const Token = sessionStorage.getItem("token");

  const [viewproduct, setviewproduct] = useState(true);

  const [sumdata, setSumdata] = useState([]);

  let coinData = "";

  useEffect(() => {
    callAPI();
  }, []);

  const callAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/augmont/products?page=1&count=30`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      console.log("DISHANT RESPONSE+++++++++++++++++++++++++", data);
      coinData = data;

      const goldCoins =
        data?.data?.[0]?.result?.data?.filter(
          (item) => item.metalType === "gold"
        ) || [];

      const sortedGoldCoins = goldCoins.sort(
        (a, b) => parseFloat(a.productWeight) - parseFloat(b.productWeight)
      );

      setSumdata(sortedGoldCoins);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const [particulardata, setparticulardata] = useState(null);

  const handleCoinClick = (item) => {
    setparticulardata(item);
    setviewproduct(false);
  };

  return (
    <>
      {viewproduct ? (
        <div className="rounded">
        
          <div
            className="row mx-auto d-flex justify-content-center"
            style={{ color: "#4A2A7D",alignItems:"center",textAlign: "center" }}
          >
          <h2 style={{ margin:"20px 0"}}>Gold Products</h2>
            {sumdata.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-md-3 p-2 rounded  m-3 goldcoin-card"
                  style={{
                    border: "2px solid #FAD356",
                    cursor: "pointer",
                    width: "30%",
                  }}
                >
                  {/* <Coincard
                    img={item?.productImages?.[0]?.url}
                    qty={item?.productWeight}
                    name={item?.name}
                    price={item?.basePrice}
                    click={() => {
                      handleCoinClick(item);
                    }}
                  /> */}
                  <CustomCoinCard
                     img={item?.productImages?.[0]?.url}
                    qty={item?.productWeight}
                    name={item?.name}
                    price={item?.basePrice}
                    click={() => {
                      handleCoinClick(item);
                    }}
                  />
                </div>
              );
            })}
            <div className="col-md-12 mt-3" style={{ color: "#4A2A7D" }}>
              <h6 className="text-center mt-1" style={{ color: "#4A2A7D" }}>
                Bharat Batuk Pvt Ltd (BBPL)
              </h6>
              <p className="text-center mt-2">
                Gold bought in the last 7 days cannot be sold.
                <br />
                BBPL offers gold products through our partners.
                <br />
                Refer to{" "}
                <Link target="blank" to="https://batuk.in/terms-conditions/">
                  Terms & Conditions{" "}
                </Link>
                of Gold for details.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <CoinDetial data={particulardata} />
        </>
      )}
    </>
  );
};

export default DeliveryGold;
