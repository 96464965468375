import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

export const getSpotGoldApi = createAsyncThunk('spotGold', async () => {
    const response = await axiosInstance.get('/category/productSubCategory');
    return response.data;
});

const spotGoldSlice = createSlice({
    name: "spotGold",
    initialState: {
        spotGold: [],
        errors: [],
        status: "idle"
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSpotGoldApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSpotGoldApi.fulfilled, (state, action) => {
                state.spotGold = action.payload;
                state.status = "idle";
            })
            .addCase(getSpotGoldApi.rejected, (state, action) => {
                state.errors.push(action.error.message);
                state.status = "idle";
            });
    }
});

export default spotGoldSlice.reducer;
