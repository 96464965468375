import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { sipHistoryDetailsApi } from "../../redux/features/sip/sipHistoryDetailsSlice";
import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  cancelSipApi,
  sipReset,
} from "../../redux/features/sip/cancelSipSlice";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "1rem",
};

export default function SipHistoryDetails() {
  const { sipId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedValue, setSelectedValue] = useState("");

  // console.log("LOCATION============", location.state);

  const { metalType } = location.state;

  const sipDetails = useSelector(
    (state) => state.sipHistoryDetailsSlice.data.data
  );

  // console.log("SIP DETAIL API=================", sipDetails?.subscription);

  const { umn, recurringAmount, status, expiryDate, firstChargeDate } =
    sipDetails?.subscription || "";
  useEffect(() => {
    dispatch(sipHistoryDetailsApi(sipId));
  }, [sipId]);

  const handleCancelSip = () => {
    // setOpen(true);
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "Do you want to cancel this SIP?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes, cancel it!",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     dispatch(cancelSipApi(sipId));
    //     Swal.fire("Cancelled!", "Your SIP has been cancelled.", "success");
    //   }
    // });
    dispatch(cancelSipApi(sipId));
  };

  const cancelSip = useSelector((state) => state.cancelSipSlice.data);

  // console.log("cancel SIP=================", cancelSip);

  useEffect(() => {
    if (cancelSip.error === false) {
      dispatch(sipReset());
      navigate("/dashboard/sip-history");
      toast.success("SIP Cancelled Successfully");
    }
  }, [cancelSip]);
  return (
    <div>
      <div
        style={{
          border: "2px solid gold",
          padding: "1rem",
          borderRadius: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
          padding: "1rem",
        }}
      >
        <div>
          {metalType == "gold" ? (
            <img src="/gold_shield.png" alt="gold" style={{ width: 60 }} />
          ) : (
            <img src="/silver_shield.png" alt="silver" style={{ width: 60 }} />
          )}
        </div>
        <div
          style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#4a2a7d" }}
        >
          AUGMONT
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
            fontWeight: "bold",
            color: status === "ACTIVE" ? "green" : "red",
          }}
        >
          {status}
        </div>
      </div>
      {/* --------------------------- */}
      <div
        style={{
          border: "2px solid gold",
          padding: "1rem",
          borderRadius: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "1.5rem",
          }}
        >
          <div style={{ color: "#4a2a7d", fontWeight: "bold" }}>
            <div>SIP Amount</div>
            <div>Frequency</div>
            <div>Start Date</div>
            <div>End Date</div>
            <div>UMN</div>
          </div>
          {/* <div>
            <div>:</div>
            <div>:</div>
            <div>:</div>
            <div>:</div>
            <div>:</div>
          </div> */}
          <div style={{ color: "#4a2a7d", textAlign: "right" }}>
            <div>₹{recurringAmount}</div>
            <div>Monthly</div>
            <div>{firstChargeDate}</div>
            <div>{expiryDate}</div>
            <div>{umn}</div>
          </div>
        </div>
        {status === "ACTIVE" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#4a2a7d",
                borderColor: "#4a2a7d",
                "&:hover": {
                  backgroundColor: "#472878",
                  color: "white",
                  borderColor: "#472878",
                },
              }}
              // onClick={handleCancelSip}
              onClick={handleOpen}
            >
              Cancel sip
            </Button>
          </div>
        )}
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  backgroundColor: "#DCDAFF",
                  padding: "1rem",
                  // marginTop: "1rem",
                  borderRadius: "0.5rem",
                }}
              >
                Deleting your Digital Gold SIP will stop your Digital Gold
                Saving for your future. Sta Saved for the long to meet your
                financial goals.
              </div>

              <Typography
                id="modal-modal-description"
                sx={{
                  mt: 2,
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#4a2a7d",
                }}
              >
                May we know the reason?
              </Typography>

              <RadioGroup
                // sx={{ padding: "1rem" }}
                name="sip_options"
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                <FormControlLabel
                  value="no_money"
                  control={
                    <Radio
                      sx={{
                        color: "#4a2a7d",
                        "&.Mui-checked": {
                          color: "#4a2a7d",
                        },
                      }}
                    />
                  }
                  label="I do not have the money to continue this SIP"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="new_sip"
                  control={
                    <Radio
                      sx={{
                        color: "#4a2a7d",
                        "&.Mui-checked": {
                          color: "#4a2a7d",
                        },
                      }}
                    />
                  }
                  label="I want to start a new Gold SIP on Batuk App"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="save_elsewhere"
                  control={
                    <Radio
                      sx={{
                        color: "#4a2a7d",
                        "&.Mui-checked": {
                          color: "#4a2a7d",
                        },
                      }}
                    />
                  }
                  label="I have decided to save elsewhere."
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="goal_completed"
                  control={
                    <Radio
                      sx={{
                        color: "#4a2a7d",
                        "&.Mui-checked": {
                          color: "#4a2a7d",
                        },
                      }}
                    />
                  }
                  label="I completed my savings goal."
                  labelPlacement="end"
                />
              </RadioGroup>
              <div
                style={{
                  backgroundColor: "#DCDAFF",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                }}
              >
                <strong>Note:</strong> Once the SIP is deleted, all future
                debits for this SIP will be stopped. SIP debit requests already
                in progress and sent to your bank will be deducted even after
                deletion.
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "2rem auto",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: "#4a2a7d",
                    borderColor: "#4a2a7d",
                    "&:hover": {
                      backgroundColor: "#472878",
                      color: "white",
                      borderColor: "#472878",
                    },
                  }}
                  onClick={handleCancelSip}
                  disabled={!selectedValue}
                >
                  Cancel sip
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
}
